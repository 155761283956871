import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { CompensationOption } from "@/types/claims";

interface CompensationOptionProps {
  compensationOption: CompensationOption;
}

export const CompensationOptionComponent: React.FC<CompensationOptionProps> = ({
  compensationOption,
}) => {
  const { t } = useTranslation("shift-claim-details");
  return (
    <div className="flex w-full flex-col p-medium">
      <div className="mb-large flex flex-row space-x-small">
        <LivoIcon name="report-medical" size={24} color="#ACBBCB" />
        <p className="body-regular">{t("compensation_option_type_label")}</p>
      </div>
      <div className="ring-solid flex w-full flex-col  justify-center rounded-[8px] p-medium text-center ring-1  ring-Divider-Subtle">
        <p className="bodyRegular">{compensationOption.label}</p>
        <p className="heading-md">{compensationOption.compensationValue}</p>
      </div>
    </div>
  );
};
