import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useSearchParams<T extends Record<string, string>>() {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    const result: Partial<T> = {};

    params.forEach((value, key) => {
      result[key as keyof T] = value as T[keyof T]; // Ép kiểu với generic
    });

    return result;
  }, [search]);
}

export function useSearchParam(param: string) {
  const { search } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get(param) ?? "";
  }, [param, search]);
}
