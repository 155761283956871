import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { ICON_GRAY } from "@/utils/colors";

interface ProfessionalCVSummaryProps {
  cvSummary: string;
  className?: string;
}

export function ProfessionalCVSummary({
  cvSummary,
  className,
}: ProfessionalCVSummaryProps) {
  const { t } = useTranslation("professional-claim");
  return (
    <div className={"p-medium " + (className || "")}>
      <div className="mb-large flex flex-row items-center space-x-small">
        <LivoIcon name="sparkles" size={24} color={ICON_GRAY} />
        <p className="body-regular">{t("cv_summary_title")}</p>
      </div>
      <p className="body-regular mb-small text-Text-Subtle">{cvSummary}</p>
    </div>
  );
}
