import {
  ContractDurationType,
  DurationTypeCast,
  StartDateType,
  StartDateTypeCast,
} from "@/types/offers";

import { z } from "zod";

const zodDurations = Object.values(ContractDurationType) as [
  DurationTypeCast,
  ...DurationTypeCast[],
];

const zodStartDates = Object.values(StartDateType) as [
  StartDateTypeCast,
  ...StartDateTypeCast[],
];

export const offerFormSchema = z
  .object({
    category: z
      .object({
        label: z.string(),
        value: z.string().min(1, "offers:category_required"),
      })
      .nullable()
      .refine((check) => check !== null, "offers:category_required"),
    skill: z.string().nonempty("offers:skill_required"),
    contractType: z.string().nonempty("offers:contract_type_required"),
    startDateType: z
      .enum([...zodStartDates, ""])
      .refine((check) => check !== "", "offers:start_date_type_required"),
    startDate: z.string().optional(),
    durationType: z.enum([...zodDurations, ""]),
    duration: z.string().optional(),
    schedule: z
      .array(z.string(), {
        required_error: "offers:schedule_required",
      })
      .refine((check) => check.length > 0, "offers:schedule_required"),

    salaryMin: z
      .string()
      .nonempty("offers:salary_min_required")
      .regex(/^[0-9,.]*$/, "offers:salary_min_invalid"),
    salaryMax: z
      .string()
      .regex(/^[0-9,.]*$/, "offers:salary_max_invalid")
      .optional(),
    salaryPeriod: z.string().nonempty("offers:salary_period_required"),
    salaryDetails: z.string().optional(),
    perks: z.array(z.string()),
    additionalRequirements: z.string().optional(),
    requirements: z.array(
      z.object({
        skill: z.string(),
        experience: z.string(),
      })
    ),
    details: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.startDateType === "SPECIFIC_DATE") {
        return !!data.startDate; // required when startDateType is SPECIFIC_DATE
      }
      return true;
    },
    {
      message: "offers:start_date_required",
      path: ["startDate"],
    }
  )
  .refine(
    (data) => {
      if (data.durationType === "SPECIFIC_DATE") {
        return !!data.duration; // required when durationType is SPECIFIC_DATE
      }
      return true;
    },
    {
      message: "offers:duration_date_required",
      path: ["duration"],
    }
  )
  // salaryMax should be greater or equal than salaryMin
  .refine(
    (data) => {
      if (data.salaryMax && data.salaryMin) {
        return parseFloat(data.salaryMax) >= parseFloat(data.salaryMin);
      }
      return true;
    },
    {
      message: "offers:salary_max_should_be_greater_or_equal",
      path: ["salaryMax"],
    }
  );

/**
 * TODO: There some cycle in dependencies here.
 * OfferFormData currently is a module inside pages, a View layer.
 * But it exported type used as services, a Domain layer.
 * This is a bad practice, and should be avoided.
 * This should be moved to a shared layer, or a domain layer.
 * In future, thinking about new modules called validators or schemas to independent schema from the view.
 * Also, translations should be moved outside view
 */
export type OfferFormData = z.infer<typeof offerFormSchema>;
