import { useSelector } from "react-redux";

import FiltersButton from "@/components/common/FiltersButton";

import { ShiftSummary } from "@/services/shifts-calendar";
import { RootState } from "@/store/types";
import { day } from "@/utils/datetime";

import { CalendarMonth } from "./CalendarMonth";

interface CalendarSummaryProps {
  currentMonth: string;
  onMonthChange: (month: string) => void;
  onDayPress: (day: string) => void;
  shiftSummary: ShiftSummary[];
  loading: boolean;
  openFilter: () => void;
}
export const CalendarSummary: React.FC<CalendarSummaryProps> = ({
  currentMonth,
  onMonthChange,
  onDayPress,
  shiftSummary,
  loading,
  openFilter,
}) => {
  const filters = useSelector((state: RootState) => state.filterShifts.filters);
  const numberOfAppliedFilters = filters.filter(
    (f) => f.appliedOptions.length > 0
  ).length;
  const { selectedDate } = useSelector(
    (state: RootState) => state.calendarSummary
  );
  return (
    <div className="flex h-full w-[256px] flex-col justify-start space-y-small self-start border-r border-solid border-Divider-Subtle bg-white px-medium pt-[20px]">
      <div>
        <CalendarMonth
          onDayPress={(_day: string) => {
            onDayPress(_day);
            if (day(_day).month() !== day(currentMonth).month()) {
              onMonthChange(day(_day).startOf("month").format("YYYY-MM-DD"));
            }
          }}
          calendarSummary={shiftSummary}
          monthToRender={currentMonth}
          daysSelected={[selectedDate.toString()]}
          onMonthChange={(month: string) => {
            onMonthChange(month);
          }}
        />
        {filters && filters.length > 0 && (
          <FiltersButton
            onClick={openFilter}
            filters={filters}
            numberOfAppliedFilters={numberOfAppliedFilters}
          />
        )}
      </div>
    </div>
  );
};
