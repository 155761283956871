/**
 * a simple markdown parser that supports bold and italic
 * Can add more parsers for other markdown syntax like underline, strikethrough, etc.
 * Continuous handling using chain of responsibility pattern
 */

import { Link } from "react-router-dom";

type MarkdownOutput = (string | JSX.Element)[];

// handle bold
export const parseBold = (input: MarkdownOutput): MarkdownOutput => {
  const regex = /\*\*(.*?)\*\*/g;
  return input.flatMap((part, index) => {
    if (typeof part !== "string") return [part];
    const segments: (string | JSX.Element)[] = [];
    let match: RegExpExecArray | null;
    let lastIndex = 0;

    while ((match = regex.exec(part)) !== null) {
      if (lastIndex < match.index) {
        segments.push(part.slice(lastIndex, match.index));
      }
      segments.push(<b key={`bold-${index}-${segments.length}`}>{match[1]}</b>);
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < part.length) {
      segments.push(part.slice(lastIndex));
    }

    return segments;
  });
};

// handle italic
export const parseItalic = (input: MarkdownOutput): MarkdownOutput => {
  const regex = /\*(.*?)\*/g;
  return input.flatMap((part, index) => {
    if (typeof part !== "string") return [part];
    const segments: (string | JSX.Element)[] = [];
    let match: RegExpExecArray | null;
    let lastIndex = 0;

    while ((match = regex.exec(part)) !== null) {
      if (lastIndex < match.index) {
        segments.push(part.slice(lastIndex, match.index));
      }
      segments.push(
        <i key={`italic-${index}-${segments.length}`}>{match[1]}</i>
      );
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < part.length) {
      segments.push(part.slice(lastIndex));
    }

    return segments;
  });
};

export const parseLink = (input: MarkdownOutput): MarkdownOutput => {
  const regex = /\[(.*?)\]\((.*?)\)(?:\{(.*?)\})?/g;
  return input.flatMap((part, index) => {
    if (typeof part !== "string") return [part];
    const segments: (string | JSX.Element)[] = [];
    let match: RegExpExecArray | null;
    let lastIndex = 0;

    while ((match = regex.exec(part)) !== null) {
      if (lastIndex < match.index) {
        segments.push(part.slice(lastIndex, match.index));
      }

      const attributes =
        match[3]?.split(" ").reduce(
          (acc, attr) => {
            const [key, value] = attr.split("=");
            if (key && value) {
              acc[key] = value.replace(/['"]+/g, "");
            }
            return acc;
          },
          {} as Record<string, string>
        ) || {};

      segments.push(
        <Link
          className="!font-semibold !text-Action-Primary hover:!underline"
          key={`link-${index}-${segments.length}`}
          to={match[2] || "#"}
          state={attributes.state || undefined}
          target={attributes.target || undefined}
          rel={attributes.rel || "noopener noreferrer"}
        >
          {match[1]}
        </Link>
      );
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < part.length) {
      segments.push(part.slice(lastIndex));
    }

    return segments;
  });
};

const chainProcessors = (
  processors: ((input: MarkdownOutput) => MarkdownOutput)[],
  initialInput: string
): MarkdownOutput => {
  return processors.reduce<MarkdownOutput>(
    (output, processor) => processor(output),
    [initialInput]
  );
};

export const markdown = (text: string): MarkdownOutput => {
  return chainProcessors([parseBold, parseItalic, parseLink], text);
};
