const colors = {
  "Neutral-100": "#eeeff3",
  "Neutral-200": "#dae4e7",
  "Neutral-300": "#c6d0db",
  "Neutral-400": "#acbbcb",
  "Neutral-500": "#9fa9bf",
  "Neutral-600": "#848da9",
  "Neutral-700": "#707a91",
  "Neutral-800": "#5b6478",
  "Neutral-900": "#4d5461",
  "Neutral-950": "#2c3038",
  "Neutral-000": "#ffffff",
  "Neutral-050": "#f4f5f7",
  "Neutral-999": "#000000",
  "Primary-100": "#e0efff",
  "Primary-200": "#bae3fe",
  "Primary-300": "#7ccafd",
  "Primary-400": "#3ab0f9",
  "Primary-500": "#149ef2",
  "Primary-600": "#0277c8",
  "Primary-700": "#015fa1",
  "Primary-800": "#055186",
  "Primary-900": "#0a456e",
  "Primary-950": "#072b48",
  "Primary-050": "#eff9ff",
  "Secondary-100": "#d6f1d6",
  "Secondary-200": "#abedc7",
  "Secondary-300": "#86d2ac",
  "Secondary-400": "#5fbdad",
  "Secondary-500": "#4f989e",
  "Secondary-600": "#417683",
  "Secondary-700": "#375d68",
  "Secondary-800": "#334e57",
  "Secondary-900": "#114454",
  "Secondary-950": "#052430",
  "Secondary-050": "#eff7e7",
  "Positive-100": "#e1f7e1",
  "Positive-200": "#c2f0c6",
  "Positive-300": "#92e299",
  "Positive-400": "#74d57e",
  "Positive-500": "#33b240",
  "Positive-600": "#24932e",
  "Positive-700": "#237329",
  "Positive-800": "#205c27",
  "Positive-900": "#1c4c21",
  "Positive-950": "#092910",
  "Positive-050": "#f0fcf2",
  "Warning-100": "#ffe8c5",
  "Warning-200": "#ffcd89",
  "Warning-300": "#ffa538",
  "Warning-400": "#ff911c",
  "Warning-500": "#f96a02",
  "Warning-600": "#dd4700",
  "Warning-700": "#b52c04",
  "Warning-800": "#94200d",
  "Warning-900": "#7b1d0f",
  "Warning-950": "#460b02",
  "Warning-050": "#fff8eb",
  "Negative-100": "#ffe1e1",
  "Negative-200": "#ffc6c7",
  "Negative-300": "#ffa0a0",
  "Negative-400": "#ff5a59",
  "Negative-500": "#fa3d3b",
  "Negative-600": "#e4201b",
  "Negative-700": "#c11314",
  "Negative-800": "#a01414",
  "Negative-900": "#831918",
  "Negative-950": "#470808",
  "Negative-050": "#fff0f1",
  "Grey-100": "#eeeff3",
  "Grey-200": "#dae4e7",
  "Grey-300": "#c6d0db",
  "Grey-400": "#acbbcb",
  "Grey-500": "#9fa9bf",
  "Grey-600": "#848da9",
  "Grey-700": "#707a91",
  "Grey-800": "#5b6478",
  "Grey-900": "#4d5461",
  "Grey-950": "#2c3038",
  "Grey-999": "#000000",
  "Grey-000": "#ffffff",
  "Grey-050": "#f4f5f7",
  "Blue-100": "#e0efff",
  "Blue-200": "#bae3fe",
  "Blue-300": "#7ccafd",
  "Blue-400": "#3ab0f9",
  "Blue-500": "#149ef2",
  "Blue-600": "#0277c8",
  "Blue-700": "#015fa1",
  "Blue-800": "#055186",
  "Blue-900": "#0a456e",
  "Blue-950": "#072b48",
  "Blue-050": "#eff9ff",
  "Teal-100": "#d2f7fb",
  "Teal-200": "#adeff5",
  "Teal-300": "#6fe2ef",
  "Teal-400": "#34cce1",
  "Teal-500": "#12a3b9",
  "Teal-600": "#138da6",
  "Teal-700": "#1a7085",
  "Teal-800": "#1f5d6e",
  "Teal-900": "#194d5e",
  "Teal-950": "#0c3240",
  "Teal-050": "#eefcfe",
  "Mint-100": "#d6f1d6",
  "Mint-200": "#abedc7",
  "Mint-300": "#86d2ac",
  "Mint-400": "#5fbdad",
  "Mint-500": "#4f989e",
  "Mint-600": "#417683",
  "Mint-700": "#375d68",
  "Mint-800": "#334e57",
  "Mint-900": "#114454",
  "Mint-950": "#052430",
  "Mint-050": "#eff7e7",
  "Green-100": "#e1f7e1",
  "Green-200": "#c2f0c6",
  "Green-300": "#92e299",
  "Green-400": "#74d57e",
  "Green-500": "#33b240",
  "Green-600": "#24932e",
  "Green-700": "#237329",
  "Green-800": "#205c27",
  "Green-900": "#1c4c21",
  "Green-950": "#092910",
  "Green-050": "#f0fcf2",
  "Yellow-100": "#fef9c3",
  "Yellow-200": "#fef187",
  "Yellow-300": "#ffe041",
  "Yellow-400": "#ffce1f",
  "Yellow-500": "#efb300",
  "Yellow-600": "#ce8900",
  "Yellow-700": "#a46107",
  "Yellow-800": "#884b08",
  "Yellow-900": "#743e11",
  "Yellow-950": "#421f05",
  "Yellow-050": "#fefde9",
  "Orange-100": "#ffe8c5",
  "Orange-200": "#ffcd89",
  "Orange-300": "#ffa538",
  "Orange-400": "#ff911c",
  "Orange-500": "#f96a02",
  "Orange-600": "#dd4700",
  "Orange-700": "#b52c04",
  "Orange-800": "#94200d",
  "Orange-900": "#7b1d0f",
  "Orange-950": "#460b02",
  "Orange-050": "#fff8eb",
  "Red-100": "#ffe1e1",
  "Red-200": "#ffc6c7",
  "Red-300": "#ffa0a0",
  "Red-400": "#ff5a59",
  "Red-500": "#fa3d3b",
  "Red-600": "#e4201b",
  "Red-700": "#c11314",
  "Red-800": "#a01414",
  "Red-900": "#831918",
  "Red-950": "#470808",
  "Red-050": "#fff0f1",
  "Pink-100": "#fee5f3",
  "Pink-200": "#fecce7",
  "Pink-300": "#fe85c6",
  "Pink-400": "#fe69b5",
  "Pink-500": "#f83d97",
  "Pink-600": "#e71d74",
  "Pink-700": "#ca0c59",
  "Pink-800": "#a70d4b",
  "Pink-900": "#8a113f",
  "Pink-950": "#550222",
  "Pink-050": "#fef1fa",
  "Purple-100": "#f0eefa",
  "Purple-200": "#e3dff4",
  "Purple-300": "#ccc5ec",
  "Purple-400": "#b1a4e0",
  "Purple-500": "#987ed4",
  "Purple-600": "#7e58c2",
  "Purple-700": "#7550b2",
  "Purple-800": "#634295",
  "Purple-900": "#52377c",
  "Purple-950": "#332352",
  "Purple-050": "#f7f6fb",
  "Divider-Default": "#C6D0DB", // Grey-400
  "Divider-Strong": "#707a91", // Grey-700
  "Divider-Subtle": "#C6D0DB", // Grey-300
  "Background-Primary": "#ffffff", // Neutral-000 (White)
  "Background-Secondary": "#f4f5f7", // Grey-050
  "Background-Tertiary": "#E3E6EC",
  "Text-Light": "#9fa9bf", // Grey-500
  "Text-Subtle": "#707A91", // Grey-900
  "Text-Secondary": "#00000099", // Grey-999
  "Text-Default": "#2c3038", // Grey-950
  "Text-Inverse": "#ffffff", // Neutral-000 (White)
  "Action-Primary": "#149ef2", // Primary-500 (Blue)
  "Action-Notification": "#FA3D3B", // Negative-500 (Red)
  "Action-Secondary": "#375d68", // Secondary-700
  "Daytime-Morning": "#ffa538", // Orange-300
  "Daytime-Afternoon": "#fe85c6", // Pink-300
  "Daytime-Night": "#12a3b9", // Teal-500
  "BG-Default": "#F3F4F5",
};

export default colors;
