import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";
import { ModalContainer } from "@/components/common/ModalContainer";

interface EditCapacityModalProps {
  isOpen: boolean;
  addCapacity: (newCapacity: number) => void;
  maxCapacity: number;
  goBack: () => void;
}
export const EditCapacityModal: React.FC<EditCapacityModalProps> = ({
  isOpen,
  addCapacity,
  maxCapacity,
  goBack,
}) => {
  const { t } = useTranslation("shift-claim-details");
  const [numberOfSpots, setNumberOfSpots] = useState(1);

  return (
    <ModalContainer isOpen={isOpen} onClose={goBack}>
      <div className="w-[410px] rounded-[16px] bg-white p-medium shadow-custom">
        <p className="heading-md mb-large">{t("add_capacity_modal_title")}</p>
        <div className="flex flex-row items-center justify-center space-x-medium">
          <button
            disabled={numberOfSpots <= 1}
            onClick={() => setNumberOfSpots((nSpots) => nSpots - 1)}
          >
            <LivoIcon
              name="minus"
              size={24}
              color={numberOfSpots > 1 ? "#149EF2" : "#DAE4E7"}
            />
          </button>
          <div className="ring-solid flex w-[90px] items-center justify-center rounded-[4px] py-medium text-center ring-1 ring-[#ACBBCB]">
            <p className="body-regular size-full self-center text-[#055186]">
              {numberOfSpots}
            </p>
          </div>
          <button
            disabled={numberOfSpots >= maxCapacity}
            onClick={() => setNumberOfSpots((nSpots) => nSpots + 1)}
          >
            <LivoIcon
              name="plus"
              size={24}
              color={numberOfSpots < maxCapacity ? "#149EF2" : "#DAE4E7"}
            />
          </button>
        </div>
        <div className="mt-large flex flex-row items-center">
          <button
            onClick={() => goBack()}
            className="flex flex-1 items-center justify-center px-small py-large  text-center text-Action-Primary"
          >
            <p className="action-regular w-full">
              {t("add_capacity_modal_go_back")}
            </p>
          </button>
          <button
            className="flex flex-1 rounded-[100px] bg-Action-Primary px-small py-large text-center text-Text-Inverse"
            onClick={() => addCapacity(numberOfSpots)}
          >
            <p className="action-regular w-full">
              {t("add_capacity_modal_button")}
            </p>
          </button>
        </div>
      </div>
    </ModalContainer>
  );
};
