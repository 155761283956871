import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { setActivityShiftListSelectedShiftId } from "@/store/actions/activityShiftListActions";
import { RootState } from "@/store/types";
import { Shift } from "@/types/shifts";

import { ActivityShiftCard } from "./ActivityShiftCard";
import { EmptyCardComponent } from "./EmptyCardComponent";
import { EmptyDetailsComponent } from "./EmptyDetailsComponent";

interface ActivityShiftListProps {
  loading: boolean;
  shifts: Shift[];
}
export const ActivityShiftListComponent: React.FC<ActivityShiftListProps> = ({
  loading,
  shifts,
}) => {
  const { t } = useTranslation("calendar");
  const { selectedShiftId } = useSelector(
    (state: RootState) => state.activityShiftList
  ); // change for activty
  const dispatch = useDispatch();

  const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
      shiftRefs.current[selectedShiftId]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedShiftId]);

  return (
    <>
      <div className="flex flex-1 overflow-y-auto pt-xLarge mx-16">
        <div className="flex size-full flex-col">
          <div className="mb-large flex w-full items-center justify-between space-x-small">
            <p className="heading-md text-ellipsis text-nowrap">
              {t("activity_title")}
            </p>
          </div>
          <div className="flex size-full flex-col space-y-small">
            {loading ? (
              <div className="flex h-full flex-1 items-center justify-center">
                <CircularProgress />
              </div>
            ) : shifts.length === 0 ? (
              <div className="flex flex-col">
                <EmptyCardComponent />
              </div>
            ) : (
              <div className={"flex flex-col  space-y-small"}>
                {shifts.map((shift, index) => {
                  return (
                    <div
                      key={index}
                      ref={(el: any) => {
                        shiftRefs.current[shift.id] = el;
                      }}
                    >
                      <ActivityShiftCard
                        shift={shift as Shift}
                        onClick={() => {
                          dispatch(
                            setActivityShiftListSelectedShiftId(shift.id)
                          ); // change for activity
                        }}
                        isSelected={selectedShiftId === shift.id}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {shifts.length === 0 && !loading ? (
        <div className="flex w-2/3 border-l border-Divider-Default">
          <EmptyDetailsComponent />
        </div>
      ) : null}
    </>
  );
};
