import { ModalWithCloseButtonContainer } from "@/components/common/ModalWithCloseButton";

import { LivoCVDetailsDTO } from "@/types/common/curriculum";

import { QualificationCard } from "./QualificationCard";

interface LivoCVModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  livoCVDetails: LivoCVDetailsDTO;
  fullScreen?: boolean;
  style?: any;
}

export function LivoCVModal({
  title,
  isOpen,
  onClose,
  livoCVDetails,
  fullScreen,
  style,
}: LivoCVModalProps) {
  return (
    <ModalWithCloseButtonContainer
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      style={style}
    >
      <div className="h-3/4 overflow-y-scroll bg-Grey-050 p-4">
        <QualificationCard qualification={livoCVDetails.education} />
        <QualificationCard
          className="mt-3"
          qualification={livoCVDetails.experience}
        />
      </div>
    </ModalWithCloseButtonContainer>
  );
}
