import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

import { CircularProgress } from "@mui/material";

import LivoIcon from "@/components/common/LivoLogo";

import { EmptyShiftsState } from "../../components/internalProfessionals/EmptyState";
import { FacilityStaff } from "../../services/facility-staff";
import { FacilityStaffCard } from "./FacilityStaffCard";
import { SearchBar } from "./SearchBar";

interface FacilityStaffListComponentProps {
  facilityStaff: FacilityStaff[];
  loading: boolean;
  loadNextPage: () => void;
  hasMoreData: boolean;
  searchQuery: string;
  setSearchQuery: (text: string) => void;
  onEdit: (facilityStaff: FacilityStaff) => void;
  onCreate: () => void;
  facilityHasUnitsConfigured?: boolean;
}

export const FacilityStaffListComponent: React.FC<
  FacilityStaffListComponentProps
> = ({
  facilityStaff,
  loading,
  loadNextPage,
  hasMoreData,
  searchQuery,
  setSearchQuery,
  onEdit,
  onCreate,
  facilityHasUnitsConfigured,
}) => {
  const { t } = useTranslation("facility-staff");
  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    scrollToTop();
  }, [searchQuery]);

  return (
    <div className="no-scrollbar flex flex-1 justify-center overflow-y-hidden py-xLarge">
      <div className="flex w-full max-w-[1500px] flex-1 flex-col space-y-medium px-medium">
        <div className="flex w-full items-center justify-between space-x-small">
          <p className="heading-md text-ellipsis text-nowrap">
            {t("facility_staff_page_title")}
          </p>
          <button
            onClick={() => onCreate()}
            className="flex cursor-pointer items-center space-x-tiny rounded-full py-small pl-large pr-medium ring-1 ring-Divider-Default"
          >
            <p className="body-regular text-Primary-500">
              {t("add_user_button")}
            </p>
            <LivoIcon name="user-plus" size={24} color="#149EF2" />
          </button>
        </div>
        <SearchBar
          searchText={searchQuery}
          setSearchText={setSearchQuery}
          searchPlaceHolder={t("search_placeholder")}
        />
        <div className="flex h-fit w-full flex-col">
          <div className="mb-large mr-[20px] flex items-center space-x-small border-b border-Divider-Default p-small text-left text-Text-Subtle ">
            <div className="table-header flex flex-1 ">
              {t("user_name_label")}
            </div>
            <div className="table-header flex flex-1 ">{t("email_label")}</div>
            {facilityHasUnitsConfigured ? (
              <div className="table-header flex flex-1 ">
                {t("units_label")}
              </div>
            ) : null}
            <div className="table-header flex flex-1 ">
              {t("permissions_label")}
            </div>
          </div>
          {loading ? (
            <div className="flex h-fit flex-1 items-center justify-center">
              <CircularProgress />
            </div>
          ) : facilityStaff.length === 0 ? (
            <EmptyShiftsState title={"No hay usuarios registrados"} />
          ) : (
            <div
              id="scrollableDiv"
              className="pb-100 no-scrollbar w-full space-y-small"
              ref={scrollableDivRef}
              style={{
                overflow: "scroll",
                height: "calc(100vh - 300px)",
              }}
            >
              <InfiniteScroll
                dataLength={facilityStaff.length}
                next={() => loadNextPage()}
                hasMore={hasMoreData}
                loader={<div />}
                scrollableTarget="scrollableDiv"
                className="space-y-small"
                scrollThreshold={0.7}
                style={{
                  overflow: "visible",
                }}
              >
                {facilityStaff.map((item, index) => (
                  <FacilityStaffCard
                    key={index}
                    faciltiyStaff={item}
                    onClick={() => onEdit(item)}
                    facilityHasUnitsConfigured={facilityHasUnitsConfigured}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
          <div className="h-large" />
        </div>
      </div>
    </div>
  );
};
