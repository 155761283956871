import { useDispatch } from "react-redux";

import {
  publishShift,
  ShiftPublicationRequest,
} from "@/services/publish-shift";
import { setDateSelected } from "@/store/actions";
import { showToastAction } from "@/store/actions/appConfigurationActions";
import { ShiftTimeInDayEnum } from "@/types/shifts";
import { day } from "@/utils/datetime";

import { ShiftConfiguration } from "./BaseShiftForm";
import PublishEditModal from "./PublishEditModal";

interface PublishShiftModalProps {
  onClose: () => void;
  isOpen: boolean;
  selectedDate: string;
  shiftTimeInDay: ShiftTimeInDayEnum;
  reloadData: () => void;
  shiftDetails?: ShiftConfiguration;
}

export const PublishShiftModal: React.FC<PublishShiftModalProps> = ({
  onClose,
  isOpen,
  shiftTimeInDay,
  selectedDate,
  reloadData,
  shiftDetails,
}) => {
  // select the the start time according to shift time in day

  const dispatch = useDispatch();

  const publishShiftFunction = (
    shiftRequest: ShiftPublicationRequest
  ): Promise<void> => {
    return publishShift(shiftRequest).then(() => {
      onClose();
      dispatch(
        showToastAction({
          message: "Turno creado con éxito",
          severity: "success",
        })
      );
      if (day(shiftRequest.startTime).isSame(selectedDate, "day")) {
        reloadData();
      } else {
        dispatch(
          setDateSelected(day(shiftRequest.startTime).format("YYYY-MM-DD"))
        );
      }
    });
  };

  return (
    <PublishEditModal
      shiftDetails={shiftDetails || null}
      shiftTimeInDay={shiftTimeInDay}
      selectedDate={selectedDate}
      onSubmit={(publishShiftRequest: ShiftPublicationRequest) =>
        publishShiftFunction(publishShiftRequest)
      }
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
    />
  );
};
