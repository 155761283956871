import { useTranslation } from "react-i18next";

import { CustomInput } from "@/components/common/CustomInput";

import { DropwDown } from "./DropDown";

interface PriceSelectorProps {
  setPrice: (price: string) => void;
  selectedPrice: string;
  selectedModality: string;
  setSelectedModality: (modality: string) => void;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const PriceSelector: React.FC<PriceSelectorProps> = ({
  setPrice,
  selectedPrice,
  selectedModality,
  setSelectedModality,
  errorMessage,
  isDisabled,
}) => {
  const { t } = useTranslation("publish-shift");
  return (
    <div className="flex w-full flex-col">
      <div
        className="flex flex-row space-x-medium"
        style={{ pointerEvents: isDisabled ? "none" : "all" }}
      >
        <div className="w-1/2">
          <CustomInput
            setValue={setPrice}
            selectedValue={selectedPrice}
            placeHolder={t("price_input")}
            endingLabel="€"
            errorMessage={errorMessage}
            inputType="number"
            disabled={isDisabled}
            label={t("price_input")}
          />
        </div>
        <div className="w-1/2">
          <DropwDown
            setOptionId={setSelectedModality}
            selectedOptionId={selectedModality}
            placeHolder={t("price_mode_dropdown")}
            options={[
              { id: "hourly", name: t("hourly_rate") },
              { id: "total", name: t("total_rate") },
            ]}
            disabled={isDisabled}
            label={t("price_mode_dropdown")}
          />
        </div>
      </div>
    </div>
  );
};
