import { useMemo } from "react";
import { useSelector } from "react-redux";

import { protectedRoutes, ShiftClaimRoute } from "@/routers/config";
import { RootState } from "@/store/types";

export const useProtectedRoutes = () => {
  const { accountInfo } = useSelector((state: RootState) => state.account);

  const filteredRoutes = useMemo(() => {
    const filtered = protectedRoutes.filter((route) =>
      accountInfo?.visibleTabMenu.includes(route.id)
    );

    if (filtered.some((route) => route.id === "LEGAL_DOCUMENTATION")) {
      filtered.push(ShiftClaimRoute);
    }

    return filtered;
  }, [accountInfo?.visibleTabMenu]);

  return filteredRoutes;
};
