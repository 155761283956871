import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import LivoIcon from "@/components/common/LivoLogo";

import { updateOfferClaimStatus } from "@/services/offers";
import { OfferClaim, OfferClaimStatus } from "@/types/offers";

import { OfferClaimButton } from "../components/OfferClaimButton";
import { OfferProfessionalProfileSection } from "../components/OfferProfessionalProfile";
import { DiscloseDetailsModal } from "../modals/DiscloseDetailsModal";
import { HireOfferClaimModal } from "../modals/HireOfferClaimModal";
import { RejectOfferClaimModal } from "../modals/RejectOfferClaimModal";

interface OfferClaimDetailProps {
  selectedOfferClaim: OfferClaim;
  onClose: () => void;
  refreshClaims: () => void;
}

export const OfferClaimDetail: React.FC<OfferClaimDetailProps> = ({
  selectedOfferClaim,
  onClose,
  refreshClaims,
}) => {
  const { t } = useTranslation("offers");
  const location = useLocation();
  const offerId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("offerId") ?? "";
    return parseInt(id);
  }, [location.search]);

  const [isVisible, setIsVisible] = useState(false);
  const [isReviewDetailModalOpen, setIsReviewDetailModalOpen] = useState(false);
  const [isHireModalOpen, setIsHireModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    const element = document.getElementById("offer-claim-detail");
    if (element) {
      element.style.scrollBehavior = "smooth";
      element.scrollTop = 0;
      element.style.scrollBehavior = "auto";
    }
  }, [selectedOfferClaim]);

  function getFirstName(fullName: string) {
    return fullName.split(" ")[0];
  }

  function discloseOfferDetail(offerId: number, claimId: number) {
    updateOfferClaimStatus(
      offerId,
      claimId,
      OfferClaimStatus.DETAILS_DISCLOSED
    ).then(() => {
      setIsReviewDetailModalOpen(false);
      refreshClaims();
    });
  }

  function hireOfferClaim(offerId: number, claimId: number) {
    updateOfferClaimStatus(offerId, claimId, OfferClaimStatus.HIRED).then(
      () => {
        setIsHireModalOpen(false);
        refreshClaims();
      }
    );
  }

  function rejectOfferClaim(
    offerId: number,
    claimId: number,
    reason: string,
    details: string
  ) {
    updateOfferClaimStatus(
      offerId,
      claimId,
      OfferClaimStatus.REJECTED,
      reason,
      details
    ).then(() => {
      setIsRejectModalOpen(false);
      refreshClaims();
    });
  }

  return (
    <div className="flex h-full max-w-screen-lg flex-1">
      <div
        className={`no-scrollbar flex h-full flex-1 flex-col overflow-y-auto border-l border-solid border-Divider-Subtle bg-white transition-transform duration-300${isVisible ? "translate-x-0" : "translate-x-full"}`}
      >
        <div className="inline-flex h-96 flex-col items-center justify-start gap-2 p-6">
          <div className=" inline-flex items-start justify-between self-stretch">
            <button
              onClick={() => {
                setIsVisible(false);
                setTimeout(() => onClose(), 300);
              }}
            >
              <LivoIcon name="close" size={24} color="#707A91" />
            </button>
            <div className="flex items-start justify-end gap-2">
              {selectedOfferClaim.status === "VISIBLE" && (
                <OfferClaimButton
                  label={t("btn_display_details")}
                  color="white"
                  backgroundColor="#149ef2"
                  onClick={() => {
                    setIsReviewDetailModalOpen(true);
                  }}
                />
              )}

              {selectedOfferClaim.status === "DETAILS_DISCLOSED" && (
                <OfferClaimButton
                  label={t("btn_mark_hired")}
                  color="white"
                  backgroundColor="#149ef2"
                  onClick={() => {
                    setIsHireModalOpen(true);
                  }}
                />
              )}

              {(selectedOfferClaim.status === "VISIBLE" ||
                selectedOfferClaim.status === "DETAILS_DISCLOSED") && (
                <OfferClaimButton
                  label={t("btn_remove")}
                  color="#2c3038"
                  backgroundColor="#ffc6c7"
                  onClick={() => {
                    setIsRejectModalOpen(true);
                  }}
                />
              )}
            </div>
          </div>
          <OfferProfessionalProfileSection
            profile={selectedOfferClaim?.professionalProfile}
            detailsDisclosed={
              selectedOfferClaim.status === "DETAILS_DISCLOSED" ||
              selectedOfferClaim.status === "HIRED"
            }
            callToActionText={
              selectedOfferClaim.status === "VISIBLE"
                ? t("call_to_disclose_details")
                : null
            }
            status={selectedOfferClaim.status}
          />
        </div>
      </div>

      <DiscloseDetailsModal
        professionalName={getFirstName(
          selectedOfferClaim?.professionalProfile?.fullName
        )}
        isOpen={isReviewDetailModalOpen}
        onClose={() => {
          setIsReviewDetailModalOpen(false);
        }}
        onAccept={() => {
          discloseOfferDetail(offerId!!, selectedOfferClaim.id);
        }}
      />
      <HireOfferClaimModal
        isOpen={isHireModalOpen}
        onClose={() => {
          setIsHireModalOpen(false);
        }}
        onAccept={() => {
          hireOfferClaim(offerId!!, selectedOfferClaim.id);
        }}
      />
      <RejectOfferClaimModal
        professionalName={getFirstName(
          selectedOfferClaim?.professionalProfile?.fullName
        )}
        isOpen={isRejectModalOpen}
        onClose={() => {
          setIsRejectModalOpen(false);
        }}
        rejectOfferClaim={(reason: string, details: string) => {
          rejectOfferClaim(offerId!!, selectedOfferClaim.id, reason, details);
        }}
      />
    </div>
  );
};
