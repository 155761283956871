import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SortingOptionsEnum } from "@/components/common/SortingSelector";
import { HolidayTag } from "@/components/shiftDetails/HolidayTag";

import { setPublishShiftListSelectedShiftId } from "@/store/actions/publishShiftListActions";
import { RootState } from "@/store/types";
import { ActionComponentIdEnum, DayShift, Shift } from "@/types/shifts";
import { formatDateWithToday } from "@/utils";

import { ShiftListCard } from "./ShiftListCard";

interface DayShiftComponentProps {
  dayShift: DayShift;
  actionComponents: {
    iconName: string;
    onClick: (shift: Shift) => void;
    id: ActionComponentIdEnum;
  }[];
  sortedBy?: SortingOptionsEnum;
}
export const DayShiftComponent: React.FC<DayShiftComponentProps> = ({
  dayShift,
  actionComponents,
  sortedBy,
}) => {
  const { selectedShiftId } = useSelector(
    (state: RootState) => state.publishShiftList
  );
  const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
      shiftRefs.current[selectedShiftId]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedShiftId]);

  const dispatch = useDispatch();
  return (
    <div className="flex w-full flex-col">
      <div className="my-small flex items-center space-x-small">
        <p className="subtitle-regular">{formatDateWithToday(dayShift.date)}</p>
        <HolidayTag holiday={dayShift.holiday} />
      </div>

      <div className={"flex flex-col  space-y-small"}>
        {dayShift.shifts.map((shift, index) => {
          return (
            <div
              key={index}
              ref={(el: any) => {
                shiftRefs.current[shift.id] = el;
              }}
            >
              <ShiftListCard
                shift={shift as Shift}
                onClick={() => {
                  dispatch(setPublishShiftListSelectedShiftId(shift.id));
                }}
                isSelected={selectedShiftId === shift.id}
                actionComponents={actionComponents}
                sortedBy={sortedBy}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
