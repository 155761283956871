import { CSSProperties, useMemo } from "react";

import Chip from "@/components/common/Chip";

import colors from "@/config/color-palette";
import { OfferStatusCast } from "@/types/offers";

type Status = OfferStatusCast | "DEFAULT" | "TAG";

const OfferStatusMap: Map<Status, CSSProperties> = new Map([
  [
    "PENDING_VERIFICATION",
    {
      backgroundColor: colors["Orange-200"],
      color: colors["Orange-900"],
    },
  ],
  [
    "DRAFT",
    {
      backgroundColor: colors["Purple-200"],
      color: colors["Purple-900"],
    },
  ],
  [
    "PUBLISHED",
    {
      backgroundColor: colors["Positive-200"],
      color: colors["Positive-900"],
    },
  ],
  [
    "CLOSED",
    {
      backgroundColor: colors["Neutral-200"],
      color: colors["Grey-900"],
    },
  ],
  [
    "CLOSED_EXPIRED",
    {
      backgroundColor: colors["Neutral-200"],
      color: colors["Grey-900"],
    },
  ],
  [
    "CANCELED",
    {
      backgroundColor: colors["Neutral-200"],
      color: colors["Grey-900"],
    },
  ],
  [
    "DEFAULT",
    {
      borderWidth: "1px",
      borderColor: colors["Neutral-600"],
      backgroundColor: colors["Neutral-000"],
    },
  ],
  [
    "TAG",
    {
      color: colors["Grey-900"],
      backgroundColor: colors["Neutral-200"],
    },
  ],
]);

export const OfferLabelMap = new Map([
  ["PUBLISHED", "Publicada"],
  ["CANCELED", "Cerrada manualmente"],
  ["CLOSED", "Cerrada"],
  ["DRAFT", "Borrador"],
  ["PENDING_VERIFICATION", "Pendiente de verificación"],
  ["DEFAULT", ""],
  ["CLOSED_EXPIRED", "Cerrada por expiración"],
]);

interface OfferStatusProps {
  label?: string;
  status: Status;
}

function OfferStatusDEPRECATED({ label, status }: OfferStatusProps) {
  const config = useMemo(() => {
    const style = OfferStatusMap.get(status) ?? {};
    const _label = `${OfferLabelMap.get(status) ?? ""}${label ? " " + label : ""}`;
    return { label: _label, style };
  }, [status, label]);

  return <Chip style={config.style} label={config.label} />;
}

export default OfferStatusDEPRECATED;
