import React from "react";

import Typography from "@mui/material/Typography";

import { CopyButton } from "./CopyButton";

interface BoldTitleAndValueProps {
  title: string;
  value: string;
  placeholder?: string;
  annotation?: string;
  copyText?: string;
  copyTextSuccess?: string;
  classname?: string;
}

const BoldTitleAndValue: React.FC<BoldTitleAndValueProps> = ({
  title,
  value,
  placeholder,
  annotation,
  copyText,
  copyTextSuccess,
  classname,
}) => {
  if (title !== "") {
    return (
      <div className={`flex flex-row items-center ${classname}`}>
        <Typography variant="body1" component="div">
          <b>{title}:</b> {value && value !== "" ? value : placeholder}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          style={{ marginLeft: "4px" }}
        >
          {annotation && annotation !== "" ? annotation : ""}
        </Typography>

        {copyText && copyText !== "" && (
          <CopyButton
            text={copyText}
            successMessage={copyTextSuccess}
            iconSize={18}
            style={{ marginLeft: "8px" }}
          />
        )}
      </div>
    );
  }
  return null;
};

export default BoldTitleAndValue;
