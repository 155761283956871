import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { RootState } from "@/store/types";
import { applyProfessionalFilter } from "@/types/common/shiftFilters";

import { InternalProfessionalFilter } from "../../services/api";
import {
  FacilityDataFieldDefinition,
  InternalProfessional,
  InvitationStatus,
} from "../../types/internal";
import { EmptyShiftsState } from "./EmptyState";
import { InternalProfessionalCard } from "./InternalProfessionalCard";
import { ProfessionalFilter } from "./ProfessionalFilter";
import { SearchBar } from "./SearchBar";

interface InternalProfessionalsListComponentProps {
  professionals: InternalProfessional[];
  loading: boolean;
  selectedFilter: InternalProfessionalFilter;
  selectedProfessionalId: number | null;
  setSelectedProfessionalId: (id: number) => void;
  loadNextPage: () => void;
  searchQuery: string;
  setSearchQuery: (text: string) => void;
  hasMoreData: boolean;
  totalProfessionals: number | null;
  pendingRequests: InternalProfessional[];
  dataFieldDefinitions: FacilityDataFieldDefinition[];
}

export const InternalProfessionalsListComponent: React.FC<
  InternalProfessionalsListComponentProps
> = ({
  professionals,
  loading,
  selectedProfessionalId,
  setSelectedProfessionalId,
  loadNextPage,
  hasMoreData,
  searchQuery,
  setSearchQuery,
  totalProfessionals,
  pendingRequests,
  dataFieldDefinitions,
}) => {
  const { t } = useTranslation("internal-professional-page");
  const [invitationStatusFilter, setInvitationStatusFilter] = useState("all");
  let displayProfessionals =
    invitationStatusFilter === "pending" ? pendingRequests : professionals;
  const filters = useSelector(
    (state: RootState) => state.professionalFilters.filters
  );
  filters.forEach(
    (f) =>
      (displayProfessionals = applyProfessionalFilter(f, displayProfessionals))
  );

  const scrollableDivRef = useRef<HTMLDivElement | null>(null);

  const scrollToTop = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTo({
        top: 0,
      });
    }
  };
  const numberOfColumns = dataFieldDefinitions.length + 2;
  const gridColsStyle = `grid-cols-${numberOfColumns}`;
  useEffect(() => {
    scrollToTop();
  }, [searchQuery, invitationStatusFilter]);
  return (
    <div className="flex max-h-max flex-1 justify-center py-xLarge">
      <div className="flex w-full max-w-[1500px] flex-1 flex-col  px-medium">
        <div className="mb-large flex items-center space-x-small">
          <p className="heading-md text-ellipsis text-nowrap">
            {t("professionals_page_title")}
          </p>
          <p className="body-large text-Text-Subtle ">
            ({displayProfessionals.length})
          </p>
        </div>
        <SearchBar
          searchText={searchQuery}
          setSearchText={setSearchQuery}
          searchPlaceHolder={t("search_placeholder")}
        />
        <div className="mt-large flex h-fit w-full flex-col">
          <ProfessionalFilter
            appliedFilter={invitationStatusFilter}
            setFilter={(filter: any) => setInvitationStatusFilter(filter)}
            nPendingClaims={
              invitationStatusFilter !== "pending" &&
              totalProfessionals !== null
                ? pendingRequests.length
                : null
            }
          />
        </div>
        <div className="flex h-fit w-full flex-col">
          <div
            className="flex flex-1 flex-row items-center px-small"
            style={{
              minWidth: "200px",
            }}
          >
            <div
              className={`grid flex-1 ${gridColsStyle} mb-large items-center space-x-small border-b border-Divider-Default p-small text-left text-Text-Subtle`}
            >
              <div className="table-header  col-span-2">
                {t("professional_table_name_header")}
              </div>
              {dataFieldDefinitions.map((dataField, index) => (
                <div key={index} className="table-header col-span-1 text-left">
                  <p className="text-clip">{dataField.label}</p>
                </div>
              ))}
            </div>
            <div
              className="size-[20px]" // space for notifications badge
            ></div>
          </div>

          {loading ? (
            <div className="flex h-fit flex-1 items-center justify-center">
              <CircularProgress />
            </div>
          ) : displayProfessionals.length === 0 ? (
            invitationStatusFilter === "pending" ? (
              <EmptyShiftsState title={t("no_requests_title")} />
            ) : (
              <EmptyShiftsState
                title={t("no_professionals_title")}
                subtitle={t("no_professionals_subtitle")}
              />
            )
          ) : (
            <div
              id="scrollableDiv"
              className="pb-100 no-scrollbar w-full space-y-small"
              ref={scrollableDivRef}
              style={{
                overflow: "scroll",
                height: "calc(100vh - 370px)", // this is needed to know where the scroll list finishes, if not the the will overflow and the sroll won't happen on the infinite scroll
              }}
            >
              <InfiniteScroll
                dataLength={displayProfessionals.length}
                next={() => loadNextPage()}
                hasMore={hasMoreData}
                loader={<div />}
                scrollableTarget="scrollableDiv"
                className="space-y-small"
                scrollThreshold={0.7}
              >
                {displayProfessionals.map((item) => (
                  <InternalProfessionalCard
                    key={item.id}
                    professional={item}
                    isSelected={selectedProfessionalId === item.id}
                    onClick={() => setSelectedProfessionalId(item.id)}
                    notifications={
                      item.invitationStatus === InvitationStatus.PENDING
                        ? 1
                        : undefined
                    }
                    dataFieldDefinitions={dataFieldDefinitions}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
          <div className="h-large" />
        </div>
      </div>
    </div>
  );
};
