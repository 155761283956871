import { useDispatch } from "react-redux";

import { ShiftConfiguration } from "@/components/publishShift/BaseShiftForm";
import PublishEditModal from "@/components/publishShift/PublishEditModal";

import { ShiftPublicationRequest } from "@/services/publish-shift";
import {
  ShiftUpdateRequest,
  updateShiftRequest,
} from "@/services/shifts-calendar";
import { setDateSelected } from "@/store/actions";
import { RecurrencyOptionsEnum } from "@/types/publish-shift";
import { Shift } from "@/types/shifts";
import { day } from "@/utils/datetime";

interface EditShiftModalProps {
  onClose: () => void;
  isOpen: boolean;
  reloadData: () => void;
  shiftDetails: Shift | null;
  bulkOperation?: RecurrencyOptionsEnum;
}

export const EditShiftModal: React.FC<EditShiftModalProps> = ({
  onClose,
  isOpen,
  reloadData,
  shiftDetails,
}) => {
  // select the the start time according to shift time in day

  const dispatch = useDispatch();

  const shiftDetailsConfig = shiftDetails
    ? {
        ...shiftDetails,
        recurrentDates: [day(shiftDetails.startTime).format("YYYY-MM-DD")],
        compensationOptions:
          shiftDetails?.compensationOptions.map((option) => option.value) || [],
      }
    : null;

  return (
    <div>
      <PublishEditModal
        onSubmit={(shiftUpdateRequest: ShiftPublicationRequest) => {
          return updateShiftRequest(shiftDetails!!.id, {
            ...shiftUpdateRequest,
          } as ShiftUpdateRequest).then(() => {
            onClose();
            if (
              day(shiftUpdateRequest.startTime).isSame(
                shiftDetails!!.date,
                "day"
              )
            ) {
            } else {
              dispatch(
                setDateSelected(
                  day(shiftUpdateRequest.startTime).format("YYYY-MM-DD")
                )
              );
            }
            reloadData();
          });
        }}
        shiftDetails={
          {
            ...shiftDetailsConfig,
          } as ShiftConfiguration
        }
        onClose={onClose}
        isOpen={isOpen}
        isEditing={true}
      />
    </div>
  );
};
