import { Typography } from "@mui/material";

interface SectionContainerProps {
  title: string;
  children: React.ReactNode;
}

const SectionContainer = ({ title, children }: SectionContainerProps) => {
  return (
    <div className="flex flex-col gap-3 rounded-lg bg-white p-4">
      <Typography
        variant="h6"
        className="mb-2 font-semibold  !text-Text-Subtle"
      >
        {title}
      </Typography>
      {children}
    </div>
  );
};

export default SectionContainer;
