import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import { IconPlus } from "@tabler/icons-react";

import OfferDetail from "@/pages/OfferDetail";
import OfferCreation from "@/pages/OfferDetail/OfferCreationPage/OfferCreation";
import {
  FilterSelection,
  FiltersRow,
} from "@/pages/OfferList/components/FilterRow";
import OfferItem from "@/pages/OfferList/components/OfferItem";
import OfferRow from "@/pages/OfferList/components/OfferRow";
import {
  OfferProvider,
  useOfferContext,
} from "@/pages/OfferList/contexts/offer.context";
import useFetchOfferList from "@/pages/OfferList/hooks/useFetchOfferList";
import OfferSlotView from "@/pages/OfferList/views/OfferSlots";
import LoadingView from "@/components/common/LoadingView";
import { MaterialActionButton } from "@/components/common/MaterialActionButton";

import colors from "@/config/color-palette";
import { OfferStatus } from "@/types/offers";
import { markdown } from "@/utils/markdown";

const OfferList: React.FC = () => {
  const { t } = useTranslation("offers");
  const navigate = useNavigate();
  const { selectedFilter, setFilter, setSlots } = useOfferContext();
  const { status = "all" } = selectedFilter;

  const { isLoading, listResponse } = useFetchOfferList();
  const { slots, contactedReason, rows } = listResponse ?? {};

  useEffect(() => {
    setSlots(slots ?? undefined);
  }, [setSlots, slots]);

  const offers = useMemo(() => {
    return (
      listResponse?.rows.filter((offer) => {
        const _status = status as keyof typeof OFFER_STATUSES;
        if (!_status || _status === "all") return true;
        return offer.status === OFFER_STATUSES[_status]?.mappingStatus;
      }) ?? []
    );
  }, [listResponse?.rows, status]);

  const openOfferCreationPage = () => {
    navigate("/ofertas-publicadas?action=create-offer");
  };

  return (
    <div className="relative flex size-full justify-between overflow-hidden">
      <div className="no-scrollbar flex w-full flex-1 justify-center overflow-y-auto pt-xLarge">
        <div className="flex size-full max-w-screen-lg flex-col px-medium  pb-xLarge">
          <div className="mb-large flex w-full flex-wrap items-center justify-between space-x-small">
            <div className="mr-small flex items-center space-x-small">
              <Typography
                variant="h4"
                className="text-ellipsis text-nowrap !text-s06 !font-semibold !leading-r02"
              >
                {t("offer_list_title")}
              </Typography>
              <span className="!text-f01 leading-r02 text-Text-Subtle">
                ({(slots?.offers?.length || 0) + (rows?.length || 0)})
              </span>
            </div>

            {/* Create Offer Button */}
            <MaterialActionButton
              tint={colors["Primary-500"]}
              variant="outlined"
              startIcon={<IconPlus size={24} />}
              className="!rounded-full !border-Primary-500 !bg-BG-Default px-4 normal-case !text-Primary-500"
              onClick={openOfferCreationPage}
            >
              {t("create_offer_button")}
            </MaterialActionButton>
          </div>

          <OfferSlotView
            slots={slots}
            isLoading={isLoading}
            contactedReason={contactedReason}
          />

          <div className="mt-6 flex-col w-full flex-wrap items-center gap-4 pb-large">
            <Typography
              variant="h5"
              className="text-ellipsis text-nowrap !text-s05 !font-semibold !leading-r02"
            >
              {t("offer_title_created")}
            </Typography>
            <Typography variant="body1" className="pb-4">
              {t("number_slots_in_month", {
                slotsInMonth: slots?.maxPublicationsPerMonth,
              })}
            </Typography>
            <FiltersRow
              selections={OFFER_STATUS_FILTER_SELECTIONS}
              appliedFilter={status}
              setFilter={(filter: string) => {
                setFilter({ ...selectedFilter, status: filter });
              }}
            />
          </div>
          <div className="flex w-full flex-col justify-center space-y-small pb-5">
            {/* loading state */}
            {isLoading && <LoadingView />}

            {/* empty view */}
            {!isLoading && offers.length === 0 && (
              <>
                {!selectedFilter.search && <NoOfferEver />}

                {!!selectedFilter.search && (
                  <div className="flex flex-col items-center p-medium text-center">
                    <p className="heading-md mb-small text-Text-Default">
                      {t("empty_offer_state_title")}
                    </p>
                  </div>
                )}
              </>
            )}

            {/* offers list */}
            {!isLoading && offers.length > 0 && (
              <div className="flex w-full flex-col gap-6 pb-12">
                {offers.map((offer, index) => (
                  <OfferRow
                    key={`offer-list-${status ?? "all"}-${offer.id}`}
                    index={index}
                  >
                    <OfferItem offer={offer} />
                  </OfferRow>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <OfferDetail />
      <OfferCreation />
    </div>
  );
};

const OFFER_STATUSES = {
  all: {
    index: 0,
    label: "filter_all",
    mappingStatus: "ALL",
  },
  draft: {
    index: 1,
    label: "filter_draft",
    mappingStatus: OfferStatus.DRAFT,
  },
  closed: {
    index: 2,
    label: "filter_closed",
    mappingStatus: OfferStatus.CLOSED,
  },
};

const OFFER_STATUS_FILTER_SELECTIONS: FilterSelection[] = Object.entries(
  OFFER_STATUSES
)
  .map(([id, { label, mappingStatus, index }]) => ({
    id,
    index,
    label,
    mappingStatus,
  }))
  .sort((a, b) => a.index - b.index);

const OfferListContainer = () => {
  return (
    <OfferProvider>
      <OfferList />
    </OfferProvider>
  );
};

const NoOfferEver = () => {
  const { t } = useTranslation("offers");
  return (
    <div className="mb-2 py-4">
      <Typography variant="h6" className="mb-1 font-semibold leading-6">
        {t("list_no_offer_ever_title")}
      </Typography>
      <Typography variant="body1" className="leading-6">
        {markdown(t("list_no_offer_ever_content"))}
      </Typography>
    </div>
  );
};

export default OfferListContainer;
