import LivoIcon from "@/components/common/LivoLogo";

interface ClickableRowProps {
  leftIcon?: string;
  text: string;
  rightIcon?: string;
  iconSize?: number;
  leftIconColor?: string;
  rightIconColor?: string;
  onClick?: () => void;
}

export default function ClickableRow({
  leftIcon,
  text,
  rightIcon,
  onClick,
  iconSize,
  leftIconColor,
  rightIconColor,
}: ClickableRowProps) {
  return (
    <div
      onClick={onClick}
      className={`
        flex flex-row items-center justify-between
        ${onClick ? "cursor-pointer" : "cursor-default"}
      `}
    >
      <div className="flex flex-row items-center">
        {leftIcon && (
          <LivoIcon
            name={leftIcon}
            size={iconSize ?? 24}
            color={leftIconColor ?? "#ACBBCB"}
          />
        )}
        <span className="mx-small text-Primary-500">{text}</span>
      </div>

      {rightIcon ? (
        <LivoIcon
          name={rightIcon}
          size={iconSize ?? 24}
          color={rightIconColor ?? "#149EF2"}
        />
      ) : (
        <LivoIcon
          name="chevron-right"
          size={iconSize ?? 24}
          color={rightIconColor ?? "#149EF2"}
        />
      )}
    </div>
  );
}
