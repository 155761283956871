import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useFetchCities } from "@/pages/RegisterFlow/hooks/useFetchCities";
import FormAutocomplete from "@/components/form/FormAutocomplete";
import FormTextField from "@/components/form/FormTextField";

const Step3 = () => {
  const { t } = useTranslation("register");
  const { control } = useFormContext();
  const { cities: citiesOptions, isLoading } = useFetchCities();

  return (
    <div className="flex w-full flex-col items-center gap-4">
      <h3 className="text-center text-lg font-bold">
        {t("form_step_3_title")}
      </h3>
      <p className="mb-6 self-start text-start text-sm text-gray-600">
        {t("form_step_3_subtitle")}
      </p>

      <div className="w-full space-y-4">
        <FormTextField
          name="legal_name"
          control={control}
          label={t("form_step_3_legal_name")}
          placeholder={t("form_step_3_legal_name_placeholder")}
          variant="outlined"
          fullWidth
        />
        <FormTextField
          name="public_name"
          control={control}
          label={t("form_step_3_public_name")}
          placeholder={t("form_step_3_public_name_placeholder")}
          variant="outlined"
          fullWidth
        />
        <FormTextField
          name="cif"
          control={control}
          label={t("form_step_3_cif")}
          variant="outlined"
          fullWidth
        />
        <FormTextField
          name="address"
          control={control}
          label={t("form_step_3_address")}
          variant="outlined"
          fullWidth
        />
        <FormAutocomplete
          loading={isLoading}
          name="city_code"
          label={t("form_step_3_province")}
          control={control}
          options={citiesOptions}
          className="w-full"
          clearIcon={false}
        />
        <FormTextField
          name="web_page"
          control={control}
          label={t("form_step_3_web_page")}
          variant="outlined"
          fullWidth
        />
      </div>
    </div>
  );
};

export default Step3;
