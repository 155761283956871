import LivoIcon from "@/components/common/LivoLogo";

import { NotificationsBadge } from "./NotificationsBadge";

interface SideBarButtonProps {
  name: string;
  isFocused: boolean;
  onClick: () => void;
  notifications?: number;
}

export const SideBarButton: React.FC<SideBarButtonProps> = ({
  name,
  isFocused,
  onClick,
  notifications,
}) => {
  return (
    <button
      onClick={onClick}
      className={`p-small ${isFocused ? "bg-Primary-100" : "hover:bg-Background-Secondary"} relative rounded-[16px]`}
    >
      <LivoIcon
        name={name}
        size={36}
        color={isFocused ? "#149EF2" : "#ACBBCB"}
      />
      {notifications ? (
        <div className="absolute right-0 top-0 p-[2px]">
          <NotificationsBadge notifications={notifications} />
        </div>
      ) : null}
    </button>
  );
};
