import { useState } from "react";

import LivoIcon from "@/components/common/LivoLogo";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  setValue: (time: string) => void;
  selectedValue: string;
  onValueBlur?: (value: string) => void;
  iconName?: string;
  placeHolder?: string;
  errorMessage?: string;
  endingLabel?: string;
  inputType?: string;
  inputStyle?: any;
  hasChanged?: boolean;
  callToActionIcon?: string;
  label?: string;
  isLoading?: boolean;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  setValue,
  selectedValue,
  onValueBlur,
  iconName,
  placeHolder,
  errorMessage,
  endingLabel,
  inputType,
  inputStyle,
  callToActionIcon,
  hasChanged,
  label,
  isLoading,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    onValueBlur?.(selectedValue);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  return (
    <div className="relative flex flex-col">
      {label && selectedValue && selectedValue !== "" ? (
        <div className="absolute -top-small left-small bg-white px-tiny">
          <p className="info-overline text-Text-Subtle">{label}</p>
        </div>
      ) : null}
      <div
        className={`ring-solid shrink-1 box-border flex w-full flex-row items-center justify-start space-x-small rounded-[8px] bg-white px-small py-medium ring-1
                        ${errorMessage ? "ring-2 ring-red-500" : "ring-Divider-Subtle focus-within:ring-2 focus-within:ring-Action-Secondary"} `}
      >
        {hasChanged && !isFocused ? (
          <div className="size-small rounded-full bg-Action-Primary" />
        ) : null}
        {iconName && (
          <div className="items-center justify-center">
            <LivoIcon
              name={iconName}
              size={24}
              color={isFocused ? "#4F989E" : "#ACBBCB"}
            />
          </div>
        )}
        <input
          type={inputType ? inputType : "text"}
          value={selectedValue}
          onChange={handleInputValue}
          onBlur={handleInputBlur}
          className="body-regular placeholder:body-regular w-full placeholder:text-Text-Subtle focus:outline-none"
          placeholder={placeHolder}
          onFocus={handleInputFocus}
          style={inputStyle}
          {...props}
        />
        {isLoading ? (
          <div className="flex items-center justify-center">
            <div className="size-large animate-spin rounded-full border-2 border-Action-Primary border-t-transparent" />
          </div>
        ) : null}
        {endingLabel ? (
          <p className="body-regular ml-small text-Text-Subtle">
            {endingLabel}
          </p>
        ) : null}
        {callToActionIcon && (
          <div className="items-center justify-center">
            <LivoIcon name={callToActionIcon} size={24} color={"#149EF2"} />
          </div>
        )}
      </div>
      {errorMessage ? (
        <p className="info-caption mt-tiny text-Negative-500">{errorMessage}</p>
      ) : null}
    </div>
  );
};
