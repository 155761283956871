import LivoIcon from "@/components/common/LivoLogo";

interface InformationRowProps {
  iconName: string;
  children: React.ReactNode;
  iconColor?: string;
  style?: any;
}

export const InformationRow: React.FC<InformationRowProps> = ({
  iconName,
  children,
  iconColor,
  style,
}) => {
  return (
    <div
      className="flex w-full flex-row items-center space-x-small"
      style={style}
    >
      <div className="flex items-center justify-center">
        <LivoIcon
          name={iconName}
          size={24}
          color={iconColor ? iconColor : "#ACBBCB"}
        />
      </div>
      <div className="body-sm flex flex-1 items-center space-x-small text-Text-Default">
        {children}
      </div>
    </div>
  );
};
