import { CategoryTag } from "@/components/common/CategoryTag";
import { NotificationsBadge } from "@/components/common/NotificationsBadge";

import { ProfilePicture } from "../../components/shiftDetails/ProfilePicture";
import {
  FacilityDataFieldDefinition,
  InternalProfessional,
  internalProfessionalDisplayName,
} from "../../types/internal";
import { TagLabelRow } from "../common/TagLabelRow";

interface InternalProfessionalCardProps {
  professional: InternalProfessional;
  isSelected?: boolean;
  onClick?: () => void;
  notifications?: number;
  dataFieldDefinitions: FacilityDataFieldDefinition[];
}

export const InternalProfessionalCard: React.FC<
  InternalProfessionalCardProps
> = ({
  professional,
  isSelected,
  onClick,
  notifications,
  dataFieldDefinitions,
}) => {
  const numberOfDataFields = dataFieldDefinitions.length + 2;
  const gridCols = `grid-cols-${numberOfDataFields}`;

  return (
    <div
      onClick={onClick}
      className={`flex flex-row  items-center p-small ${isSelected ? "bg-Action-Secondary text-Text-Inverse" : "bg-white"} relative rounded-[16px] ${onClick ? "cursor-pointer" : ""} overflow-hidden`}
      style={{
        minWidth: "200px",
      }}
    >
      <div
        className={`grid flex-1 ${gridCols} items-center justify-between space-x-small`}
      >
        <div
          className={`col-span-2 flex items-center space-x-small text-nowrap text-left`}
        >
          <ProfilePicture
            profilePictureUrl={professional.profilePictureUrl}
            size={36}
            modality={null}
          />
          {professional.category ? (
            <CategoryTag text={professional.category.acronym} />
          ) : null}
          <p
            className="subtitle-regular truncate "
            title={internalProfessionalDisplayName(professional)}
          >
            {internalProfessionalDisplayName(professional)}
          </p>
        </div>
        {dataFieldDefinitions.map((dataFieldDefinition) => {
          const selectedValue = professional.dataFields.find(
            (dataField) => dataField.key === dataFieldDefinition.key
          )?.displayText;
          const multiSelectOption = dataFieldDefinition.options.map(
            (option) => option.value
          );
          return (
            <div
              key={dataFieldDefinition.key}
              className="col-span-1 flex text-nowrap text-left"
              title={selectedValue?.split(", ").join("\n") || "-"} // Tooltip with full text
            >
              {dataFieldDefinition.key === "MULTI_SELECT" && selectedValue ? (
                <TagLabelRow tags={multiSelectOption} />
              ) : (
                <p className="body-regular truncate">{selectedValue || "-"}</p>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-[20px]">
        {notifications && <NotificationsBadge notifications={notifications} />}
      </div>
    </div>
  );
};
