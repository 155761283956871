import { useTranslation } from "react-i18next";

export const PastEmptyShiftsState = () => {
  const { t } = useTranslation("calendar");
  return (
    <div
      className="relative rounded-[16px] bg-white pl-small"
      style={{
        minWidth: "176px",
      }}
    >
      <div className="flex flex-col p-medium">
        <p className="body-regular text-Text-Default">
          {t("past_empty_shifts_state_title")}
        </p>
      </div>
    </div>
  );
};
