interface EmptyShiftsStateProps {
  title?: string;
  subtitle?: string;
}
export const EmptyShiftsState: React.FC<EmptyShiftsStateProps> = ({
  title,
  subtitle,
}) => {
  return (
    <div className="mt-medium flex flex-col p-medium text-center">
      {title && (
        <p className="heading-md mb-small text-Text-Default">{title}</p>
      )}
      {subtitle && <p className="body-regular text-Text-Default">{subtitle}</p>}
    </div>
  );
};
