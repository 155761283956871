import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { IconArrowNarrowLeft } from "@tabler/icons-react";

import LegalFilesCard from "@/components/LegalFilesCard";
import ProfessionalProfileCard from "@/components/ProfessionalProfileCard";
import ShiftInformationCard from "@/components/ShiftInformationCard";

import {
  confirmRequest,
  fetchContactInfo,
  fetchLegalReviewDetails,
} from "@/services/api";
import { ShiftClaimDetails } from "@/types";
import {
  FacilityReviewStatusEnum,
  getReviewStatusLabelProps,
} from "@/utils/constants";

import "../styles/ShiftClaimDetails.css";

export const ShiftClaimDetailsPage: React.FC = () => {
  const { t } = useTranslation("shift-claim-details");
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [shiftClaimDetails, setShiftClaimDetails] =
    useState<ShiftClaimDetails | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"error" | "success">(
    "success"
  ); // You can set this to 'error' for error messages
  const [email, setEmail] = useState("");
  const handleGoBack = () => {
    window.location.href = "/documentacion";
  };
  const fetchShiftClaimDetails = async () => {
    setLoading(true);
    await fetchContactInfo()
      .then((contactInfo) => setEmail(contactInfo.email))
      .catch((error) => openSnackbar(t("error_contact_info"), "error"));
    await fetchLegalReviewDetails(+id!!)
      .then((shiftClaimDetails) => {
        setShiftClaimDetails(shiftClaimDetails);
        setLoading(false);
      })
      .catch((error) => {
        setShiftClaimDetails(null);
        setLoading(false);
      });
  };

  const openSnackbar = (message: string, severity: "error" | "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleConfirmClick = async () => {
    confirmRequest(shiftClaimDetails!!.shiftClaimId)
      .then(() => {
        openSnackbar(t("confirmation_success_message"), "success");
        fetchShiftClaimDetails();
      })
      .catch(() => openSnackbar(t("confirmation_error_message"), "error"));
  };

  useEffect(() => {
    fetchShiftClaimDetails();
  }, []);

  const reviewStatusLabelProps = useMemo(() => getReviewStatusLabelProps(), []);

  if (loading) {
    return (
      <Container
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          minHeight: "30em",
          minWidth: "100%",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  const statusLabelProp =
    reviewStatusLabelProps[
      shiftClaimDetails?.facilityReviewStatus as keyof typeof reviewStatusLabelProps
    ];

  const shiftClaimSubtitle =
    shiftClaimDetails !== null ? ` - ${statusLabelProp.label}` : "";
  const title = t("page_title") + shiftClaimSubtitle;

  const shiftClaimDetailsComponent =
    shiftClaimDetails !== null ? (
      <>
        <Box sx={{ flexDirection: "column", display: "flex", mb: 3 }}>
          <ShiftInformationCard
            claim={shiftClaimDetails!!}
            rightHeader={
              shiftClaimDetails?.facilityReviewStatus ===
              FacilityReviewStatusEnum.IN_REVIEW ? (
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleConfirmClick}
                  size="medium"
                >
                  {t("confirm_button_label")}
                </Button>
              ) : undefined
            }
          />
          <Box sx={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
            <ProfessionalProfileCard
              professionalInitProfile={
                shiftClaimDetails!!.professionalInitProfile
              }
              professionalLegalProfile={
                shiftClaimDetails!!.professionalLegalProfile
              }
            />
            <LegalFilesCard
              professionalInitProfile={
                shiftClaimDetails!!.professionalInitProfile
              }
              professionalLegalProfile={
                shiftClaimDetails!!.professionalLegalProfile
              }
              shiftClaimId={shiftClaimDetails!!.shiftClaimId}
            />
          </Box>
          {email && (
            <Card sx={{ m: 1 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  {t("contact_label")}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {t("contact_info")}&nbsp;
                  <a href={`mailto:${email}`}>{email}</a>
                </Typography>
              </CardContent>
            </Card>
          )}
        </Box>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={() => setSnackbarOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </>
    ) : (
      <Card>
        <CardContent>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            {t("unavailable_shift_message")}
          </Typography>
        </CardContent>
      </Card>
    );

  return (
    <div className="content flex overflow-auto">
      <Container sx={{ pb: 2, pt: 2, height: "100vh" }}>
        <Box
          sx={{
            mt: 2,
            mb: 2,
            alignItems: "center",
            flexDirection: "row",
            display: "flex",
          }}
        >
          <IconButton onClick={handleGoBack} size="large" sx={{ mr: 1 }}>
            <IconArrowNarrowLeft />
          </IconButton>
          <Typography variant="h4">{title}</Typography>
        </Box>
        {shiftClaimDetailsComponent}
      </Container>
    </div>
  );
};
