import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import NotFound from "@/pages/404";
import Landing from "@/pages/Landing";
import EmailGateKeeper from "@/pages/RegisterFlow/views/EmailGateKeeper";
import RegisterFlow from "@/pages/RegisterFlow/views/RegisterFlow";
import ResetPasswordFlow from "@/pages/ResetPasswordFlow/ResetPasswordFlow";
import SignInForm from "@/pages/SignInForm";

import { protectedRoutes } from "@/routers/config";
import { useProtectedRoutes } from "@/routers/hooks/use-protected-router";
import ProtectedLayout from "@/routers/layout/ProtectedLayout";
import OfflineRoute from "@/routers/OfflineRoute";
import ProtectedRoute from "@/routers/ProtectedRoute";
import PublicRoute from "@/routers/PublicRoute";
import { ACCOUNT_TYPE } from "@/services/account";

interface RouterComponentProps {
  logOut: () => void;
}

export const RouterComponent: React.FC<RouterComponentProps> = ({ logOut }) => {
  const filteredRoutes = useProtectedRoutes();
  const wasLoggedInBefore = localStorage.getItem("last_login") !== null;

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path="/" element={<Landing />}>
            <Route
              index
              element={wasLoggedInBefore ? <SignInForm /> : <EmailGateKeeper />}
            />
            <Route path="signin" element={<SignInForm />} />
            <Route path="register" element={<EmailGateKeeper />} />
          </Route>
          {Object.values(ACCOUNT_TYPE).map((accountType) => (
            <Route path={accountType}>
              <Route
                path="reset-password"
                element={<ResetPasswordFlow accountType={accountType} />}
              />
            </Route>
          ))}
          <Route path="register/new-account" element={<RegisterFlow />} />
        </Route>

        <Route element={<OfflineRoute />}>
          <Route element={<ProtectedRoute />}>
            <Route element={<ProtectedLayout logOut={logOut} />}>
              {protectedRoutes.map((route) => {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={<route.component />}
                  />
                );
              })}
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
