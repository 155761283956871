import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import useFetchOfferClaims from "@/pages/OfferDetail/hooks/useFetchOfferClaims";
import {
  FilterSelection,
  FiltersRow,
} from "@/pages/OfferList/components/FilterRow";
import LoadingView from "@/components/common/LoadingView";

import { OfferClaim } from "@/types/offers";

import { OfferClaimCard } from "../components/OfferClaimCard";
import { OfferClaimCardCompact } from "../components/OfferClaimCardCompact";
import { OfferClaimDetail } from "./OfferClaimDetail";

import clsx from "clsx";

interface OfferCandidateListProps {}

export const OFFER_CANDIDATES_FILTER_SELECTIONS: FilterSelection[] = [
  {
    id: "all",
    label: "filter_all",
    mappingStatus: "ALL",
  },
  {
    id: "activeClaims",
    label: "filter_active",
    mappingStatus: "ACTIVE",
  },
  {
    id: "discardedClaims",
    label: "filter_discarded",
    mappingStatus: "CLOSED",
  },
];

export const OfferClaimsList: React.FC<OfferCandidateListProps> = () => {
  const { t } = useTranslation("offers");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [selectedId, setSelectedId] = useState<number | undefined | null>();
  const location = useLocation();
  const offerId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("offerId") ?? "";
  }, [location.search]);

  const { claimsResponse, isLoading, refetch } = useFetchOfferClaims(
    offerId,
    selectedFilter
  );

  const { rows: claims = [], total = 0 } = claimsResponse ?? {};

  const selectedOfferClaim = useMemo(() => {
    return claims.find((claim) => claim.id === selectedId);
  }, [claims, selectedId]);

  const setSelectedOfferClaimId = (claim: OfferClaim) => {
    setSelectedId(claim.id);
  };

  return (
    <div className="flex w-full flex-1 justify-center">
      <div
        className={clsx(
          "no-scrollbar transition-width flex  h-full max-w-screen-lg flex-col overflow-y-auto px-5 pb-xLarge pt-6 transition duration-300",
          !!selectedOfferClaim ? "w-[400px]" : "w-full"
        )}
      >
        <div className="mb-large flex w-full flex-wrap items-center justify-between space-x-small">
          <div className="mr-small flex items-center space-x-small">
            <p className="heading-md text-ellipsis text-nowrap">
              {t("offer_candidates")}
            </p>
            {total > 0 && (
              <p className="body-large text-Text-Subtle">({total})</p>
            )}
          </div>
        </div>
        <div className="flex w-full flex-wrap items-center space-x-tiny space-y-tiny pb-large">
          <FiltersRow
            selections={OFFER_CANDIDATES_FILTER_SELECTIONS}
            appliedFilter={selectedFilter}
            setFilter={(filter: string) => {
              setSelectedFilter(filter);
            }}
          />
        </div>
        <div className="flex w-full justify-center space-y-small">
          {/* loading state */}
          {isLoading && <LoadingView />}

          {/* empty view */}
          {!isLoading && claims.length === 0 && (
            <div className="flex flex-col items-center p-medium text-center">
              <p className="heading-md mb-small text-Text-Default">
                {t("empty_offer_claim_title")}
              </p>
            </div>
          )}

          {/* list */}
          {!isLoading && claims.length > 0 && (
            <div className="flex flex-1 flex-col space-y-small">
              <div className="flex w-full flex-col">
                <div className={"flex flex-col  space-y-small"}>
                  {!selectedOfferClaim &&
                    claims.map((claim) => (
                      <OfferClaimCard
                        key={`claim-card-${claim.id}`}
                        claim={claim}
                        isSelected={false}
                        selectClaim={setSelectedOfferClaimId}
                      />
                    ))}
                  {selectedOfferClaim &&
                    claims.map((claim) => (
                      <OfferClaimCardCompact
                        key={`claim-card-compact-${claim.id}`}
                        claim={claim}
                        isSelected={selectedOfferClaim.id === claim.id}
                        selectClaim={setSelectedOfferClaimId}
                      />
                    ))}
                </div>
              </div>

              <div className="h-6"></div>
            </div>
          )}
        </div>
      </div>
      {selectedOfferClaim != null && (
        <OfferClaimDetail
          selectedOfferClaim={selectedOfferClaim}
          onClose={() => setSelectedId(undefined)}
          refreshClaims={refetch}
        />
      )}
    </div>
  );
};
