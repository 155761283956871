import { useTranslation } from "react-i18next";

export const EmptyCardComponent = () => {
  const { t } = useTranslation("calendar");
  return (
    <div
      className={`flex flex-col rounded-[16px] bg-white p-medium`}
      style={{
        minWidth: "200px",
        maxWidth: "1280px",
      }}
    >
      <p className="heading-sm mb-small text-Text-Default">
        {t("activity_empty_state_title")}
      </p>
      <p className="body-regular text-Text-Default">
        {t("activity_empty_state_subtitle")}
      </p>
    </div>
  );
};
