import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "@/store/types";

import { CommonToast } from "./CommonToastComponent";

interface AppToastProps {
  style?: any;
}

export const AppToast: React.FC<AppToastProps> = ({ style }) => {
  const { toast } = useSelector((state: RootState) => state.appConfiguration);
  const dispatch = useDispatch();
  const hideToast = useCallback(() => {
    dispatch({ type: "HIDE_TOAST" });
  }, [dispatch]);

  return toast ? (
    toast.severity === "success" ? (
      <CommonToast
        backgroundColor="#F0FCF2"
        iconColor="#33B240"
        iconName="circle-check-filled"
        message={toast.message}
        onClose={hideToast}
        style={style}
      />
    ) : (
      <CommonToast
        backgroundColor="#FFF0F1"
        iconColor="#FA3D3B"
        iconName="alert-triangle-filled"
        message={toast.message}
        onClose={hideToast}
        style={style}
      />
    )
  ) : null;
};
