import React, { FC } from "react";

import LivoIcon from "@/components/common/LivoLogo";

interface OfferSideBarProps {
  goBack: () => void;
  backLabel: string;
  children?: React.ReactNode;
}

const OfferSideBar: FC<OfferSideBarProps> = ({
  goBack,
  backLabel,
  children,
}) => {
  return (
    <div className="flex h-full w-[256px] flex-col justify-start space-y-small self-start border-r border-solid border-Divider-Subtle bg-white px-medium pt-[20px]">
      <div className="inline-flex items-start justify-start gap-1 px-1 py-2">
        <button onClick={goBack} className="relative size-6">
          <LivoIcon name="arrow-left" size={24} color="#ACBBCB" />
        </button>
        <div className="shrink grow basis-0 font-['Roboto'] text-base font-semibold leading-normal text-Neutral-950">
          {backLabel}
        </div>
      </div>
      {children}
    </div>
  );
};

export default OfferSideBar;
