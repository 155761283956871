import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { CircularProgress } from "@mui/material";

import { ConfirmationModal } from "@/components/common/ConfirmationModal";
import { ExpandableContainer } from "@/components/common/ExpandableContainer";
import { ShiftConfiguration } from "@/components/publishShift/BaseShiftForm";
import { PublishShiftModal } from "@/components/publishShift/PublishShiftModal";
import { AcceptProfessionalWithClaimReason } from "@/components/shiftDetails/professionalClaim/AcceptProfessionalWithClaimReason";

import { handleApiError, isDenarioNotSyncedError } from "@/services/api";
import {
  shiftCancellationAccept,
  shiftCancellationResolve,
  shiftClaimAccept,
  shiftClaimReject,
} from "@/services/claims";
import { Logger } from "@/services/logger.service";
import {
  cancelShiftRequest,
  fetchShiftDetails,
  updateCapacityRequest,
} from "@/services/shifts-calendar";
import { AppDispatch } from "@/store";
import { fetchActivity } from "@/store/actions/activityShiftListActions";
import { showToastAction } from "@/store/actions/appConfigurationActions";
import { ShiftModalityEnum } from "@/types";
import {
  ClaimRequest,
  ClaimStatusEnunm,
  DenarioErrorPayload,
} from "@/types/claims";
import { Shift, ShiftTimeInDayEnum, ShiftTimeStatusEnum } from "@/types/shifts";
import { day } from "@/utils/datetime";

import { AcceptedClaimsComponent } from "./AcceptedClaimsComponent";
import { CancelShiftModal } from "./CancelShiftModal";
import { CancelShiftReasonsModal } from "./CancelShiftReasonsModal";
import { EditShiftModal } from "./EditShiftModal";
import { PendingClaimsComponent } from "./PendingClaimsComponent";
import { InternalProfessionalClaimDetails } from "./professionalClaim/InternalProfessionalClaimDetails";
import { ProfessionalClaimDetails } from "./professionalClaim/ProfessionalClaimDetails";
import { RejectProfessionalReasonsModal } from "./RejectProfessionalReasonsModal";
import { ShiftDetailsBody } from "./ShiftDetailsBody";
import { ShiftDetailsHeader } from "./ShiftDetailsHeader";

interface ShiftDetailsSectionProps {
  reloadShifts: () => void;
  selectedShiftId: number | null;
  setSelectedShiftId: (id: number | null) => void;
}

export const ShiftDetailsSection: React.FC<ShiftDetailsSectionProps> = ({
  reloadShifts,
  selectedShiftId,
  setSelectedShiftId,
}) => {
  const { t } = useTranslation(["shift-claim-details", "common"]);
  const [shiftDetails, setShiftDetails] = useState<Shift | null>(null);
  const [loading, setLoading] = useState(false);
  const [cancelShiftModalOpen, setCancelShiftModalOpen] = useState(false);
  const [removeCapacityModalOpen, setRemoveCapacityModalOpen] = useState(false);
  const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] =
    useState(false);
  const [publishShiftModalOpen, setPublishShiftModalOpen] = useState(false);
  const [selectedClaimId, setSelectedClaimId] = useState<number | null>(null);
  const [acceptingClaimId, setAcceptingClaimId] = useState<number | undefined>(
    undefined
  );
  const [rejectingClaimId, setRejectingClaimId] = useState<number | null>(null);
  const [selectedSlotReasonClaimId, setSelectedSlotReasonClaimId] = useState<
    number | null
  >(null);
  const dispatch = useDispatch<AppDispatch>();
  const selectedClaim = useMemo(
    () => shiftDetails?.claims.find((claim) => claim.id === selectedClaimId),
    [selectedClaimId, shiftDetails?.claims]
  );
  const selectedSlotClaim = useMemo(
    () =>
      shiftDetails?.claims.find(
        (claim) => claim.id === selectedSlotReasonClaimId
      ),
    [selectedSlotReasonClaimId, shiftDetails?.claims]
  );
  const [selectedOption, setSelectedOption] = useState("professionals");
  const [denarioError, setDenarioError] = useState<DenarioErrorPayload | null>(
    null
  );
  const [currentAction, setCurrentAction] = useState<{
    shiftId: number;
    claimId: number;
    actionType: "accept" | "reject" | "cancelAccept";
    reason?: string;
    details?: string;
    skipConstraints?: boolean;
  } | null>(null);
  const [retryingActionWithDenario, setRetryingActionWithDenario] =
    useState(false);
  const [actionWithoutDenario, setActionWithoutDenario] = useState(false);

  const loadData = useCallback(async () => {
    if (!selectedShiftId) {
      return;
    }
    setLoading(true);
    await fetchShiftDetails(selectedShiftId)
      .then((data) => {
        setLoading(false);
        if (data) {
          setShiftDetails(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        Logger.error("fetchShiftDetails", error);
      });
  }, [selectedShiftId]);

  useEffect(() => {
    loadData();
    setSelectedClaimId(null);
    setSelectedOption("professionals");
  }, [selectedShiftId, reloadShifts]);

  const handleClaimError = useCallback(
    (error: any) =>
      error.response.data.errorMessage
        ? dispatch(
            showToastAction({
              message: error.response.data.errorMessage,
              severity: "error",
            })
          )
        : handleApiError(error),
    [dispatch]
  );

  const pendingClaims = useMemo(
    () =>
      shiftDetails?.claims.filter(
        (claim) => claim.status === ClaimStatusEnunm.PENDING_APPROVAL
      ),
    [shiftDetails?.claims]
  );
  const isShiftFullyBooked =
    shiftDetails?.totalAcceptedClaims === shiftDetails?.capacity;
  const flex =
    (pendingClaims && pendingClaims.length > 0) ||
    selectedClaim ||
    !isShiftFullyBooked
      ? "66.6%"
      : "33.3%";

  const acceptClaim = useCallback(
    async (shiftId: number, claimId: number, skipConstraints?: boolean) => {
      setAcceptingClaimId(claimId);
      await shiftClaimAccept(
        shiftId,
        claimId,
        undefined,
        undefined,
        skipConstraints
      )
        .then(() => {
          dispatch(fetchActivity());
          loadData();
          reloadShifts();
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          setDenarioError(null);
        })
        .catch((error) => {
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          if (isDenarioNotSyncedError(error.response.data)) {
            const extraData = error.response.data
              .extraData as DenarioErrorPayload;
            setDenarioError(extraData);
            setCurrentAction({ shiftId, claimId, actionType: "accept" });
          } else {
            handleClaimError(error);
          }
        })
        .finally(() => {
          setAcceptingClaimId(undefined);
        });
    },
    [dispatch, handleClaimError, loadData, reloadShifts]
  );

  const rejectClaim = useCallback(
    async (
      shiftId: number,
      claimId: number,
      reason: string,
      details: string,
      skipConstraints?: boolean
    ) =>
      shiftClaimReject(shiftId, claimId, reason, details, skipConstraints)
        .then(() => {
          setRejectingClaimId(null);
          setSelectedClaimId(null);
          loadData();
          reloadShifts();
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          setDenarioError(null);
        })
        .catch((error) => {
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          if (isDenarioNotSyncedError(error.response.data)) {
            const extraData = error.response.data
              .extraData as DenarioErrorPayload;
            setDenarioError(extraData);
            setCurrentAction({
              shiftId,
              claimId,
              actionType: "reject",
              reason,
              details,
            });
          } else {
            handleClaimError(error);
          }
        }),
    [handleClaimError, loadData, reloadShifts]
  );

  const acceptCancellationRequest = useCallback(
    async function (
      shiftId: number,
      claimId: number,
      skipConstraints?: boolean
    ) {
      shiftCancellationAccept(shiftId, claimId, skipConstraints)
        .then(() => {
          dispatch(fetchActivity());
          loadData();
          reloadShifts();
          setSelectedClaimId(null);
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          setDenarioError(null);
        })
        .catch((error) => {
          setRetryingActionWithDenario(false);
          setActionWithoutDenario(false);
          if (isDenarioNotSyncedError(error.response.data)) {
            const extraData = error.response.data
              .extraData as DenarioErrorPayload;
            setDenarioError(extraData);
            setCurrentAction({ shiftId, claimId, actionType: "cancelAccept" });
          } else {
            handleApiError(error);
          }
        });
    },
    [dispatch, loadData, reloadShifts]
  );

  const isRejectModalOpen = useMemo(
    () => rejectingClaimId !== null,
    [rejectingClaimId]
  );
  const returnFromRejectModal = useCallback(
    () => setRejectingClaimId(null),
    []
  );

  return (
    <ExpandableContainer width={selectedShiftId ? flex : "0%"}>
      <div
        className={`flex size-full min-w-fit border-l border-solid border-Divider-Subtle bg-white`}
      >
        {loading && selectedShiftId ? (
          <div className="flex size-full flex-col items-center justify-center">
            <CircularProgress />
          </div>
        ) : shiftDetails ? (
          <div className={`flex h-full flex-1 divide-x divide-Divider-Default`}>
            <div className="flex h-full flex-1 flex-col pt-xLarge">
              <ShiftDetailsHeader
                title={shiftDetails.specialization.displayText}
                onCopy={() => {
                  setPublishShiftModalOpen(true);
                }}
                onClose={() => {
                  setSelectedShiftId(null);
                }}
                onEdit={() => {
                  setEditShiftDetailsModalOpen(true);
                }}
                onDelete={() => {
                  setCancelShiftModalOpen(true);
                }}
                editable={
                  shiftDetails.shiftTimeStatus === ShiftTimeStatusEnum.UPCOMING
                }
                recurrent={shiftDetails.recurrentDates?.length > 1}
              />
              <div className="no-scrollbar flex h-full flex-1 flex-col space-y-large divide-y divide-Divider-Default overflow-y-auto">
                <ShiftDetailsBody shift={shiftDetails} />

                <AcceptedClaimsComponent
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  shouldShowSlotReasonList={
                    shiftDetails.shouldShowSlotReasonList
                  }
                  claims={shiftDetails.claims.filter(
                    (claim) => claim.status === ClaimStatusEnunm.APPROVED
                  )}
                  capacity={shiftDetails.capacity}
                  updateCapacity={(newCapacity: number) => {
                    updateCapacityRequest(shiftDetails!!.id, newCapacity).then(
                      () => {
                        loadData();
                        reloadShifts();
                      }
                    );
                  }}
                  editable={
                    shiftDetails.shiftTimeStatus ===
                    ShiftTimeStatusEnum.UPCOMING
                  }
                  onDecreaseCapacity={() => {
                    setRemoveCapacityModalOpen(true);
                  }}
                  selectClaim={(claim) => setSelectedClaimId(claim.id)}
                />
              </div>
            </div>

            {selectedSlotClaim ? (
              <div className="no-scrollbar flex  size-full flex-1 overflow-y-scroll pt-xLarge">
                <AcceptProfessionalWithClaimReason
                  claim={selectedSlotClaim}
                  goBack={() => {
                    setSelectedSlotReasonClaimId(null);
                  }}
                  onAccept={() => {
                    loadData();
                    reloadShifts();
                    setSelectedSlotReasonClaimId(null);
                  }}
                  handleClaimError={handleClaimError}
                  shiftId={shiftDetails.id}
                />
              </div>
            ) : selectedClaim ? (
              <div className="flex flex-1  pt-xLarge">
                {selectedClaim.modality === ShiftModalityEnum.INTERNAL ? (
                  <InternalProfessionalClaimDetails
                    onUpdateSlotReason={() => {
                      loadData();
                      reloadShifts();
                    }}
                    shiftId={shiftDetails.id}
                    claim={selectedClaim}
                    shiftStatus={shiftDetails.shiftTimeStatus}
                    goBack={() => setSelectedClaimId(null)}
                    onAccept={() => {
                      if (
                        selectedClaim.slotReasonOptions &&
                        selectedClaim.slotReasonOptions?.length > 0
                      ) {
                        setSelectedSlotReasonClaimId(selectedClaim.id);
                      } else {
                        acceptClaim(shiftDetails.id, selectedClaimId!!).then(
                          () => setSelectedClaimId(null)
                        );
                      }
                    }}
                    onReject={() => setRejectingClaimId(selectedClaimId)}
                    acceptCancellationRequest={(claimId) => {
                      acceptCancellationRequest(shiftDetails.id, claimId);
                    }}
                    rejectCancellationRequest={(claimId) => {
                      shiftCancellationResolve(shiftDetails.id, claimId).then(
                        () => {
                          dispatch(fetchActivity());

                          loadData();
                          reloadShifts();
                        }
                      );
                    }}
                    onDelete={reloadShifts}
                  />
                ) : (
                  <ProfessionalClaimDetails
                    shiftId={shiftDetails.id}
                    claimId={selectedClaim.id}
                    goBack={() => setSelectedClaimId(null)}
                    onAccept={() => {
                      if (
                        selectedClaim.slotReasonOptions &&
                        selectedClaim.slotReasonOptions.length > 0
                      ) {
                        setSelectedSlotReasonClaimId(selectedClaim.id);
                      } else {
                        acceptClaim(shiftDetails.id, selectedClaimId!!).then(
                          () => setSelectedClaimId(null)
                        );
                      }
                    }}
                    onReject={() => setRejectingClaimId(selectedClaimId)}
                    onUpdateSlotReason={() => {
                      loadData();
                      reloadShifts();
                    }}
                  />
                )}
              </div>
            ) : (
              !isShiftFullyBooked && (
                <div className="border-left-4 flex  flex-1 overflow-y-auto overflow-x-hidden border-red-500 p-4 px-small pt-xLarge">
                  <PendingClaimsComponent
                    claims={pendingClaims}
                    onAccept={(claim: ClaimRequest) => {
                      if (
                        claim.slotReasonOptions &&
                        claim.slotReasonOptions?.length > 0
                      ) {
                        setSelectedSlotReasonClaimId(claim.id);
                      } else {
                        acceptClaim(shiftDetails.id, claim.id);
                      }
                    }}
                    onReject={(claimId: number) => {
                      dispatch(fetchActivity());
                      setRejectingClaimId(claimId);
                    }}
                    selectClaim={(claim) => setSelectedClaimId(claim.id)}
                    acceptingClaimId={acceptingClaimId}
                    rejectingClaimId={rejectingClaimId || undefined}
                  />
                </div>
              )
            )}
          </div>
        ) : null}
        {shiftDetails && (
          <CancelShiftModal
            isOpen={cancelShiftModalOpen}
            onClose={() => setCancelShiftModalOpen(false)}
            cancelShift={(reason, details, bulkOperation) => {
              cancelShiftRequest(
                shiftDetails!!.id,
                reason,
                details,
                bulkOperation
              ).then(() => {
                setSelectedShiftId(null);
                setCancelShiftModalOpen(false);
                reloadShifts();
              });
            }}
            isRecurrent={
              !!(
                shiftDetails?.recurrentDates &&
                shiftDetails?.recurrentDates?.length > 1
              )
            }
            shiftDetails={shiftDetails}
          />
        )}
        <CancelShiftReasonsModal
          title={t("decrease_capacity_modal_title")}
          isOpen={removeCapacityModalOpen}
          goBack={() => setRemoveCapacityModalOpen(false)}
          cancelShift={(reason, details) => {
            updateCapacityRequest(
              shiftDetails!!.id,
              shiftDetails!!.capacity - 1,
              reason,
              details
            ).then(() => {
              setRemoveCapacityModalOpen(false);
              loadData();
              reloadShifts();
            });
          }}
        />

        <EditShiftModal
          isOpen={editShiftDetailsModalOpen && shiftDetails !== null}
          onClose={() => {
            setEditShiftDetailsModalOpen(false);
          }}
          reloadData={() => {
            loadData();
            reloadShifts();
          }}
          shiftDetails={shiftDetails}
        />
        <RejectProfessionalReasonsModal
          title={t("reject_professional_modal_title")}
          isOpen={isRejectModalOpen}
          goBack={returnFromRejectModal}
          rejectProfessional={(reason, details) =>
            rejectClaim(shiftDetails!!.id, rejectingClaimId!!, reason, details)
          }
        />
        <PublishShiftModal
          isOpen={publishShiftModalOpen && shiftDetails !== null}
          onClose={() => {
            setPublishShiftModalOpen(false);
          }}
          selectedDate={
            (shiftDetails && shiftDetails.startTime) || new Date().toISOString()
          }
          shiftTimeInDay={ShiftTimeInDayEnum.MORNING}
          reloadData={() => {
            reloadShifts();
          }}
          shiftDetails={
            {
              ...shiftDetails,
              recurrentDates: shiftDetails
                ? [day(shiftDetails.startTime).format("YYYY-MM-DD")]
                : [],
              compensationOptions:
                shiftDetails?.compensationOptions.map(
                  (option) => option.value
                ) || [],
            } as ShiftConfiguration
          }
        />
      </div>

      {denarioError ? (
        <ConfirmationModal
          title={denarioError.title}
          subtitle={denarioError.description}
          isOpen={true}
          handleClose={() => {
            setDenarioError(null);
            setRetryingActionWithDenario(false);
            setActionWithoutDenario(false);
          }}
          dismissTitle={denarioError.actions.secondary.title}
          buttonTitle={denarioError.actions.primary.title}
          onPress={() => {
            setRetryingActionWithDenario(true);
            if (currentAction) {
              switch (currentAction.actionType) {
                case "accept":
                  acceptClaim(currentAction.shiftId, currentAction.claimId);
                  break;
                case "reject":
                  rejectClaim(
                    currentAction.shiftId,
                    currentAction.claimId,
                    currentAction.reason!,
                    currentAction.details!
                  );
                  break;
                case "cancelAccept":
                  acceptCancellationRequest(
                    currentAction.shiftId,
                    currentAction.claimId
                  );
                  break;
              }
            }
          }}
          onDismiss={() => {
            setActionWithoutDenario(true);
            if (currentAction) {
              switch (currentAction.actionType) {
                case "accept":
                  acceptClaim(
                    currentAction.shiftId,
                    currentAction.claimId,
                    true
                  );
                  break;
                case "reject":
                  rejectClaim(
                    currentAction.shiftId,
                    currentAction.claimId,
                    currentAction.reason!,
                    currentAction.details!,
                    true
                  );
                  break;
                case "cancelAccept":
                  acceptCancellationRequest(
                    currentAction.shiftId,
                    currentAction.claimId,
                    true
                  );
                  break;
              }
            }
          }}
          buttonIsLoading={retryingActionWithDenario}
          dismissIsLoading={actionWithoutDenario}
        />
      ) : null}
    </ExpandableContainer>
  );
};
