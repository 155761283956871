import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { ShiftTimeInDayEnum } from "@/types/shifts";
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "@/utils";

interface ShiftInDaySelectorProps {
  shiftTimeInDay: string;
  setShiftTimeInDay: (shiftTimeInDay: string) => void;
}

export const ShiftTimeInDaySelector: React.FC<ShiftInDaySelectorProps> = ({
  shiftTimeInDay,
  setShiftTimeInDay,
}) => {
  const { t } = useTranslation("calendar");
  return (
    <div className="flex w-full flex-col space-y-medium self-start rounded-[8px] border border-solid border-Divider-Subtle p-small">
      {Object.keys(SHIFT_TIME_IN_DAY_DEFINITIONS).map((key, index) => {
        const props = SHIFT_TIME_IN_DAY_DEFINITIONS[key as ShiftTimeInDayEnum];
        return (
          <button
            key={index}
            onClick={() => setShiftTimeInDay(props.hashKey)}
            className="flex flex-row items-center justify-between space-x-tiny"
          >
            <div className="flex flex-row items-center space-x-tiny">
              <LivoIcon name={props.icon} size={18} color={props.color} />
              <p className="body-regular">{t(props.name as never)}</p>
            </div>
            {
              <LivoIcon
                name={
                  shiftTimeInDay === props.hashKey ? "radio-filled" : "circle"
                }
                size={18}
                color={shiftTimeInDay === props.hashKey ? "#149EF2" : "#ACBBCB"}
              />
            }
          </button>
        );
      })}
    </div>
  );
};
