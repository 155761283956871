import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";
import { TogglerOff } from "@/components/common/TogglerOff";
import { TogglerOn } from "@/components/common/TogglerOn";

interface RecurentTogglerProps {
  isRecurrent: boolean;
  setIsRecurrent: (isRecurrent: boolean) => void;
}

export const RecurrentToggler: React.FC<RecurentTogglerProps> = ({
  isRecurrent,
  setIsRecurrent,
}) => {
  const { t } = useTranslation("publish-shift");
  return (
    <div className="ring-solid flex w-full flex-1 items-center justify-between space-x-small rounded-[8px] px-small py-medium ring-1 ring-Divider-Default">
      <LivoIcon name="repeat" size={24} color="#ACBBCB" />
      <p className="body-regular w-full text-left text-Text-Subtle">
        {t("recurrent_label")}
      </p>
      <button onClick={() => setIsRecurrent(!isRecurrent)}>
        {isRecurrent ? <TogglerOn /> : <TogglerOff />}
      </button>
    </div>
  );
};
