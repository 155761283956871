import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

import i18n from "@/services/i18next";
import store from "@/store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  debug: process.env.NODE_ENV === "development",
};

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={
        process.env.NODE_ENV === "development"
          ? ""
          : (process.env.REACT_APP_PUBLIC_POSTHOG_KEY ?? "")
      }
      options={options}
    >
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
