interface CategoryTagProps {
  text: string;
  style?: any;
}

export const CategoryTag: React.FC<CategoryTagProps> = ({ text, style }) => {
  return (
    <div className="flex items-center justify-start gap-[9.60px] rounded border border-Neutral-600 bg-white p-1">
      <div className=" body-regular leading-none text-Text-Default">{text}</div>
    </div>
  );
};
