import LivoIcon from "@/components/common/LivoLogo";

interface ShiftDetailsRowProps {
  iconName: string;
  children: React.ReactNode;
  iconColor?: string;
  style?: React.CSSProperties;
}

export const ShiftDetailsRow: React.FC<ShiftDetailsRowProps> = ({
  iconName,
  children,
  iconColor,
  style,
}) => {
  return (
    <div
      className="flex w-full flex-row items-start space-x-small"
      style={style}
    >
      <div className="flex items-start justify-center">
        <LivoIcon
          name={iconName}
          size={24}
          color={iconColor ? iconColor : "#ACBBCB"}
        />
      </div>
      <div className="body-sm flex flex-1 items-start space-x-small text-Text-Default">
        {children}
      </div>
    </div>
  );
};
