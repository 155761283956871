export enum TIME {
  "5_seconds" = 1000 * 5,
  "10_seconds" = 1000 * 10,
  "30_seconds" = 1000 * 30,
  "1_minute" = 1000 * 60,
  "5_minutes" = 1000 * 60 * 5,
  "10_minutes" = 1000 * 60 * 10,
  "15_minutes" = 1000 * 60 * 15,
  "30_minutes" = 1000 * 60 * 30,
  "1_hour" = 1000 * 60 * 60,
}
