import React from "react";
import { useTranslation } from "react-i18next";

import { IconCalendarEvent, IconClock } from "@tabler/icons-react";

import OfferInfoHeader from "@/pages/OfferDetail/components/OfferInfoHeader";
import {
  joinSchedule,
  mapContractType,
  mapSalary,
  mapStartDateToText,
} from "@/pages/OfferDetail/utils";
import Chip from "@/components/common/Chip";
import { CopyButton } from "@/components/common/CopyButton";
import LivoIcon from "@/components/common/LivoLogo";

import colors from "@/config/color-palette";
import { OfferDetail } from "@/types/offers";

import clsx from "clsx";

interface OfferInfoCardProps {
  offer: OfferDetail;
  isDetailView?: boolean;
}

const OfferInfoCard: React.FC<OfferInfoCardProps> = ({
  offer,
  isDetailView,
}) => {
  const { t } = useTranslation(["offers", "shift-list"]);
  const { startDate } = offer ?? {};

  return (
    <div
      className={clsx(
        "modern-scrollbar w-full !max-w-3xl overflow-y-auto rounded-lg bg-white",
        isDetailView ? "my-6 p-6" : "my-0 p-2"
      )}
    >
      <OfferInfoHeader offer={offer} isDetailView={isDetailView} />
      {/* Duration */}
      <div className="mb-xLarge">
        <h2 className="subtitle-regular mb-small font-bold">
          {t("offer_contract")}
        </h2>
        <div className="mb-small flex items-center">
          <IconCalendarEvent size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {mapContractType(offer.contractType) ?? "-"}
          </p>
        </div>
        <div className="mb-small flex items-center">
          <IconClock size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {mapStartDateToText(startDate?.type, startDate?.date) ?? "-"}
          </p>
        </div>

        <div className="mb-small flex items-center">
          <IconClock size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {joinSchedule(offer.schedules) ?? "-"}
          </p>
        </div>
      </div>

      {/* Compensation */}
      <div className="mb-xLarge">
        <h2 className="subtitle-regular mb-small font-bold">
          {t("offer_compensation")}
        </h2>
        <div className="flex items-center">
          <LivoIcon name={"cash-banknote"} size={24} color={"#ACBBCB"} />
          <p
            // TODO: temp - need to refine
            className={`body-regular ${true ? "text-Green-700" : "text-Text-Subtle"} ml-small`}
          >
            {mapSalary(offer.salaryMin, offer.salaryMax, offer.salaryPeriod)}
          </p>
        </div>
        {offer.salaryDetails && (
          <p className="body-regular ml-small pl-6">{offer.salaryDetails}</p>
        )}
      </div>

      {/* Perks */}
      <div className="mb-xLarge">
        <h2 className="subtitle-regular mb-small font-bold">
          {t("offer_perks")}
        </h2>
        {offer.perks && offer.perks.length > 0 ? (
          <div className="flex flex-wrap gap-tiny">
            {offer.perks.map((perk) => (
              <Chip
                className="text-Text-Default"
                key={`skill-${perk.displayText}`}
                label={perk.displayText}
                icon={
                  perk.icon && (
                    <LivoIcon
                      name={perk.icon}
                      size={16}
                      color={colors["Text-Subtle"]}
                    />
                  )
                }
              />
            ))}
          </div>
        ) : (
          <div className="flex gap-2">
            <LivoIcon name={"cash-banknote"} size={24} color={"#ACBBCB"} />
            <p className="text-gray-700">{t("offer_empty_data")}</p>
          </div>
        )}
      </div>

      {/* Requirements */}
      <div className="mb-xLarge">
        <h2 className="subtitle-regular mb-small font-bold">
          {t("offer_requirements")}
        </h2>
        {offer.requirements && offer.requirements.length > 0 ? (
          offer.requirements.map((req) => (
            <div
              key={`skills-${req.skill.value}-${req.experience.value}`}
              className="mb-1 flex items-center text-gray-700"
            >
              <LivoIcon name={"stethoscope"} size={24} color={"#ACBBCB"} />
              <div className="ml-small">
                <Chip
                  label={req?.skill?.displayText}
                  className="text-Text-Default"
                />
              </div>
              <span className="info-caption ml-small text-Text-Subtle">
                {req?.experience?.displayText}
              </span>
            </div>
          ))
        ) : (
          <div className="mb-1 flex gap-2 items-center text-gray-700">
            <LivoIcon name={"stethoscope"} size={24} color={"#ACBBCB"} />
            {offer.additionalRequirements ? (
              <p>{offer.additionalRequirements}</p>
            ) : (
              <p>{t("offer_empty_data")}</p>
            )}
          </div>
        )}
      </div>

      {/* Description */}
      <div className="mb-4">
        <h2 className="subtitle-regular mb-small font-bold">
          {t("offer_description")}
        </h2>
        <div className="flex">
          <div className="shrink-0 flex">
            <LivoIcon name={"blockquote"} size={24} color={"#ACBBCB"} />
            <span className="body-regular ml-small whitespace-pre-line">
              {offer.details ? (
                offer.details
              ) : (
                <p className="text-gray-700">
                  {t("offer_empty_aditional_description")}
                </p>
              )}
            </span>
          </div>
        </div>
      </div>

      {/* Offer ID */}
      {isDetailView && (
        <div>
          <h2 className="subtitle-regular mb-small font-bold">
            {t("offer_id")}
          </h2>
          <div className="flex">
            <div className="shrink-0">
              <LivoIcon name={"hash"} size={24} color={"#ACBBCB"} />
            </div>
            <span className="body-regular ml-small">{offer.externalId}</span>
            <CopyButton
              text={offer.externalId}
              successMessage={t("shift-list:identifier_copied")}
              style={{
                marginLeft: "8px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferInfoCard;
