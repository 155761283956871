import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import OfferSideBar from "@/pages/OfferDetail/components/OfferSideBar";
import OfferCreateForm from "@/pages/OfferDetail/OfferCreationPage/OfferCreationForm";

import { useAction } from "@/hooks/use-params-action";

import clsx from "clsx";

const OfferCreation = () => {
  const { t } = useTranslation("offers");
  const navigate = useNavigate();
  const { active: isOpenPage } = useAction("create-offer");
  const [isVisible, setIsVisible] = useState(isOpenPage);

  const goBack = () => {
    setIsVisible(false);
    setTimeout(() => {
      navigate("/ofertas-publicadas");
    }, 300);
  };

  useEffect(() => {
    isOpenPage && setIsVisible(true);
  }, [isOpenPage]);

  if (!isOpenPage) {
    return null;
  }

  return (
    <div
      className={clsx(
        "absolute inset-0 flex overflow-hidden bg-BG-Default transition-transform duration-300",
        isVisible ? "translate-x-0" : "translate-x-full"
      )}
    >
      {/* Side bar */}

      <OfferSideBar goBack={goBack} backLabel={t("create_offer_button")} />

      <div className="flex w-full flex-1 overflow-auto overflow-x-hidden">
        <OfferCreateForm />
      </div>
    </div>
  );
};

export default OfferCreation;
