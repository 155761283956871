import { useTranslation } from "react-i18next";

import { ActionButton } from "@/components/common/ActionButton";
import LivoIcon from "@/components/common/LivoLogo";

import { Filter } from "@/types/common/shiftFilters";

interface FiltersButtonProps {
  filters: Filter[];
  numberOfAppliedFilters: number;
  onClick: () => void;
}

export default function FiltersButton({
  onClick,
  filters,
  numberOfAppliedFilters,
}: FiltersButtonProps) {
  const { t } = useTranslation("shift-claim-list");
  return (
    <ActionButton onClick={onClick} isLoading={false} color={"#F4F5F7"}>
      <LivoIcon name="filter" size={16} color="#149EF2" />
      <p className="action-regular ml-tiny text-Primary-500">
        {numberOfAppliedFilters > 0 ? t("filters_label") : t("filter_label")}
      </p>
      {numberOfAppliedFilters > 0 && (
        <div className="subtitle-sm ml-tiny flex size-large items-center justify-center rounded-full bg-Secondary-700">
          <p className="">{numberOfAppliedFilters}</p>
        </div>
      )}
    </ActionButton>
  );
}
