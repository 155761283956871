import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import FiltersButton from "@/components/common/FiltersButton";
import { TagComponent } from "@/components/common/TagComponent";

import { RootState } from "@/store/types";
import { FilterConfiguration } from "@/types/shifts";

interface FiltersRowProps {
  appliedFilter: string;
  setFilter: (filters: string) => void;
  onFiltersButtonClick: () => void;
}
export const FILTER_CONFIGURATIONS: {
  id: string;
  label: string;
  configuration: FilterConfiguration;
}[] = [
  {
    id: "all",
    label: "filter_all",
    configuration: {},
  },
  {
    id: "pendingClaims",
    label: "filter_requests",
    configuration: {
      withPendingClaims: true,
    },
  },
  {
    id: "pendingToFill",
    label: "filter_pending",
    configuration: {
      isFilled: false,
    },
  },
  {
    id: "filled",
    label: "filter_covered",
    configuration: {
      isFilled: true,
    },
  },
];
export const FiltersRow: React.FC<FiltersRowProps> = ({
  appliedFilter,
  setFilter,
  onFiltersButtonClick,
}) => {
  const { t } = useTranslation("shift-list");
  const filters = useSelector((state: RootState) => state.filterShifts.filters);
  const numberOfAppliedFilters = filters.filter(
    (f) => f.appliedOptions.length > 0
  ).length;

  return (
    <div className="flex w-full flex-wrap items-center gap-2 pb-large">
      {FILTER_CONFIGURATIONS.map((filter) => (
        <TagComponent
          key={filter.id}
          label={t(filter.label as never)}
          onClick={() => setFilter(filter.id)}
          isSelected={appliedFilter.includes(filter.id)}
        />
      ))}
      {filters && filters.length > 0 && (
        <div>
          <FiltersButton
            onClick={onFiltersButtonClick}
            filters={filters}
            numberOfAppliedFilters={numberOfAppliedFilters}
          />
        </div>
      )}
    </div>
  );
};
