import { APIService, APIServiceName } from "@/services/api.service";

import { handleApiError } from "./api";

export type CitiesResponse = {
  code: string;
  name: string;
}[];

export function getListSupportedCities() {
  const url = "/facility/portal/common/cities";
  const api = APIService.getInstance(APIServiceName.PUBLIC);

  return api.get<CitiesResponse>(url).catch(handleApiError);
}
