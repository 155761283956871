import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ActionButton } from "@/components/common/ActionButton";

export const EmptyDetailsComponent = () => {
  const { t } = useTranslation("calendar");
  const navigate = useNavigate();

  return (
    <div className="flex size-full flex-col items-center bg-white p-24 text-center">
      <p className="mb-small text-[72px]">🙌</p>
      <p className="heading-md mb-small text-Text-Default">
        {t("activity_details_empty_state_title")}
      </p>
      <p className="body-regular mb-medium text-Text-Default">
        {t("activity_details_empty_state_subtitle")}
      </p>
      <div className="flex flex-col items-center">
        <ActionButton
          isDisabled={false}
          isLoading={false}
          onClick={() => {
            navigate("/turnos-publicados");
          }}
          style={{
            padding: "12px",
          }}
        >
          <div className="flex items-center text-center">
            <p className="action-regular">{t("activity_details_button")}</p>
          </div>
        </ActionButton>
      </div>
    </div>
  );
};
