import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ActionButton } from "@/components/common/ActionButton";
import { FilterComponent } from "@/components/common/FilterComponent";
import { ModalContainer } from "@/components/common/ModalContainer";
import { HeaderComponent } from "@/components/publishShift/HeaderComponent";

import {
  applySelectedOptions,
  clearSelectedOptionsInFilter,
  resetSelectedOptions,
  selectOptionInFilter,
  unselectOptionInFilter,
} from "@/store/actions/filtersActions";
import { RootState } from "@/store/types";

interface FilterShiftsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function FilterShiftsModal({
  isOpen,
  onClose,
}: FilterShiftsModalProps) {
  const { t } = useTranslation("shift-claim-list");
  const filters = useSelector((state: RootState) => state.filterShifts.filters);
  const dispatch = useDispatch();

  return (
    <ModalContainer
      isOpen={isOpen}
      style={{
        width: "45%",
        maxWidth: "530px",
        height: "43%",
        maxHeight: "500px",
      }}
    >
      <div className="mx-auto flex h-[500px] w-[530px] flex-col justify-between overflow-y-auto rounded-[16px] rounded-[8px] bg-white">
        <div>
          <HeaderComponent
            title={t("filters_label")}
            onClose={() => {
              dispatch(resetSelectedOptions());
              onClose();
            }}
          />
          <div className="m-medium flex flex-col">
            {filters.map((filter, index) => (
              <FilterComponent
                key={index}
                filter={filter}
                selectOptionInFilter={(filter, option) =>
                  dispatch(selectOptionInFilter(filter, option))
                }
                unselectOptionInFilter={(filter, option) =>
                  dispatch(unselectOptionInFilter(filter, option))
                }
                clearSelectedOptionsInFilter={(filter) =>
                  dispatch(clearSelectedOptionsInFilter(filter))
                }
              />
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end border-t border-Divider-Default p-large">
          <div className="bg-Primary-Default flex text-white">
            <ActionButton
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                dispatch(applySelectedOptions());
                onClose();
              }}
            >
              <p className="action-regular w-[175px] py-small text-center">
                {t("filter_label")}
              </p>
            </ActionButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
