import { accountReducer } from "./reducers/account";
import activityShiftListReducer from "./reducers/activityShiftList";
import { appConfigurationReducer } from "./reducers/appConfiguration";
import calendarSummaryReducer from "./reducers/calendarSummary";
import claimData from "./reducers/claimsReducer";
import pendingRequestsReducer from "./reducers/pendingProfessionals";
import { professionalFiltersReducer } from "./reducers/professionalFilterAction";
import publishShiftListReducer from "./reducers/publishShiftList";
import { shiftFiltersReducer } from "./reducers/shiftFilters";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  calendarSummary: calendarSummaryReducer,
  publishShiftList: publishShiftListReducer,
  activityShiftList: activityShiftListReducer,
  account: accountReducer,
  appConfiguration: appConfigurationReducer,
  filterShifts: shiftFiltersReducer,
  pendingRequests: pendingRequestsReducer,
  claimData,
  professionalFilters: professionalFiltersReducer,
});

export default rootReducer;
