import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { ClaimRequest, ClaimStatusEnunm } from "@/types/claims";
import { ShiftTimeStatusEnum } from "@/types/shifts";

import { CancelInternalProfessionalClaimModal } from "./CancelInternalProfessionalClaimModal";
import { CompensationOptionComponent } from "./CompensationOption";
import { InternalProfessionalAttributes } from "./InternalProfileAttributes";
import { ManageClaimContainer } from "./ManageClaimContainer";
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { SlotReasonDetails } from "./SlotReasonDetails";

interface InternalProfessionalClaimDetailsProps {
  claim: ClaimRequest;
  goBack: () => void;
  onAccept: () => void;
  onReject: () => void;
  acceptCancellationRequest: (claimId: number) => void;
  rejectCancellationRequest: (claimID: number) => void;
  onUpdateSlotReason: () => void;
  shiftId: number;
  onDelete: (reason: string) => void;
  shiftStatus: ShiftTimeStatusEnum;
}
export const InternalProfessionalClaimDetails: React.FC<
  InternalProfessionalClaimDetailsProps
> = ({
  claim,
  goBack,
  onAccept,
  onReject,
  acceptCancellationRequest,
  rejectCancellationRequest,
  onUpdateSlotReason,
  shiftId,
  onDelete,
  shiftStatus,
}) => {
  const { t } = useTranslation("shift-claim-details");
  const [menuOpen, setMenuOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setMenuOpen(false);
        setCancelModalOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuOpen]);

  const handleConfirmDelete = (reason: string) => {
    onDelete(reason);
  };

  return (
    <div className="flex w-full flex-col">
      {" "}
      <div className="flex w-full items-center justify-between px-large">
        <button onClick={goBack}>
          <LivoIcon name="arrow-left" size={24} color="#707A91" />
        </button>
        {shiftStatus === ShiftTimeStatusEnum.UPCOMING && (
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={(e) => {
                e.preventDefault();
                setMenuOpen(!menuOpen);
              }}
            >
              <LivoIcon name="dots" size={24} color="#707A91" />
            </button>

            {menuOpen && (
              <div
                ref={modalRef}
                className="absolute right-full top-0 z-10 mb-[14px] mr-tiny w-[255px] space-y-[14px] rounded-[8px] bg-white p-medium shadow-custom"
              >
                <button
                  className="pv-tiny flex w-full items-center justify-between px-small"
                  onClick={() => {
                    setCancelModalOpen(true);
                  }}
                >
                  <p className="body-regular mr-small text-Negative-500">
                    {"Revocar Puesto"}
                  </p>
                  <LivoIcon name="circle-minus" size={24} color="#FA3D3B" />
                </button>

                <CancelInternalProfessionalClaimModal
                  isOpen={cancelModalOpen}
                  onClose={() => setCancelModalOpen(false)}
                  onConfirm={handleConfirmDelete}
                  claim={claim}
                  shiftId={shiftId}
                  goBack={goBack}
                  onDelete={onDelete}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="flex size-full flex-col justify-between pb-large">
        <div className="no-scrollbar flex w-full flex-col space-y-large divide-y divide-Divider-Default overflow-y-scroll">
          <div className="flex w-full flex-col">
            <ProfessionalCardHeader
              professionalProfile={claim.professionalProfile}
              modality={claim.modality}
            />
            {claim.professionalProfile.internal && (
              <InternalProfessionalAttributes
                attributes={[
                  ...claim.professionalProfile.internal.dataFields.map(
                    (datafield) => ({
                      label: datafield.label,
                      value: datafield.displayText,
                    })
                  ),
                ]}
              />
            )}
          </div>
          {claim.compensationOption && (
            <CompensationOptionComponent
              compensationOption={claim.compensationOption}
            />
          )}
          {claim.status !== ClaimStatusEnunm.PENDING_APPROVAL &&
          claim.slotReasonOptions &&
          claim.slotReasonOptions.length > 0 ? (
            <SlotReasonDetails
              onUpdateSlotReason={onUpdateSlotReason}
              slotReason={claim.slotReason}
              slotReasonOptions={claim.slotReasonOptions}
              claimId={claim.id}
              shiftId={shiftId}
              slotReasonCommentDisplayed={claim.slotReasonCommentDisplayed}
            />
          ) : null}
        </div>
        <div className="flex w-full flex-col">
          {claim.status === ClaimStatusEnunm.PENDING_APPROVAL ? (
            <div className="flex w-full flex-col px-medium">
              <ManageClaimContainer
                onAccept={onAccept}
                onReject={onReject}
              ></ManageClaimContainer>
            </div>
          ) : claim.cancellationRequest ? (
            <div className="mb-large flex w-full flex-col px-medium">
              <ManageClaimContainer
                onAccept={() => acceptCancellationRequest(claim.id)}
                onReject={() => rejectCancellationRequest(claim.id)}
              >
                <div className="flex w-full flex-col space-y-tiny">
                  <p className="body-regular text-Text-Subtle">
                    {t("cancellation_request_reason")}
                  </p>
                  <p className="body-regular text-Text-Default">
                    {claim.cancellationRequest.reason}
                  </p>
                </div>
              </ManageClaimContainer>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
