import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { ModalContainer } from "@/components/common/ModalContainer";

import { Logger } from "@/services/logger.service";
import {
  fetchPublishShiftConfiguration,
  ShiftPublicationRequest,
} from "@/services/publish-shift";
import { RootState } from "@/store/types";
import { ShiftModalityEnum } from "@/types";
import {
  PublishShiftConfigurationDTO,
  ShiftTimeConfigDTO,
} from "@/types/publish-shift";
import { Category, ShiftTimeInDayEnum } from "@/types/shifts";
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "@/utils";
import { day, setTime } from "@/utils/datetime";

import { BaseShiftForm, ShiftConfiguration } from "./BaseShiftForm";
import { HeaderComponent } from "./HeaderComponent";
import { SelectCategory } from "./SelectCategory";

interface PublishEditModalProps {
  onSubmit: (shiftPublishRequest: ShiftPublicationRequest) => Promise<void>;
  shiftDetails: ShiftConfiguration | null;
  onClose: () => void;
  isOpen: boolean;
  isEditing?: boolean;
  selectedDate?: string;
  shiftTimeInDay?: ShiftTimeInDayEnum;
}

const PublishEditModal: React.FC<PublishEditModalProps> = ({
  onSubmit,
  shiftDetails,
  onClose,
  isOpen,
  isEditing,
  selectedDate,
  shiftTimeInDay,
}) => {
  const { t } = useTranslation(["publish-shift", "edit-shift"]);
  const [isChoosingCategory, setIsChoosingCategory] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const facilityProfile = useSelector(
    (state: RootState) => state.account.accountInfo
  );
  const canChooseCategory =
    !isEditing &&
    facilityProfile?.facility.categories &&
    facilityProfile?.facility.categories.length > 1;
  const [publishShiftConfiguration, setPublishShiftConfiguration] =
    useState<PublishShiftConfigurationDTO | null>(null);
  const [loading, setLoading] = useState(false);

  const getTimeForConfig = (
    config: ShiftTimeConfigDTO,
    _shiftTimeInDay: ShiftTimeInDayEnum
  ) => {
    const shiftTimeInDayHashKey =
      SHIFT_TIME_IN_DAY_DEFINITIONS[_shiftTimeInDay].hashKey;
    const shiftTimeInDayConfig =
      config[shiftTimeInDayHashKey as keyof typeof config];
    return [
      setTime(selectedDate, {
        hour: shiftTimeInDayConfig.startTime.hour,
        minute: shiftTimeInDayConfig.startTime.minute,
      }).toISOString(),
      setTime(selectedDate, {
        hour: shiftTimeInDayConfig.endTime.hour,
        minute: shiftTimeInDayConfig.endTime.minute,
      }).toISOString(),
    ];
  };

  const [initialStartTime, initialEndTime] =
    publishShiftConfiguration && shiftTimeInDay
      ? getTimeForConfig(
          publishShiftConfiguration.shiftTimeConfig,
          shiftTimeInDay
        )
      : [day(selectedDate).toISOString(), day(selectedDate).toISOString()];
  const initialCompensationOptions =
    publishShiftConfiguration?.compensationOptions?.options
      .filter((option) => option.enabledByDefault)
      .map((option) => option.value) || [];

  const emptyShiftConfig = {
    startTime: initialStartTime,
    finishTime: initialEndTime,
    shiftTimeInDay: shiftTimeInDay || ShiftTimeInDayEnum.MORNING,
    totalPay: "",
    capacity: 1,
    visibility: [ShiftModalityEnum.EXTERNAL],
    specialization: null,
    unit: "",
    details: "",
    externalVisible: true,
    internalVisible: false,
    recurrentDates: [day(selectedDate).format("YYYY-MM-DD")],
    category: selectedCategory || null,
    unitVisible: true,
    compensationOptions: initialCompensationOptions,
  };

  const initialShiftConfig = shiftDetails || emptyShiftConfig;

  const loadConfig = () => {
    setLoading(true);
    fetchPublishShiftConfiguration(selectedCategory?.code)
      .then((response) => {
        setPublishShiftConfiguration(response);
        setLoading(false);
      })
      .catch((error) => {
        Logger.error("fetchPublishShiftConfiguration", error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    setIsChoosingCategory(true);
    setSelectedCategory(null);
    onClose();
  };

  useEffect(() => {
    if (initialShiftConfig?.category) {
      setSelectedCategory(initialShiftConfig.category);
      setIsChoosingCategory(false);
    } else {
      setIsChoosingCategory(true);
    }
  }, [initialShiftConfig?.category]);

  useEffect(() => {
    if ((!isChoosingCategory || !canChooseCategory) && isOpen) {
      loadConfig();
    }
  }, [isChoosingCategory, isOpen]);

  return (
    <ModalContainer
      isOpen={isOpen}
      style={{ width: "60%", maxWidth: "700px" }}
      onClose={handleClose}
    >
      <div className="mx-auto flex flex-col overflow-y-auto rounded-[16px] rounded-[8px] bg-white">
        {loading ? (
          <div>
            <HeaderComponent
              title={
                isEditing
                  ? t("edit-shift:edit_shift_title")
                  : t("publish_shift_title")
              }
              onClose={handleClose}
            />
            <div className=" flex h-[600px] w-[700px] items-center justify-center">
              <CircularProgress />
            </div>
          </div>
        ) : canChooseCategory && isChoosingCategory ? (
          <div>
            <HeaderComponent
              title={t("edit-shift:select_category_title")}
              onClose={handleClose}
            />
            <div className="flex w-[700px]  justify-start">
              <SelectCategory
                categories={facilityProfile?.facility.categories || []}
                onSelectingCategory={(category) => {
                  setSelectedCategory(category);
                  setIsChoosingCategory(false);
                }}
              />
            </div>
          </div>
        ) : publishShiftConfiguration ? (
          <BaseShiftForm
            initialConfig={{
              ...initialShiftConfig,
              category: selectedCategory || initialShiftConfig.category,
            }}
            publishShiftConfiguration={publishShiftConfiguration}
            onSubmit={onSubmit}
            onClose={handleClose}
            isEditing={isEditing}
            title={
              isEditing
                ? t("edit-shift:edit_shift_title")
                : t("publish_shift_title")
            }
            goBack={
              canChooseCategory ? () => setIsChoosingCategory(true) : undefined
            }
          />
        ) : null}
      </div>
    </ModalContainer>
  );
};

export default React.memo(PublishEditModal);
