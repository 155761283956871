import LivoIcon from "./LivoLogo";

interface MultiSelectItemProps {
  checked: boolean;
  onClick: () => void;
  option: string;
  disclaimer?: string;
}

export const MultiSelectItem: React.FC<MultiSelectItemProps> = ({
  checked,
  onClick,
  option,
  disclaimer,
}) => {
  return (
    <button
      className="m-x-tiny flex w-full items-center justify-between"
      onClick={onClick}
    >
      <div className="flex flex-col justify-start text-left">
        <p
          className={
            checked
              ? "subtitle-regular text-Action-Primary"
              : "body-regular text-Text-Subtle"
          }
        >
          {option}
        </p>
        <p className="body-sm text-Text-Subtle">{disclaimer}</p>
      </div>
      {}
      <LivoIcon
        name={checked ? "square-check-filled" : "square"}
        size={24}
        color={checked ? "#149EF2" : "#848DA9"}
      />
    </button>
  );
};
