import { ApiApplicationError } from "@/services/api";
import {
  closeOffer,
  createDraftOffer,
  editOffer,
  EditOfferPayload,
  getFacilityConfig,
  MutateOfferPayload,
  publishOffer,
} from "@/services/facility-offer";
import store from "@/store";
import {
  showToastAction,
  toggleInternetConnection,
} from "@/store/actions/appConfigurationActions";
import { FacilityConfig, Perk, SkillGroup } from "@/types/offers";

export const OFFER_CONFIG_QUERY_KEY = "offer-config";

export const PUBLISH_OFFER_KEY = "publish-offer";

export function mapSkills(skills: SkillGroup[]) {
  //flatten skills array to a single array of objects
  const skillsList = skills
    .map((skillGroup, groupIndex) => {
      const isLastGroup = groupIndex === skills.length - 1;
      return skillGroup.group.map((skill, skillIndex) => ({
        label: skill.displayText,
        value: skill.value,
        separator: !isLastGroup && skillIndex === skillGroup.group.length - 1,
      }));
    })
    .flat();

  return skillsList;
}

export const PerkIcon = new Map([
  ["uniform", "uniform"],
  ["parking", "parking"],
  ["meal", "meal"],
  ["locker", "locker"],
]);

export function mapPerks(perks: Perk[]) {
  return perks.map((perk) => ({
    label: perk.displayText,
    value: perk.value,
    icon: perk.icon ?? PerkIcon.get(perk.value),
  }));
}

export function mapOfferConfigToFormValues(config: FacilityConfig) {
  return {
    categories: config.categories.map((category) => ({
      label: category.displayText,
      value: category.value,
    })),
    skills: mapSkills(config.skills),
    contractTypes: config.contractTypes.map((contractType) => ({
      label: contractType.displayText,
      value: contractType.value,
    })),
    startDate: config.startDate.map((startDate) => ({
      label: startDate.displayText,
      value: startDate.value,
    })),
    durationTypes: config.durationTypes.map((durationType) => ({
      label: durationType.displayText,
      value: durationType.value,
    })),
    contractSchedules: config.contractSchedules.map((contractSchedule) => ({
      label: contractSchedule.displayText,
      value: contractSchedule.value,
    })),
    salaryPeriods: config.salaryPeriods.map((salaryPeriod) => ({
      label: salaryPeriod.displayText,
      value: salaryPeriod.value,
    })),
    perks: mapPerks(config.perks),
    skillExperience: config.skillExperience.map((skillExperience) => ({
      label: skillExperience.displayText,
      value: skillExperience.value,
    })),
  };
}

export type FormConfig = ReturnType<typeof mapOfferConfigToFormValues>;

export const queryFnOfferConfig = async ({
  queryKey,
}: {
  queryKey: unknown[];
}) => {
  try {
    const response = await getFacilityConfig();
    return mapOfferConfigToFormValues(response);
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
      } else {
        store.dispatch(
          showToastAction({
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  }
};

export const mutateCreateDraftOffer = async (payload: MutateOfferPayload) => {
  try {
    const response = await createDraftOffer(payload);
    return response;
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
      } else {
        store.dispatch(
          showToastAction({
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  }
};

export const mutatePublishOffer = async (offerId: number) => {
  try {
    const response = await publishOffer(offerId);
    return response;
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
        return;
      }

      if (error.errorCode?.startsWith("4017")) {
        throw error;
      }

      store.dispatch(
        showToastAction({
          message: error.message,
          severity: "error",
        })
      );
    }
  }
};

export const mutateCloseOffer = async (offerId: number) => {
  try {
    await closeOffer(offerId);
    return { ok: true, offerId };
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
      } else {
        store.dispatch(
          showToastAction({
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  }
};

export const mutateEditOffer = async ({
  offerId,
  payload,
}: {
  offerId: number;
  payload: EditOfferPayload;
}) => {
  try {
    await editOffer(offerId, payload);
    return { ok: true };
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
      } else {
        store.dispatch(
          showToastAction({
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  }
};
