import React from "react";

import OfferActionsPopper from "@/pages/OfferDetail/OfferDetailPage/components/OfferActionPopper";
import {
  mapDurationToText,
  NOT_DELETABLE_STATUSES,
  NOT_EDITABLE_STATUSES,
} from "@/pages/OfferDetail/utils";
import { CategoryTag } from "@/components/common/CategoryTag";
import OfferStatusDEPRECATED from "@/components/Offer/OfferStatus.deprecated";

import { OfferDetail } from "@/types/offers";

function OfferInfoHeader({
  offer,
  isDetailView,
}: {
  offer: OfferDetail;
  isDetailView?: boolean;
}) {
  const { duration } = offer ?? {};

  const durationText = mapDurationToText(duration?.type, duration?.date);
  return (
    <>
      {/* Header */}
      <div className="mb-6 flex items-center justify-between">
        {!!offer.status && <OfferStatusDEPRECATED status={offer.status} />}
        {isDetailView && (
          <OfferActionsPopper
            offer={offer}
            disabledDelete={NOT_DELETABLE_STATUSES.includes(offer.status)}
            disabledEdit={NOT_EDITABLE_STATUSES.includes(offer.status)}
          />
        )}
      </div>
      <div className="mb-xLarge flex items-center">
        <CategoryTag text={offer.category} />
        <h1 className="heading-md ml-small font-bold">
          {offer.skill?.displayText}
          {offer.skill?.value && durationText && " · "}
          {durationText}
        </h1>
      </div>
    </>
  );
}

export default OfferInfoHeader;
