import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Stack, Typography } from "@mui/material";

import { OfferFormData } from "@/pages/OfferDetail/offer-form.config";
import FormAutocomplete from "@/components/form/FormAutocomplete";
import FormSelect from "@/components/form/FormSelect";

import { FormConfig } from "@/queries/offer-mutation";

function OfferCategoryForm({
  form,
  config,
}: {
  form: UseFormReturn<OfferFormData>;
  config?: FormConfig;
}) {
  const { t } = useTranslation("offers");
  const { control } = form;

  return (
    <Stack spacing={2}>
      {/* CATEGORY & SKILL */}
      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={2}
        className="mb-2"
      >
        <FormAutocomplete
          required
          name="category"
          control={control}
          className="w-full sm:w-52"
          label={t("category")}
          options={config?.categories ?? []}
          clearIcon={false}
        />

        <FormSelect
          required
          name="skill"
          control={control}
          className="w-full"
          label={t("skill")}
          options={config?.skills ?? []}
        />
      </Stack>
      <Typography variant="body2" color="text.secondary" className="!mt-1 !mb-3">
        {t("note_for_category_and_skill")}
      </Typography>
    </Stack>
  );
}

export default OfferCategoryForm;
