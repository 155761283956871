import { ProfessionalRatingStar } from "@/components/common/ProfessionalRatingStar";
import { ProfilePicture } from "@/components/shiftDetails/ProfilePicture";

import { ShiftModalityEnum } from "@/types";
import { OfferClaim } from "@/types/offers";

import { OfferClaimStatusTag } from "./OfferStatusTag";
import { SkillTagsCompact } from "./SkillTagsCompact";

interface OfferClaimCardProps {
  claim: OfferClaim;
  isSelected: boolean;
  selectClaim: (claimId: OfferClaim) => void;
}

export const OfferClaimCard: React.FC<OfferClaimCardProps> = ({
  claim,
  isSelected,
  selectClaim,
}) => {
  return (
    <div
      onClick={() => selectClaim(claim)}
      className={`flex flex-row  items-center p-small ${isSelected ? "bg-Action-Secondary text-Text-Inverse" : "bg-white"} relative cursor-pointer rounded-[16px]`}
      style={{
        minWidth: "200px",
      }}
    >
      <div className="grid w-full flex-1 grid-cols-10 items-center justify-between space-x-small">
        <div className="col-span-2 flex text-left">
          <div className="flex flex-row items-center space-x-small">
            <ProfilePicture
              profilePictureUrl={claim.professionalProfile.profilePictureUrl}
              size={48}
              modality={ShiftModalityEnum.EXTERNAL}
            />

            <p className="subtitle-regular">
              {claim.professionalProfile.fullName}
            </p>
          </div>
        </div>
        <div className="col-span-2 ml-5 flex flex-wrap items-end gap-1">
          <SkillTagsCompact
            skills={claim.professionalProfile.skills}
            flexWrap={true}
          />
        </div>

        <div className="w-15 col-span-3 flex text-left">
          <div className="text-s line-clamp-2 h-8 overflow-hidden text-Text-Default">
            {claim.professionalProfile.cvSummary}
          </div>
        </div>

        {claim.professionalProfile.professionalReview && (
          <div className="col-span-2 flex w-full flex-row items-center justify-start space-x-tiny">
            <div className="flex w-full flex-1 items-center justify-center">
              <ProfessionalRatingStar
                professionalReview={
                  claim.professionalProfile.professionalReview
                }
                textClassName={isSelected ? "text-Text-Inverse" : undefined}
              />
            </div>
          </div>
        )}

        <div className="col-span-1 flex w-full flex-row items-center justify-start space-x-tiny">
          <div className="flex w-full flex-1 items-center justify-center">
            <OfferClaimStatusTag status={claim.status} />
          </div>
        </div>
      </div>
      <div className="w-[20px]">
        {claim.newClaim && (
          <div className="ml-tiny flex items-center justify-center">
            <div className="size-2 rounded-full bg-blue-500"></div>
          </div>
        )}
      </div>
    </div>
  );
};
