interface PublishShiftInputProps {
  setValue: (value: string) => void;
  selectedValue: string;
  placeHolder?: string;
  errorMessage?: string;
  label?: string;
}

export const PublishShiftMultipleLineInput: React.FC<
  PublishShiftInputProps
> = ({ setValue, selectedValue, placeHolder, errorMessage, label }) => {
  const handleInputValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className="relative flex w-full flex-col">
      {label && selectedValue && selectedValue !== "" ? (
        <div className="absolute -top-small left-small bg-white px-tiny">
          <p className="info-overline text-Text-Subtle">{label}</p>
        </div>
      ) : null}
      <div
        className={`ring-solid shrink-1 box-border flex w-full flex-row items-start justify-start space-x-small rounded-[8px] bg-white p-small ring-1
                        ${errorMessage ? "ring-2 ring-red-500" : "ring-Divider-Subtle"} focus-within:ring-2 focus-within:ring-Action-Secondary`}
      >
        <textarea
          value={selectedValue}
          onChange={handleInputValue}
          className="body-regular placeholder:body-regular h-[113px] max-h-[300px] min-h-[113px] w-full placeholder:text-Text-Subtle focus:outline-none"
          placeholder={placeHolder}
        />
      </div>
      {errorMessage ? (
        <p className="info-caption mt-tiny text-Negative-500">{errorMessage}</p>
      ) : null}
    </div>
  );
};
