import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Chip,
  CircularProgress,
  Container,
  Link,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import FirstShifterTag from "@/components/common/FirstShifterTag";

import { formatDateToYYYYMMDD } from "@/utils";
import {
  FacilityReviewStatusEnum,
  getReviewStatusLabelProps,
} from "@/utils/constants";

import { fetchLegalReviewList } from "../services/api";
import { ShiftClaimDetails } from "../types";

import "../styles/ShiftClaimListScreen.css";

export const PAGE_SIZE = 10;

export const ShiftClaimListScreen: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("shift-claim-list");
  const [shiftClaimsData, setShiftClaimsData] = useState<ShiftClaimDetails[]>(
    []
  );
  const [displayFacilityName, setDisplayFacilityName] =
    useState<Boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("DEFAULT"); // Default tab
  const [page, setPage] = useState(0);
  const [totalShifts, setTotalShifts] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
    setPage(0);
  };

  const today = new Date();
  const dayBeforeToday = new Date(today);
  dayBeforeToday.setDate(dayBeforeToday.getDate() - 1);

  const filters = {
    PAST: {
      page: page + 1,
      toDate: formatDateToYYYYMMDD(dayBeforeToday),
      sortOrder: "DESC",
      size: PAGE_SIZE,
    },
    CONFIRMED: {
      page: page + 1,
      facilityReviewStatuses: [FacilityReviewStatusEnum.CONFIRMED],
      fromDate: formatDateToYYYYMMDD(today),
      sortOrder: "ASC",
      size: PAGE_SIZE,
    },
    DEFAULT: {
      page: page + 1,
      facilityReviewStatuses: [
        FacilityReviewStatusEnum.UNAVAILABLE,
        FacilityReviewStatusEnum.IN_REVIEW,
      ],
      fromDate: formatDateToYYYYMMDD(today),
      sortOrder: "ASC",
      size: PAGE_SIZE,
    },
  };

  const fetchShiftData = async () => {
    const filter = filters[selectedTab as keyof typeof filters];
    setLoading(true);
    fetchLegalReviewList(filter)
      .then((shiftClaimResponse) => {
        setShiftClaimsData(shiftClaimResponse.rows);
        setTotalShifts(shiftClaimResponse.total);
        setDisplayFacilityName(
          shiftClaimResponse.rows.some(
            (shiftClaim) => shiftClaim.facilityName !== null
          )
        );
        setLoading(false);
      })
      .catch((error) => {
        setShiftClaimsData([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchShiftData();
  }, [selectedTab, page]);

  const reviewStatusLabelProps = useMemo(() => getReviewStatusLabelProps(), []);

  const getStatusLabel = (facilityReviewStatus: string) => {
    const statusLabelProp =
      reviewStatusLabelProps[
        facilityReviewStatus as keyof typeof reviewStatusLabelProps
      ];
    return (
      <Chip label={statusLabelProp.label} color={statusLabelProp.colorTheme} />
    );
  };

  const content = loading ? (
    <Container
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        minHeight: "30em",
        minWidth: "100%",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Container>
  ) : (
    <TableContainer component={Paper} className="table">
      <Table>
        <TableHead className="table-header">
          <TableRow>
            <TableCell>{t("shift_day_table_title")}</TableCell>
            {displayFacilityName && (
              <TableCell>{t("facility_name_title")}</TableCell>
            )}
            <TableCell>{t("professional_table_title")}</TableCell>
            <TableCell>{t("id_table_title")}</TableCell>
            <TableCell>{t("specialization_table_title")}</TableCell>
            <TableCell>{t("price_table_title")}</TableCell>
            <TableCell>{t("status_table_title")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shiftClaimsData.map((claim) => (
            <TableRow
              key={claim.shiftClaimId}
              sx={{
                cursor: "pointer",
              }}
              hover
              component={Link}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/shift-claim/${claim.shiftClaimId}`);
              }}
              underline="none"
            >
              <TableCell className="link-text">
                {formatDateToYYYYMMDD(new Date(claim.shift.startTime))}
              </TableCell>
              {displayFacilityName && (
                <TableCell>{claim.facilityName}</TableCell>
              )}
              <TableCell>
                <div className="flex flex-row items-center justify-between">
                  <Typography
                    noWrap
                    variant="body2"
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "20ch",
                    }}
                  >
                    {claim.professionalInitProfile.firstName}{" "}
                    {claim.professionalInitProfile.lastName}
                  </Typography>

                  {claim.professionalInitProfile.firstShifter && (
                    <FirstShifterTag className="ml-tiny" />
                  )}
                </div>
              </TableCell>
              <TableCell>
                {claim.professionalLegalProfile?.nationalId ??
                  t("pending_label")}
              </TableCell>
              <TableCell>{claim.shift.specialization.displayText}</TableCell>
              <TableCell>{claim.shift.formattedTotalPay} </TableCell>
              <TableCell>
                {getStatusLabel(claim.facilityReviewStatus)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalShifts}
        page={page}
        rowsPerPageOptions={[]}
        onPageChange={(event, newPage) => {
          setPage(newPage);
        }}
        rowsPerPage={PAGE_SIZE}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} - ${to} de ${count}`
        }
      />
    </TableContainer>
  );

  return (
    <div className="content flex overflow-auto">
      <Container sx={{ pt: 2, pb: 2, height: "100vh" }}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            {t("header_title")}
          </Typography>
        </Box>
        <div className="table-container">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            component={Paper}
            sx={{ mb: 2 }}
            variant="fullWidth"
          >
            <Tab label={t("upcoming_shifts")} value="DEFAULT" />
            <Tab label={t("confirmed_shifts")} value="CONFIRMED" />
            <Tab label={t("past_shifts")} value="PAST" />
          </Tabs>
          {content}
        </div>
      </Container>
    </div>
  );
};
