import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "@/store/types";

export const selectAccount = (state: RootState) => state.account;

export const selectProfile = createSelector(
  selectAccount,
  (account) => account.accountInfo
);
