import LivoIcon from "@/components/common/LivoLogo";

interface HeaderComponentProps {
  title: string;
  onClose?: () => void;
  goBack?: () => void;
}

export const HeaderComponent: React.FC<HeaderComponentProps> = ({
  title,
  onClose,
  goBack,
}) => {
  return (
    <div className="flex w-full flex-row items-center justify-between border-b border-Divider-Default p-large">
      <div className="flex items-center space-x-small">
        {goBack ? (
          <div onClick={goBack}>
            <LivoIcon name="chevron-left" size={24} color="#2C3038" />
          </div>
        ) : null}
        <p className="heading-sm">{title}</p>
      </div>

      <button onClick={onClose} className="flex items-center justify-center">
        <LivoIcon name="close" size={24} color="#2C3038" />
      </button>
    </div>
  );
};
