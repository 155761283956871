interface CalendarDayItemProps {
  day: string;
  isToday: boolean;
  onPress: () => void;
  isOtherMonth: boolean;
  isSelected: boolean;
  hasAlert?: boolean;
  hasShifts: boolean;
  disabled?: boolean;
  holiday?: boolean;
}

export const CalendarDayItem: React.FC<CalendarDayItemProps> = ({
  day,
  isToday,
  onPress,
  isOtherMonth,
  isSelected,
  hasAlert,
  hasShifts,
  disabled,
  holiday,
}) => {
  const todayClass = "border-[1px] border-solid border-Divider-Default";
  const selectedClass = holiday
    ? "bg-[#B1A4E0] text-white"
    : "bg-Primary-500 text-white";
  const otherMonthClass = "text-gray-400";
  const holidayClass = "ring-solid ring-[2px] ring-[#B1A4E0]";
  return (
    <button
      className={`relative
                box-border
                flex
                size-xLarge
                items-center
                justify-center
                rounded-[4px]
                p-tiny
                ${!isSelected ? "hover:bg-Background-Secondary" : ""}
                ${isSelected ? selectedClass : holiday ? holidayClass : isToday ? todayClass : isOtherMonth ? otherMonthClass : ""}
            `}
      disabled={disabled}
      onClick={onPress}
    >
      <div>
        <p
          className={`
                    
                    body-sm
                    w-large
                    text-center
                    ${hasShifts && !isSelected ? "subtitle-regular text-Action-Primary" : ""}
                    ${disabled ? "text-Text-Light line-through" : ""}
                `}
        >
          {day}
        </p>
        {hasAlert && !isSelected ? (
          <div className="absolute right-[2px] top-tiny size-tiny rounded-full bg-Negative-500" />
        ) : null}
      </div>
    </button>
  );
};
