import { FC } from "react";
import { useSelector } from "react-redux";

import LivoIcon from "@/components/common/LivoLogo";
import ProfileModal from "@/components/common/modal/ProfileModal";
import { AppToast } from "@/components/common/toasts/AppToast";

import { useAuth } from "@/contexts/Authentication.context";
import { useModal } from "@/hooks/use-modal";
import { RootState } from "@/store/types";

import LogoImage from "@/assets/livoLogoText.svg";
import LogoMonoColor from "@/assets/logo_mono_color.svg";

const Header: FC = () => {
  const { token } = useAuth();
  const { openModal } = useModal();
  const { accountInfo } = useSelector((state: RootState) => state.account);

  if (!token) {
    return (
      <header className="absolute left-0 top-0 z-10 w-full">
        <div className="z-10 flex w-full items-center xxs:h-12 md:h-16">
          <img src={LogoMonoColor} alt="Logo" className="m-4 h-[42px]" />
        </div>
      </header>
    );
  }

  return (
    <header className="flex items-center bg-Secondary-700 px-large">
      <div className="mr-large flex py-medium">
        <img src={LogoImage} alt="Logo" className="mr-large h-[42px]" />
        {accountInfo ? (
          <div
            onClick={() => {
              const config = {
                className:
                  "!bg-Grey-050 w-[450px] max-w-[95dvw] !shadow-lg !rounded-2xl !p-0 !overflow-hidden",
              };
              const modalContent = <ProfileModal />;
              openModal(modalContent, config);
            }}
            className="ring-solid flex cursor-pointer items-center space-x-tiny self-start rounded-full p-small ring-1 ring-Divider-Default"
          >
            <LivoIcon name="user-circle" size={24} color="#FFFF" />
            <p className="subtitle-regular text-Text-Inverse">
              {accountInfo.firstName} {accountInfo.lastName}
            </p>
            <LivoIcon name="chevron-right" size={24} color="#FFFF" />
          </div>
        ) : null}
      </div>

      <div className="flex flex-1 justify-end py-small">
        <div className="max-w-1/3 w-1/3">
          <AppToast />
        </div>
      </div>
    </header>
  );
};

export default Header;
