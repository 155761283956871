import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

interface IncreaseCapacityProps {
  onClick: () => void;
}

export const IncreaseCapacity: React.FC<IncreaseCapacityProps> = ({
  onClick,
}) => {
  const { t } = useTranslation("shift-claim-details");
  return (
    <div className="flex size-full flex-row items-center space-x-small py-small">
      <button
        onClick={onClick}
        className={`flex items-center justify-center rounded-[8px] bg-Primary-100 p-medium`}
      >
        <LivoIcon name="plus" size={24} color="#149EF2" />
      </button>
      <div className="flex w-full flex-col justify-center">
        <p className="body-regular text-Text-Default">
          {t("add_capacity_label")}
        </p>
      </div>
    </div>
  );
};
