import LivoIcon from "@/components/common/LivoLogo";

interface PublishInputRowProps {
  iconName?: string;
  children: React.ReactNode;
}

export const PublishInputRow: React.FC<PublishInputRowProps> = ({
  iconName,
  children,
}) => {
  return (
    <div className="flex w-full flex-col py-medium">
      <div className="flex flex-row space-x-medium">
        <div className="flex items-center justify-center">
          {iconName ? (
            <LivoIcon name={iconName} size={24} color="#ACBBCB" />
          ) : (
            <div
              style={{
                width: 24,
                height: 24,
              }}
            ></div>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
