import { useTranslation } from "react-i18next";

import { CustomInput } from "@/components/common/CustomInput";
import { TogglerOff } from "@/components/common/TogglerOff";
import { TogglerOn } from "@/components/common/TogglerOn";

import { DropDownWithInput } from "./DropDownWithInput";
import { PublishInputRow } from "./PublishInputRow";

interface UnitInputComponentProps {
  unit: string;
  setUnit: (unit: string) => void;
  invalidUnit: string;
  setInvalidUnit: (invalidUnit: string) => void;
  units: string[] | null;
  unitVisible: boolean;
  setUnitVisible: (visible: boolean) => void;
  unitVisibleConfigurable: boolean;
}

export const UnitInputComponent: React.FC<UnitInputComponentProps> = ({
  unit,
  setUnit,
  invalidUnit,
  setInvalidUnit,
  units,
  unitVisible = false,
  setUnitVisible,
  unitVisibleConfigurable = false,
}) => {
  const { t } = useTranslation("internal-professional-page");
  return (
    <div className="flex flex-col">
      <PublishInputRow iconName="nurse">
        <div className="flex w-full flex-col">
          {units != null && units.length > 0 ? (
            <DropDownWithInput
              label={t("unit_label")}
              selectedOptionId={unit}
              setOptionId={(optionId) => {
                setUnit(optionId);
              }}
              placeHolder={t("unit_label")}
              options={units.map((unit) => ({
                id: unit,
                name: unit,
              }))}
              errorMessage={invalidUnit}
            />
          ) : (
            <CustomInput // Unidad
              label={t("unit_label")}
              selectedValue={unit}
              setValue={(newUnit) => {
                setUnit(newUnit);
                setInvalidUnit("");
              }}
              placeHolder={t("unit_label")}
              errorMessage={invalidUnit}
            />
          )}
        </div>
      </PublishInputRow>
      {unitVisibleConfigurable && (
        <div
          onClick={() => setUnitVisible(!unitVisible)}
          className="ml-10 flex cursor-pointer flex-row items-center space-x-small"
        >
          {unitVisible ? <TogglerOn /> : <TogglerOff />}
          <p className="info-overline text-Text-Subtle">
            {t("unit_visible_for_professionals_label")}
          </p>
        </div>
      )}
    </div>
  );
};
