import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";

import OfferCoreForm from "@/pages/OfferDetail/components/OfferCoreForm";
import { useDefaultValues } from "@/pages/OfferDetail/hooks/useDefaultValues";
import useFetchOfferConfig from "@/pages/OfferDetail/hooks/useFetchOffersConfig";
import {
  buildOfferPayload,
  useSaveDraftOffer,
} from "@/pages/OfferDetail/hooks/useMutationOffer";
import OfferPreviewModal, {
  OFFER_PREVIEW_MODAL_CLASSES,
} from "@/pages/OfferDetail/modals/OfferPreviewModal";
import {
  OfferFormData,
  offerFormSchema,
} from "@/pages/OfferDetail/offer-form.config";
import OfferCategoryForm from "@/pages/OfferDetail/OfferCreationPage/OfferCategoryForm";
import LoadingView from "@/components/common/LoadingView";
import { MaterialActionButton } from "@/components/common/MaterialActionButton";

import colors from "@/config/color-palette";
import useHandlePublishFailed from "@/hooks/offers/use-handle-publish-failed";
import { useInvalidateQuery } from "@/hooks/use-invalidate-query";
import { useModal } from "@/hooks/use-modal";
import { OFFER_LIST_QUERY_KEY } from "@/queries/offer-list";
import { Logger } from "@/services/logger.service";
import { SubscriptionStatus } from "@/types/offers";
import { preventEnterKeySubmit } from "@/utils/form";

import clsx from "clsx";

const OfferCreationForm = () => {
  const { t } = useTranslation("offers");
  const invalidateQuery = useInvalidateQuery();
  const onPublishFailed = useHandlePublishFailed();
  const { config, isConfigFetched } = useFetchOfferConfig();
  const { defaultFormValue } = useDefaultValues(config);

  const [submitMode, setSubmitMode] = useState<SubmitActionType | undefined>();
  const form = useForm<OfferFormData>({
    resolver: zodResolver(offerFormSchema),
    defaultValues: defaultFormValue,
  });
  const navigate = useNavigate();
  const { openModal } = useModal();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const { saveDraftOffer, isPending: isPendingSave } = useSaveDraftOffer();

  const onSubmit: SubmitHandler<OfferFormData> = async (data, event) => {
    try {
      const submitEvent = event?.nativeEvent as SubmitEvent;
      const { value: typeSubmit } = submitEvent?.submitter as HTMLButtonElement;

      setSubmitMode(typeSubmit as SubmitActionType);

      const payload = buildOfferPayload(data);
      const response = await saveDraftOffer(payload);
      const { details: offerDetails, subscription } = response ?? {};

      /**
       * if the action is save, we just navigate to the list of offers
       */
      if (typeSubmit === SUBMIT_ACTION.Save) {
        invalidateQuery(OFFER_LIST_QUERY_KEY);
        offerDetails?.id && navigate("/ofertas-publicadas");
        return;
      }

      offerDetails?.id &&
        navigate(`/ofertas-publicadas?offerId=${offerDetails.id}`, {
          state: { action: "edit" },
        });

      /**
       * invalid query because draft created
       */
      invalidateQuery(OFFER_LIST_QUERY_KEY);

      /**
       * if action is publish
       * we need to show a dialog with the status of the subscription
       */
      if (subscription?.status === SubscriptionStatus.SLOT_AVAILABLE) {
        if (!offerDetails) {
          return;
        }
        const content = (
          <OfferPreviewModal
            subscription={subscription}
            offer={offerDetails}
            onPublishSuccess={(response) => {
              /**
               * invalid query because offer published
               */
              invalidateQuery(OFFER_LIST_QUERY_KEY);
              response?.offerId && navigate("/ofertas-publicadas");
            }}
          />
        );

        return openModal(content, {
          className: OFFER_PREVIEW_MODAL_CLASSES,
        });
      }
      /**
       * if status is not slot available, we show a dialog with the status
       */
      onPublishFailed(subscription);
    } catch (error) {
      Logger.error("Error saving offer", error);
    } finally {
      setSubmitMode(undefined);
    }
  };

  if (!isConfigFetched) {
    return (
      <div className="relative flex size-full flex-1">
        <LoadingView />
      </div>
    );
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      // prevent submit on enter key press in input fields
      onKeyDown={preventEnterKeySubmit}
      className={"relative flex size-full flex-1 justify-center gap-6"}
    >
      <div
        className={clsx(
          "flex flex-col gap-3",
          "w-full max-w-3xl",
          "modern-scrollbar overflow-auto",
          "my-6 rounded-lg bg-white p-6 pb-12 shadow-md"
        )}
      >
        <OfferCategoryForm form={form} config={config} />
        <OfferCoreForm form={form} config={config} />
      </div>
      {/* Fixed buttons container */}
      <div className="flex w-fit min-w-48 flex-col gap-4 pt-6">
        <MaterialActionButton
          variant="contained"
          type="submit"
          value={SUBMIT_ACTION.Publish}
          tint={colors["Primary-500"]}
          isLoading={submitMode === "publish" && isPendingSave}
          isDisabled={isSubmitting}
        >
          {t("publish")}
        </MaterialActionButton>
        <MaterialActionButton
          variant="outlined"
          tint={colors["Primary-500"]}
          type="submit"
          value={SUBMIT_ACTION.Save}
          isLoading={submitMode === "save" && isPendingSave}
          isDisabled={isSubmitting}
        >
          {t("save_draft")}
        </MaterialActionButton>
      </div>
    </form>
  );
};

const SUBMIT_ACTION = {
  Save: "save",
  Publish: "publish",
};

type SubmitActionType = "save" | "publish";

export default OfferCreationForm;
