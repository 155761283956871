import React from "react";

import { ActionButton } from "./ActionButton";
import { ModalContainer } from "./ModalContainer";

interface ConfirmationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  subtitle?: string;
  children?: React.ReactNode;
  buttonTitle: string;
  dismissTitle: string;
  buttonIsLoading?: boolean;
  dismissIsLoading?: boolean;
  buttonColor?: string;
  onPress: () => void;
  onDismiss: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  children,
  buttonTitle,
  dismissTitle,
  buttonIsLoading = false,
  dismissIsLoading = false,
  buttonColor = "#FF5A59",
  onPress,
  onDismiss,
}) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={handleClose}
      style={{ width: "60%", maxWidth: "700px" }}
    >
      <div className="z-50 mx-auto flex w-[448px] flex-col overflow-y-auto rounded-[16px] bg-white  p-large">
        {title && <h2 className="mb-3 font-medium text-xl">{title}</h2>}
        {subtitle && <p className="mb-3 text-base">{subtitle}</p>}

        {children}

        <div className="mt-large flex flex-row items-center justify-between">
          <div className="w-[200px]">
            <ActionButton
              onClick={onDismiss}
              isLoading={dismissIsLoading}
              style={{ color: "#149EF2", backgroundColor: "#FFFFFF" }}
            >
              {dismissTitle}
            </ActionButton>
          </div>
          <div className="w-[200px]">
            <ActionButton
              onClick={onPress}
              isLoading={buttonIsLoading}
              style={{ backgroundColor: buttonColor }}
            >
              {buttonTitle}
            </ActionButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};
