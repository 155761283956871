import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";
import { TagLabel } from "@/components/common/TagLabel";
import FavoriteTag from "@/components/professionals/FavoriteTag";

import { ShiftModalityEnum } from "@/types";
import { ClaimRequest } from "@/types/claims";

import { ProfilePicture } from "./ProfilePicture";

interface ClaimRowProps {
  claim: ClaimRequest;
  onClick: () => void;
  style?: any;
}

export const ClaimRow: React.FC<ClaimRowProps> = ({
  claim,
  style,
  onClick,
}) => {
  const { t } = useTranslation(["professional-claim", "shift-claim-details"]);
  const subtitleComponent =
    claim.modality === ShiftModalityEnum.EXTERNAL || !claim.modality ? (
      claim.professionalProfile?.firstShifterForFacility ? (
        <p className="info-caption text-[#987ED4]">
          {t("first_shifter_label")}
        </p>
      ) : claim.professionalProfile?.favorite ? (
        <FavoriteTag />
      ) : claim.professionalProfile?.professionalReview.totalReviews > 0 ? (
        <div className=":hovered:bg-Background-Secondary flex w-full flex-row items-center justify-start space-x-tiny">
          <LivoIcon name="star-filled" size={12} color="#FF911C" />
          <p className="body-sm text-Text-Default">
            {claim.professionalProfile?.professionalReview.averageRating}
          </p>

          <p className="body-sm text-Text-Subtle">
            {`(${claim.professionalProfile?.professionalReview.totalReviews} ${
              claim.professionalProfile.professionalReview.totalReviews > 1
                ? "Reseñas"
                : "Reseña"
            })`}
          </p>
        </div>
      ) : (
        <p className="info-caption text-Text-Subtle">
          {t("shift-claim-details:no_reviews_label")}
        </p>
      )
    ) : claim.cancellationRequest ? (
      <p className="info-caption text-Negative-500">
        {t("shift-claim-details:cancellation_request")}
      </p>
    ) : (
      <div className="flex w-full flex-col justify-center">
        <div className="flex w-full flex-row flex-wrap items-center gap-y-tiny space-x-tiny">
          {claim.professionalProfile.tags?.map((tag, index) => (
            <TagLabel
              key={index}
              text={tag.label}
              backgroundColor={tag.styling?.backgroundColor || "#EEEFF3"}
              color={tag.styling?.textColor || undefined}
            />
          ))}
        </div>
        {claim.compensationOption && (
          <p className="info-caption text-Text-Subtle">
            {claim.compensationOption?.label}:{" "}
            {claim.compensationOption.compensationValue}
          </p>
        )}
      </div>
    );

  return (
    <div className="flex h-full flex-row items-center space-x-medium py-small">
      <ProfilePicture
        profilePictureUrl={claim.professionalProfile?.profilePictureUrl}
        modality={claim.modality}
        size={48}
        style={style}
      />
      <div className="flex flex-1 flex-col justify-center">
        <p className="body-regular truncate text-Text-Default">
          {claim.professionalProfile?.firstName}{" "}
          {claim.professionalProfile.lastName}
        </p>
        {subtitleComponent}
        <p
          onClick={onClick}
          className="action-sm cursor-pointer text-Action-Primary"
        >
          {t("view_more")}
        </p>
      </div>
    </div>
  );
};
