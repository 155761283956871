export const I18N_NAMESPACES = [
  "calendar",
  "cancel-approved-claim",
  "common",
  "edit-professional",
  "edit-shift",
  "facility-staff",
  "internal-professional-page",
  "no-internet",
  "offers",
  "professional-claim",
  "professionals/favorite",
  "professionals/profile",
  "publish-shift",
  "register",
  "reset-password",
  "settings",
  "shift-claim-details",
  "shift-claim-list",
  "shift-list",
  "sign-in"
] as const;
export type I18NNamespace = (typeof I18N_NAMESPACES)[number];
