import { HTMLProps, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from "@mui/material";

import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import colors from "@/config/color-palette";

import clsx from "clsx";

type FormSelectProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  options: {
    label: string;
    value: string;
    disabled?: boolean;
    separator?: boolean;
  }[];
  className?: HTMLProps<HTMLDivElement>["className"];
  showNotSelected?: boolean;
  onChange?: (event: SelectChangeEvent<PathValue<T, Path<T>>>) => void;
} & Pick<SelectProps, "label" | "required">;

const FormSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  className,
  showNotSelected = false,
  required,
  onChange: onChangeProp,
}: FormSelectProps<T>) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <div className={clsx("w-full", className)}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error },
        }) => {
          return (
            <FormControl fullWidth error={Boolean(error)}>
              <InputLabel required={required} id={`label-${name}`}>
                {label}
              </InputLabel>
              <Select
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                IconComponent={() => (
                  <div className="pointer-events-none absolute right-3 top-1/2 -translate-y-1/2">
                    {open ? (
                      <IconChevronUp size={24} color={colors["Text-Subtle"]} />
                    ) : (
                      <IconChevronDown
                        size={24}
                        color={colors["Text-Subtle"]}
                      />
                    )}
                  </div>
                )}
                labelId={`label-${name}`}
                label={label}
                onChange={(e) => {
                  onChange(e);
                  onChangeProp?.(e);
                }}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
              >
                {showNotSelected && (
                  <MenuItem value="">
                    <em>Ninguna</em>
                  </MenuItem>
                )}

                {options.flatMap((option) => {
                  const arr = [
                    <MenuItem
                      disabled={option.disabled}
                      key={`select-${name}-${option.value}`}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>,
                  ];
                  if (option.separator) {
                    arr.push(<Divider key={`${option.value}-divider`} />);
                  }
                  return arr;
                })}
              </Select>
              {error && (
                <Typography
                  className="!mx-[14px] !mt-[3px]"
                  variant="caption"
                  color="error"
                >
                  {t(error.message as never)}
                </Typography>
              )}
            </FormControl>
          );
        }}
      />
    </div>
  );
};

export default FormSelect;
