import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";

import {
  IconBriefcase,
  IconBuildingHospital,
  IconChevronLeft,
  IconHeart,
  IconKey,
  IconLanguage,
  IconMail,
  IconMapPin,
  IconNurse,
  IconUser,
  IconWorld,
  IconX,
} from "@tabler/icons-react";

import ChangeLanguageTab from "@/components/account/ChangeLanguageTab";
import { ChangePasswordComponent } from "@/components/account/ChangePassword";
import FavoriteProfessionalsSection from "@/components/common/modal/ProfileModal/FavoriteProfessionalsSection";
import SectionContainer from "@/components/common/modal/ProfileModal/SectionContainer";
import SectionRow from "@/components/common/modal/ProfileModal/SectionRow";
import { TagLabel } from "@/components/common/TagLabel";

import colors from "@/config/color-palette";
import useCurrentLanguage from "@/hooks/use-current-language";
import { useModal } from "@/hooks/use-modal";
import { UserFeatureEnum } from "@/services/account";
import { selectProfile } from "@/store/selectors/account.selector";

import clsx from "clsx";

const ProfileModal = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation([
    "settings",
    "professionals/favorite",
    "professionals/profile",
  ]);
  const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

  const handleSelectItem = (tab: string) => {
    setActiveTab(tab);
  };

  const handleBack = () => {
    setActiveTab(undefined);
  };

  return (
    <div className="relative flex max-h-[95dvh] flex-col gap-3 overflow-y-scroll">
      <div className="flex w-full items-center justify-between border-b bg-white px-6  pb-2 pt-6">
        <Typography
          variant="h5"
          className="text-2xl !font-semibold leading-8 text-Text-Default"
        >
          {t("settings_modal_header_title")}
        </Typography>
        <button onClick={closeModal} className="rounded-full p-1 ">
          <IconX size={24} color={colors["Text-Subtle"]} />
        </button>
      </div>
      <div className="mt-4 space-y-6 px-6 pb-6">
        <div
          className={clsx(
            "transition-transform duration-300",
            !!activeTab ? "-translate-x-full" : "translate-x-0"
          )}
        >
          <MenuList onSelectItem={handleSelectItem} />
        </div>
        <div
          className={clsx(
            "absolute left-0 top-10 bg-BG-Default",
            "transition-transform duration-300",
            activeTab ? "translate-x-0" : "translate-x-full"
          )}
        >
          {!!activeTab && (
            <div className="size-full p-6">
              <div className="flex size-full flex-col items-start overflow-hidden rounded-lg bg-white pb-12">
                <Grid
                  direction={"row"}
                  className="p-2"
                  container
                  alignItems={"center"}
                >
                  <IconButton onClick={handleBack}>
                    <IconChevronLeft size={24} color={colors["Text-Subtle"]} />
                  </IconButton>
                  <Typography
                    variant="subtitle1"
                    className="!font-semibold !text-Text-Subtle"
                  >
                    {activeTab === "change-password" &&
                      t("change_password_title")}

                    {activeTab === "favorite-professionals" &&
                      t("professionals/favorite:favorite_professionals_title")}

                    {activeTab === "change-language" &&
                      t("professionals/profile:change_language_modal_title")}
                  </Typography>
                </Grid>
                {activeTab === "change-password" && (
                  <ChangePasswordComponent onSubmit={handleBack} />
                )}
                {activeTab === "favorite-professionals" && (
                  <FavoriteProfessionalsSection />
                )}
                {activeTab === "change-language" && (
                  <ChangeLanguageTab onComplete={handleBack} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface MenuListProps {
  activeTab?: string;
  onSelectItem: (tab: string) => void;
}

const MenuList: FC<MenuListProps> = ({ onSelectItem, activeTab }) => {
  const { t } = useTranslation(["settings", "professionals/favorite"]);
  const { t: tLang } = useTranslation("professionals/profile");
  const currentLanguage = useCurrentLanguage();

  const profile = useSelector(selectProfile);

  if (!profile?.id) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3">
      {/* User Info */}
      <SectionContainer title={t("settings_screen_information")}>
        <SectionRow
          icon={<IconUser size={24} color={colors["Neutral-400"]} />}
          text={profile?.firstName + " " + profile?.lastName}
        />
        <SectionRow
          icon={<IconMail size={24} color={colors["Neutral-400"]} />}
          text={profile?.email}
        />
      </SectionContainer>

      <SectionContainer title={t("settings_screen_settings_config")}>
        {/* profile favorite professionals */}
        {profile?.userFeatures?.includes(
          UserFeatureEnum.FAVOURITE_PROFESSIONALS_MANAGEMENT
        ) && (
          <SectionRow
            icon={<IconHeart size={24} color={colors["Neutral-400"]} />}
            text={t("professionals/favorite:favorite_professionals_title")}
            onClick={() => onSelectItem("favorite-professionals")}
          />
        )}
        {/* Change Password */}
        <SectionRow
          icon={<IconKey size={24} color={colors["Neutral-400"]} />}
          text={t("change_password_title")}
          onClick={() => onSelectItem("change-password")}
        />
        {/* Change language */}
        {process.env.NODE_ENV === "development" && (
          <SectionRow
            icon={<IconLanguage size={24} color={colors["Neutral-400"]} />}
            text={tLang(`lang_${currentLanguage}`)}
            onClick={() => onSelectItem("change-language")}
          />
        )}
      </SectionContainer>

      {/* Facility Info */}
      <SectionContainer title={t("settings_screen_facility_info")}>
        <SectionRow
          icon={<IconBriefcase size={24} color={colors["Neutral-400"]} />}
          text={profile?.facility?.name ?? ""}
        />
        <SectionRow
          icon={
            <IconBuildingHospital size={24} color={colors["Neutral-400"]} />
          }
          text={profile?.facility?.publicName ?? ""}
        />
        {(profile?.units?.length ?? 0) > 0 && (
          <SectionRow
            icon={<IconNurse size={24} color={colors["Neutral-400"]} />}
            text={""}
          >
            <div className="flex w-full flex-wrap">
              {profile?.units?.map((unit, index) => (
                <div className="my-1 mr-tiny" key={index}>
                  <TagLabel text={unit.displayName} />
                </div>
              ))}
            </div>
          </SectionRow>
        )}
        <SectionRow
          icon={
            <span className="ml-1 font-semibold text-Neutral-400">CIF:</span>
          }
          text={profile?.facility?.cif ?? "--"}
        />
        <SectionRow
          icon={<IconMapPin size={24} color={colors["Neutral-400"]} />}
          text={profile?.facility.address}
          onClick={
            profile?.facility?.mapLink
              ? () => {
                  window.open(
                    profile.facility.mapLink,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }
              : undefined
          }
        />
        <SectionRow
          icon={<IconMapPin size={24} color={colors["Neutral-400"]} />}
          text={profile?.facility.addressCity}
        />
        <div className="mt-1 flex items-center gap-2 text-gray-800">
          <IconWorld size={24} color={colors["Neutral-400"]} />
          <a
            href={normalizeUrl(profile.facility?.webPage) ?? "#"}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {profile.facility?.webPage ?? "--"}
          </a>
        </div>
      </SectionContainer>
    </div>
  );
};

function normalizeUrl(domainOrUrl?: string | null) {
  if (!domainOrUrl) return "";
  if (!domainOrUrl.startsWith("http")) {
    return `http://${domainOrUrl}`;
  }
  return domainOrUrl;
}

export default ProfileModal;
