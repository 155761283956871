import { useTranslation } from "react-i18next";

import { ShiftCardTag } from "@/components/shifts/ShiftCardTag";

import { ClaimRequest } from "@/types/claims";

import { AcceptButton } from "./AcceptButton";
import { ClaimRow } from "./ClaimRow";
import OpenFavoriteProfessionals from "./professionalClaim/OpenFavoriteProfessionals";
import { RejectButton } from "./RejectButton";

interface PendingClaimsComponentProps {
  claims?: ClaimRequest[];
  acceptingClaimId?: number;
  rejectingClaimId?: number;
  onAccept: (claimId: ClaimRequest) => void;
  onReject: (claimId: number) => void;
  selectClaim: (claim: ClaimRequest) => void;
}

export const PendingClaimsComponent: React.FC<PendingClaimsComponentProps> = ({
  claims,
  acceptingClaimId,
  rejectingClaimId,
  onAccept,
  onReject,
  selectClaim,
}) => {
  const { t } = useTranslation([
    "shift-claim-details",
    "professionals/favorite",
  ]);
  return (
    <div className="flex w-full min-w-fit flex-col">
      <div className="mb-small flex items-center space-x-tiny">
        <p className="heading-md">{t("pending_claims_title")}</p>
        {claims && claims.length > 0 && (
          <ShiftCardTag isFilled={false} totalPendingClaims={claims.length} />
        )}
      </div>

      {claims && claims.length > 0 ? (
        <div className="flex w-full flex-1 flex-col justify-start space-y-small">
          {claims.map((claim, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-between space-x-small"
            >
              <ClaimRow claim={claim} onClick={() => selectClaim(claim)} />
              <div className="flex flex-row items-center justify-center space-x-small">
                <AcceptButton
                  onClick={() => {
                    onAccept(claim);
                  }}
                  isDisabled={false}
                  isLoading={acceptingClaimId === claim.id}
                />
                <RejectButton
                  onClick={() => {
                    onReject(claim.id);
                  }}
                  isDisabled={false}
                  isLoading={rejectingClaimId === claim.id}
                />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-scrollbar flex h-full flex-1 flex-col space-y-large divide-y divide-Divider-Default overflow-y-auto">
          <p>
            {t("professionals/favorite:searching_professionals_description")}
          </p>

          <div className="my-small">
            <OpenFavoriteProfessionals />
          </div>
        </div>
      )}
    </div>
  );
};
