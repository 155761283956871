import { APIService, APIServiceName } from "@/services/api.service";
import {
  OfferClaimRejectReason,
  OfferClaimsResponse,
  OfferClaimStatus,
  OfferDetail,
  OfferListingResponse,
} from "@/types/offers";

import { api as axios, handleApiError } from "./api";

type Params = {
  status?: string;
};

export function fetchOffers(status?: string): Promise<OfferListingResponse> {
  // return mockFetchOffers(status)

  const uri = "/facility/portal/offers";
  const params: Params = {};
  if (status && status !== "ALL") {
    params.status = status;
  }

  return axios
    .get(uri, { params })
    .then((response) => {
      return response.data;
    })
    .catch(handleApiError);
}

export function fetchOfferClaims(
  offerId: number,
  status?: string
): Promise<OfferClaimsResponse> {
  // return mockFetchOfferClaims(offerId, status)

  const uri = `/facility/portal/offers/${offerId}/claims`;
  const params: Params = {};
  if (status && status !== "ALL") {
    params.status = status;
  }

  return axios
    .get(uri, { params })
    .then((response) => {
      return response.data;
    })
    .catch(handleApiError);
}

export function fetchOfferDetail(offerId: number) {
  const uri = `/facility/portal/offers/${offerId}/details`;
  const api = APIService.getInstance(APIServiceName.AUTHORIZED);

  return api.get<OfferDetail>(uri).catch(handleApiError);
}

export function updateOfferClaimStatus(
  offerId: number,
  claimId: number,
  newStatus: OfferClaimStatus,
  reason?: string,
  reasonComment?: string
): Promise<void> {
  return axios
    .post(`/facility/portal/offers/${offerId}/claims/${claimId}`, {
      newStatus,
      reason,
      reasonComment,
    })
    .then((response) => {
      return response.data;
    })
    .catch(handleApiError);
}

export function fetchOfferClaimRejectReasons(): Promise<
  OfferClaimRejectReason[]
> {
  return mockFetchCancelOfferReasons();

  return axios
    .get("/facility/common/offer-claim-reject-reasons")
    .then((response) => {
      return response.data;
    })
    .catch(handleApiError);
}

export function mockFetchCancelOfferReasons(): Promise<
  OfferClaimRejectReason[]
> {
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve(mockCancelOfferReasons);
    }, 1000);
  });
}

const mockCancelOfferReasons: OfferClaimRejectReason[] = [
  {
    displayText: "No tiene suficiente experiencia",
    value: "NO_SUFFICIENT_EXPERIENCE",
  },
  {
    displayText: "Trabajará en otra posición",
    value: "PROFESSIONAL_WORK_IN_OTHER_POSITION",
  },
  {
    displayText: "El puesto ya está cubierto",
    value: "POSITION_ALREADY_COVERED",
  },
  {
    displayText: "Mala experiencia con el profesional",
    value: "BAD_EXPERIENCE_WITH_PROFESSIONAL",
  },
  { displayText: "Other", value: "OTHER" },
];
