import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import OfferSideBar from "@/pages/OfferDetail/components/OfferSideBar";
import { OfferSideBarMenu } from "@/pages/OfferDetail/components/OfferSideBarMenu";
import {
  OfferDetailProvider,
  useOfferDetail,
} from "@/pages/OfferDetail/contexts/OfferDetailContext";
import { OfferClaimsList } from "@/pages/OfferDetail/OfferDetailPage/views/OfferClaimsList";
import OfferInfoTab from "@/pages/OfferDetail/OfferDetailPage/views/OfferInfoTab";
import { mapDurationToText } from "@/pages/OfferDetail/utils";
import OfferStatusDEPRECATED from "@/components/Offer/OfferStatus.deprecated";

import { useInvalidateQuery } from "@/hooks/use-invalidate-query";
import { useSearchParam } from "@/hooks/use-search-params";
import { OFFER_LIST_QUERY_KEY } from "@/queries/offer-list";

import clsx from "clsx";

enum Tab {
  CANDIDATES = "CANDIDATE",
  DETAILS = "DETAILS",
}

const OfferDetailView = () => {
  const { t } = useTranslation("offers");
  const navigate = useNavigate();
  const location = useLocation();

  const {
    skillText: _skillText = "",
    duration: _duration = "",
    status: _status,
    action,
  } = location.state ?? {};

  const [selectedTab, setSelectedTab] = useState(Tab.DETAILS);
  const [isVisible, setIsVisible] = useState(false);
  const invalidQuery = useInvalidateQuery();
  const { offer, offerId } = useOfferDetail();

  const { duration } = offer ?? {};

  const goBack = () => {
    // Invalidate the offer list query to refresh the list
    invalidQuery(OFFER_LIST_QUERY_KEY);
    setIsVisible(false);
    setTimeout(() => {
      navigate("/ofertas-publicadas");
    }, 300);
  };

  useEffect(() => {
    offerId && setIsVisible(true);
  }, [offerId]);

  if (!offerId) {
    return null;
  }

  const skillText = offer?.skill?.displayText ?? _skillText;
  const durationText =
    mapDurationToText(duration?.type, duration?.date) ?? _duration;
  const status = offer?.status ?? _status;

  const backLabel =
    skillText + (skillText && durationText ? " · " : "") + durationText;

  return (
    <div
      className={clsx(
        "absolute inset-0 flex overflow-hidden bg-BG-Default transition-transform duration-300",
        isVisible ? "translate-x-0" : "translate-x-full"
      )}
    >
      {/* Side bar */}

      <OfferSideBar goBack={goBack} backLabel={backLabel}>
        <>
          {status && (
            <div className="mx-3 mb-1">
              <OfferStatusDEPRECATED status={status} />
            </div>
          )}
          <div className="inline-flex h-24 flex-col items-start justify-start gap-2 px-3">
            <div className="flex h-24 cursor-pointer flex-col items-start justify-start self-stretch">
              <OfferSideBarMenu
                label={t("offer_candidates")}
                badge={offer != null ? offer.totalNewClaims : 0}
                onClick={() => setSelectedTab(Tab.CANDIDATES)}
                selected={selectedTab === Tab.CANDIDATES}
              />

              <OfferSideBarMenu
                label={t("offer_details")}
                badge={0}
                onClick={() => setSelectedTab(Tab.DETAILS)}
                selected={selectedTab === Tab.DETAILS}
              />
            </div>
          </div>
        </>
      </OfferSideBar>

      {/* Content */}
      <div className="flex w-full flex-1 overflow-x-hidden">
        {selectedTab === Tab.CANDIDATES && (
          // Candidates list
          <OfferClaimsList />
        )}
        {selectedTab === Tab.DETAILS && (
          // Offer info
          <OfferInfoTab />
        )}
      </div>
    </div>
  );
};

const OfferDetailPage = () => {
  const offerId = useSearchParam("offerId");

  return (
    <OfferDetailProvider offerId={offerId}>
      {offerId && <OfferDetailView />}
    </OfferDetailProvider>
  );
};

export default OfferDetailPage;
