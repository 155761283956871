import React, { useEffect, useState } from "react";

import LivoIcon from "@/components/common/LivoLogo";

type Option = {
  id: string;
  name: string;
};
interface DropwDownProps {
  setOptionId: (time: string) => void;
  selectedOptionId: string;
  placeHolder?: string;
  errorMessage?: string;
  options?: Option[]; // Added options prop
  disabled?: boolean;
  label?: string;
}

export const DropwDown: React.FC<DropwDownProps> = ({
  setOptionId,
  selectedOptionId,
  placeHolder,
  errorMessage,
  options = [], // Default to an empty array if no options are provided
  disabled,
  label,
}) => {
  const selectedOptionName = options.find(
    (option) => option.id === selectedOptionId
  )?.name;

  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (option: Option) => {
    setOptionId(option.id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", () => setIsOpen(false));
    } else {
      document.removeEventListener("mousedown", () => setIsOpen(false));
    }

    return () => {
      document.removeEventListener("mousedown", () => setIsOpen(false));
    };
  }, [isOpen]);

  return (
    <div className="relative flex flex-col">
      {label && selectedOptionId && selectedOptionId !== "" ? (
        <div className="absolute -top-small left-small bg-white px-tiny">
          <p className="info-overline text-Text-Subtle">{label}</p>
        </div>
      ) : null}
      <div
        className={`ring-solid shrink-1 box-border flex w-full flex-row items-center justify-between space-x-small rounded-[8px] px-small py-medium ring-1 ${!disabled ? "bg-white" : "bg-Background-Secondary text-Text-Subtle"}
                    ${errorMessage ? "ring-2 ring-red-500" : isOpen ? "ring-2 ring-Action-Secondary" : "ring-Divider-Subtle"} `}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p
          className={`body-regular w-full ${selectedOptionName && !disabled ? "text-Text-Default" : "text-Placeholder-Default"}`}
        >
          {selectedOptionName || placeHolder}
        </p>
        <div className="items-center justify-center">
          <LivoIcon name={"chevron-down"} size={24} color={"#ACBBCB"} />
        </div>
      </div>
      {isOpen && options.length > 0 && (
        <ul className="absolute top-full z-20 mt-tiny max-h-60 w-full overflow-auto rounded-[8px] border border-Divider-Default bg-white">
          {options.map((option, index) => (
            <li
              key={index}
              className="body-regular cursor-pointer px-large py-medium hover:bg-Background-Secondary"
              onMouseDown={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
      {errorMessage && (
        <p className="info-caption mt-tiny text-Negative-500">{errorMessage}</p>
      )}
    </div>
  );
};
