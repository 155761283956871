import React, { useMemo } from "react";

import LivoIcon from "@/components/common/LivoLogo";

import {
  QualificationDataDTO,
  QualificationDTO,
} from "@/types/common/curriculum";
import { BADGE_GRAY } from "@/utils/colors";

import clsx from "clsx";

interface QualificationCardProps {
  qualification: QualificationDTO;
  className?: string;
}

type QualificationSectionGroup = {
  [title: string]: QualificationSectionProps;
};

export const QualificationCard: React.FC<QualificationCardProps> = ({
  qualification,
  className,
}) => {
  const { qualifications } = qualification;
  const noQualifications =
    !qualification.qualifications || qualifications.length === 0;

  const qualificationSections = useMemo(() => {
    if (noQualifications) {
      return [];
    }

    const qualificationSectionGroup = qualifications.reduce(
      (group, qualification, index) => {
        if (!group[qualification.title]) {
          group[qualification.title] = {
            order: index,
            title: qualification.title,
            qualifications: [],
          };
        }

        group[qualification.title].qualifications.push(qualification);
        return group;
      },
      {} as QualificationSectionGroup
    );

    return Object.values(qualificationSectionGroup).sort(
      (s1, s2) => s1.order - s2.order
    );
  }, [noQualifications, qualifications]);

  return (
    <div className={"rounded-lg p-4 bg-white " + (className || "")}>
      <p className="heading-md mb-3">{qualification.title}</p>

      {noQualifications && (
        <p className="body-regular text-Text-Subtle">
          {qualification.description}
        </p>
      )}

      {qualificationSections.map((sectionProps, index) => (
        <QualificationSection
          {...sectionProps}
          key={index}
          lastSection={index >= qualificationSections.length - 1}
        />
      ))}
    </div>
  );
};

interface QualificationSectionProps {
  order: number;
  title: string;
  qualifications: QualificationDataDTO[];
  lastSection?: boolean;
}

function QualificationSection({
  title,
  qualifications,
  lastSection,
}: QualificationSectionProps) {
  return (
    <div>
      <p className="subtitle-regular mb-1 font-semibold">{title}</p>

      {qualifications.map((qualification, qualificationIndex) => (
        <div
          key={qualificationIndex}
          className={clsx(
            lastSection &&
              qualificationIndex >= qualifications.length - 1 &&
              "mb-5 border-b border-Divider-Default pb-5"
          )}
        >
          {qualification.details.map((detail, detailIndex) => (
            <div key={detailIndex} className="flex w-full items-start">
              {detail.icon && (
                <div className="mr-1">
                  <LivoIcon
                    name={detail.icon.name}
                    size={detail.icon.size || 24}
                    color={detail.icon.color || BADGE_GRAY}
                  />
                </div>
              )}
              {detail.displayText && (
                <p className="body-regular">{detail.displayText}</p>
              )}
              {detail.additionalText && (
                <p className="body-regular text-Text-Subtle">
                  {detail.additionalText}
                </p>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
