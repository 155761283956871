import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { EmptyShiftsState } from "@/components/calendar/EmptyShiftsState";
import LivoIcon from "@/components/common/LivoLogo";
import { ShiftConfiguration } from "@/components/publishShift/BaseShiftForm";
import { PublishShiftModal } from "@/components/publishShift/PublishShiftModal";
import { EditShiftModal } from "@/components/shiftDetails/EditShiftModal";
import { HolidayTag } from "@/components/shiftDetails/HolidayTag";
import { ShiftCard } from "@/components/shifts/ShiftCard";

import { DATETIME_FORMAT } from "@/config/datetime-format.enum";
import { setSelectedShiftId } from "@/store/actions";
import { RootState } from "@/store/types";
import { applyFilter } from "@/types/common/shiftFilters";
import {
  ActionComponentIdEnum,
  Shift,
  ShiftTimeInDayEnum,
} from "@/types/shifts";
import { formatDate, SHIFT_TIME_IN_DAY_DEFINITIONS } from "@/utils";
import { formatDateTime, isBeforeToday } from "@/utils/datetime";

import { PastEmptyShiftsState } from "./PastShiftsEmptyState";

interface CalendarDayShiftsProps {
  shifts: Shift[];
  date: string;
  loading: boolean;
  reloadData: () => void;
  shadowReload: () => void;
  holiday?: boolean;
}

export const CalendarDayShifts: React.FC<CalendarDayShiftsProps> = ({
  shifts,
  date,
  loading,
  reloadData,
  shadowReload,
  holiday = false,
}): JSX.Element => {
  const { t } = useTranslation("calendar");
  const [publishShiftOpen, setPublishShiftOpen] = React.useState(false);
  const [selectedShiftTimeInDay, setSelectedShiftTimeInDay] =
    React.useState<ShiftTimeInDayEnum | null>(null);
  const { selectedShiftId } = useSelector(
    (state: RootState) => state.calendarSummary
  );
  const [actionableShift, setActionableShift] = React.useState<Shift | null>(
    null
  );
  const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] =
    React.useState(false);
  const dispatch = useDispatch();
  const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const filters = useSelector((state: RootState) => state.filterShifts.filters);
  let filteredShifts = shifts;
  filters.forEach((f) => (filteredShifts = applyFilter(f, filteredShifts)));

  useEffect(() => {
    if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
      shiftRefs.current[selectedShiftId]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedShiftId]);

  if (loading) {
    return (
      <div className="flex h-full flex-1 items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  const handleOpen = () => {
    setPublishShiftOpen(true);
    setEditShiftDetailsModalOpen(false);
  };

  const handleClose = () => {
    setPublishShiftOpen(false);
    setActionableShift(null);
  };

  return (
    <div className="content no-scrollbar flex flex-1 justify-center overflow-y-auto px-medium pt-xLarge">
      <div className="flex max-w-screen-lg flex-1 flex-col">
        <div className="mb-large flex flex-row items-center  space-x-tiny">
          <h1 className="heading-md">{formatDate(date)}</h1>
          <HolidayTag holiday={holiday} />
        </div>
        <div
          className={
            selectedShiftId
              ? "flex flex-col space-y-large"
              : "flex flex-col space-y-large lg:flex-row lg:space-x-large lg:space-y-0"
          }
        >
          {Object.keys(SHIFT_TIME_IN_DAY_DEFINITIONS).map(
            (shiftTimeInDay, index) => {
              const shiftsForTimeInDay = filteredShifts.filter(
                (shift) => shift.shiftTimeInDay === shiftTimeInDay
              );
              const props =
                SHIFT_TIME_IN_DAY_DEFINITIONS[
                  shiftTimeInDay as ShiftTimeInDayEnum
                ];
              return (
                <div key={index} className="mb-large flex-1">
                  <div className="mb-medium flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center space-x-tiny">
                      <LivoIcon
                        name={props.icon}
                        size={24}
                        color={props.color}
                      />
                      <h2 className="subtitle-regular">
                        {t(props.name as never)}
                      </h2>
                    </div>
                    <button
                      disabled={isBeforeToday(date)}
                      onClick={() => {
                        setSelectedShiftTimeInDay(
                          shiftTimeInDay as ShiftTimeInDayEnum
                        );
                        handleOpen();
                      }}
                      className={`p-tiny ${isBeforeToday(date) ? "opacity-0" : ""}`}
                    >
                      <LivoIcon name="plus" size={24} color="#149EF2" />
                    </button>
                  </div>
                  <div className="flex flex-col space-y-medium">
                    {shiftsForTimeInDay.length === 0 ? (
                      isBeforeToday(date) ? (
                        <PastEmptyShiftsState />
                      ) : (
                        <EmptyShiftsState
                          date={formatDate("DD/MM/YYYY")}
                          shiftTimeInDay={t(props.name as never)}
                          onClick={() => {
                            setSelectedShiftTimeInDay(
                              shiftTimeInDay as ShiftTimeInDayEnum
                            );
                            handleOpen();
                          }}
                        />
                      )
                    ) : (
                      shiftsForTimeInDay.map((shift, index) => {
                        return (
                          <div
                            key={index}
                            ref={(el: any) => {
                              shiftRefs.current[shift.id] = el;
                            }}
                          >
                            <ShiftCard
                              shift={shift as Shift}
                              onClick={() => {
                                dispatch(setSelectedShiftId(shift.id));
                              }}
                              isSelected={selectedShiftId === shift.id}
                              actionComponents={[
                                {
                                  id: ActionComponentIdEnum.EDIT,
                                  iconName: "pencil",
                                  onClick: () => {
                                    setActionableShift(shift);
                                    setEditShiftDetailsModalOpen(true);
                                    setPublishShiftOpen(false);
                                  },
                                },
                                {
                                  id: ActionComponentIdEnum.COPY,
                                  iconName: "copy",
                                  onClick: () => {
                                    setActionableShift(shift);
                                    setEditShiftDetailsModalOpen(false);
                                    setPublishShiftOpen(true);
                                  },
                                },
                              ]}
                            />
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            }
          )}
          <div
            style={{
              height: "24px",
            }}
          ></div>
        </div>
        <PublishShiftModal
          isOpen={publishShiftOpen}
          onClose={handleClose}
          selectedDate={date}
          shiftTimeInDay={selectedShiftTimeInDay || ShiftTimeInDayEnum.MORNING}
          reloadData={reloadData}
          shiftDetails={
            actionableShift
              ? ({
                  ...actionableShift,
                  recurrentDates: actionableShift
                    ? [
                        formatDateTime(
                          actionableShift.startTime,
                          DATETIME_FORMAT.DATE_UK
                        ),
                      ]
                    : [],
                  compensationOptions:
                    actionableShift?.compensationOptions.map(
                      (option) => option.value
                    ) || [],
                } as ShiftConfiguration)
              : undefined
          }
        />
        <EditShiftModal
          isOpen={editShiftDetailsModalOpen && actionableShift !== null}
          onClose={() => {
            setEditShiftDetailsModalOpen(false);
            setActionableShift(null);
          }}
          reloadData={() => {
            shadowReload();
          }}
          shiftDetails={actionableShift}
        />
      </div>
    </div>
  );
};
