import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { SortingOptionsEnum } from "@/components/common/SortingSelector";
import { TagLabel } from "@/components/common/TagLabel";
import { ModalityTag } from "@/components/internal/ModalityTag";
import { CapacityComponent } from "@/components/shifts/CapacityComponent";
import { ScheduleComponent } from "@/components/shifts/ScheduleComponent";
import { ShiftCard } from "@/components/shifts/ShiftCard";
import { ShiftCardContainer } from "@/components/shifts/ShiftCardContainer";
import { ShiftCardTag } from "@/components/shifts/ShiftCardTag";

import { ShiftModalityEnum } from "@/types";
import {
  ActionComponentIdEnum,
  Shift,
  ShiftTimeStatusEnum,
} from "@/types/shifts";
import { getDate, getWeekDay } from "@/utils";

import { LargeCardHeader } from "./LargeCardHeader";

interface ShiftListCardProps {
  shift: Shift;
  isSelected?: boolean;
  onClick?: () => void;
  actionComponents?: {
    iconName: string;
    onClick: (shift: Shift) => void;
    id: ActionComponentIdEnum;
  }[];
  sortedBy?: SortingOptionsEnum;
}

export const ShiftListCard: React.FC<ShiftListCardProps> = ({
  shift,
  isSelected,
  onClick,
  actionComponents,
  sortedBy,
}) => {
  const { t } = useTranslation("shift-claim-list");
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    const handleResize = () => {
      if (container) {
        setIsLargeScreen(container.offsetWidth > 840);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);
    if (container) {
      resizeObserver.observe(container);
      handleResize(); // Initial check
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  const poolAndInternalOnboarded =
    shift.livoPoolOnboarded && shift.livoInternalOnboarded;

  const ScheduleTimeColumn = () => (
    <div className="flex w-full flex-1 items-center justify-center">
      <ScheduleComponent
        startTime={shift.startTime}
        finishTime={shift.finishTime}
        style="body-sm"
      />
    </div>
  );

  const PublicationTimeColumn = () => (
    <div className="flex w-full flex-1 items-end justify-end space-x-medium">
      <div className="body-sm flex items-center justify-end">
        {t("published_label", {
          date: getDate(shift.createdAt),
        })}
      </div>
    </div>
  );

  const ShiftTimeColumn = () => (
    <div className="flex w-full flex-1 items-end justify-end space-x-medium">
      <div className="body-sm flex items-center justify-end">
        {getWeekDay(shift.startTime)} {getDate(shift.startTime)}
      </div>
    </div>
  );

  const largeCard = sortedBy ? (
    <div className="flex w-full flex-1 items-center justify-between space-x-small p-small py-large">
      <div className="w-[240px] shrink-0">
        <LargeCardHeader
          title={shift.title}
          recurrent={shift.recurrentDates?.length > 1}
          category={shift.category}
        />
      </div>
      <div className="flex w-full flex-1 flex-row items-center justify-start space-x-tiny">
        {poolAndInternalOnboarded && (
          <div className="flex items-center space-x-tiny">
            <ModalityTag
              modality={ShiftModalityEnum.REPEAT}
              style={
                shift.recurrentDates?.length > 1 ? "opacity-1" : "opacity-0"
              }
              shortTag={true}
            />
            <ModalityTag
              modality={ShiftModalityEnum.INTERNAL}
              style={
                !shift.internalVisible
                  ? "opacity-0"
                  : isSelected
                    ? "mr-tiny bg-Secondary-050"
                    : "mr-tiny"
              }
              shortTag={true}
            />
            <ModalityTag
              modality={ShiftModalityEnum.EXTERNAL}
              style={
                !shift.externalVisible
                  ? "opacity-0"
                  : isSelected
                    ? "bg-[#E0EFFF]"
                    : ""
              }
              shortTag={true}
            />
          </div>
        )}
        {shift.skills.map((skill, index) => (
          <TagLabel key={index} text={skill.displayText} />
        ))}
      </div>

      {sortedBy === SortingOptionsEnum.SHIFT_PUBLICATION_TIME && (
        <div className="flex flex-1 flex-row items-center justify-between space-x-small">
          <ShiftTimeColumn />
        </div>
      )}

      <div className="flex flex-1 flex-row items-center justify-between space-x-small">
        <ScheduleTimeColumn />
      </div>

      {sortedBy === SortingOptionsEnum.SHIFT_TIME && (
        <div className="flex flex-1 flex-row items-center justify-between space-x-small">
          <PublicationTimeColumn />
        </div>
      )}

      <div className="flex flex-1 flex-row items-center justify-between space-x-small">
        <div className="flex w-full flex-1 items-end justify-end space-x-medium">
          <div className="flex items-center justify-end">
            <CapacityComponent
              acceptedClaims={shift.totalAcceptedClaims}
              totalAcceptedClaimsWithoutHRIntegration={
                shift.totalAcceptedClaimsWithoutHRIntegration
              }
              emptyClaims={shift.capacity - shift.totalAcceptedClaims}
            />
          </div>
          <ShiftCardTag
            totalPendingClaims={
              shift.totalPendingClaims + shift.totalCancellationRequests
            }
            isFilled={
              shift.totalAcceptedClaims === shift.capacity &&
              shift.totalCancellationRequests === 0
            }
            largeTag={true}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex w-full flex-1 items-center justify-between space-x-small p-small py-large">
      <div className="flex w-[180px] flex-1 text-left">
        <LargeCardHeader
          title={shift.title}
          recurrent={shift.recurrentDates?.length > 1}
          category={shift.category}
        />
      </div>
      <div className="flex w-full flex-1 flex-row items-center justify-start space-x-tiny">
        {poolAndInternalOnboarded && (
          <div className="flex items-center space-x-tiny">
            <ModalityTag
              modality={ShiftModalityEnum.REPEAT}
              style={
                shift.recurrentDates?.length > 1 ? "opacity-1" : "opacity-0"
              }
              shortTag={true}
            />
            <ModalityTag
              modality={ShiftModalityEnum.INTERNAL}
              style={
                !shift.internalVisible
                  ? "opacity-0"
                  : isSelected
                    ? "mr-tiny bg-Secondary-050"
                    : "mr-tiny"
              }
              shortTag={true}
            />
            <ModalityTag
              modality={ShiftModalityEnum.EXTERNAL}
              style={
                !shift.externalVisible
                  ? "opacity-0"
                  : isSelected
                    ? "bg-[#E0EFFF]"
                    : ""
              }
              shortTag={true}
            />
          </div>
        )}
        {shift.skills.map((skill, index) => (
          <TagLabel key={index} text={skill.displayText} />
        ))}
      </div>
      <div className="flex flex-1 flex-row items-center justify-between space-x-small">
        <div className="flex w-full flex-1 items-center justify-center">
          <ScheduleComponent
            startTime={shift.startTime}
            finishTime={shift.finishTime}
            style="body-sm"
          />
        </div>
        <div className="flex w-full flex-1 items-end justify-end space-x-medium">
          <div className="flex items-center justify-end">
            <CapacityComponent
              acceptedClaims={shift.totalAcceptedClaims}
              totalAcceptedClaimsWithoutHRIntegration={
                shift.totalAcceptedClaimsWithoutHRIntegration
              }
              emptyClaims={shift.capacity - shift.totalAcceptedClaims}
            />
          </div>
          <ShiftCardTag
            totalPendingClaims={
              shift.totalPendingClaims + shift.totalCancellationRequests
            }
            isFilled={
              shift.totalAcceptedClaims === shift.capacity &&
              shift.totalCancellationRequests === 0
            }
            largeTag={true}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div ref={containerRef} className="w-full">
      {isLargeScreen ? (
        <ShiftCardContainer
          shift={shift}
          isSelected={isSelected}
          onClick={onClick}
          actionComponents={
            shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST
              ? actionComponents?.filter(
                  (actionComponent) =>
                    actionComponent.id !== ActionComponentIdEnum.EDIT
                )
              : actionComponents
          }
        >
          {largeCard}
        </ShiftCardContainer>
      ) : (
        <ShiftCard
          shift={shift}
          isSelected={isSelected}
          onClick={onClick}
          actionComponents={
            shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST
              ? actionComponents?.filter(
                  (actionComponent) =>
                    actionComponent.id !== ActionComponentIdEnum.EDIT
                )
              : actionComponents
          }
          sortedBy={sortedBy}
        />
      )}
    </div>
  );
};
