import { CapacityItem } from "./CapacityItem";

interface CapacitySelectorProps {
  selectedCapacity: number;
  maxCapacity: number;
  setCapacity: (capacity: number) => void;
}

export const CapacitySelector: React.FC<CapacitySelectorProps> = ({
  selectedCapacity,
  maxCapacity,
  setCapacity,
}) => {
  return (
    <div className="flex h-[48px] w-full flex-row space-x-large">
      {Array.from({ length: maxCapacity }, (_, i) => i + 1).map((capacity) => (
        <CapacityItem
          key={capacity}
          isSelected={capacity === selectedCapacity}
          capacity={capacity.toString()}
          onPress={() => setCapacity(capacity)}
        />
      ))}
    </div>
  );
};
