import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SortingOptionsEnum } from "@/components/common/SortingSelector";
import { ShiftDetailsSection } from "@/components/shiftDetails/ShiftDetailsSection";
import { FILTER_CONFIGURATIONS } from "@/components/shiftlist/FiltersRow";
import { ShiftListComponent } from "@/components/shiftlist/ShiftListComponent";

import { ApiApplicationError } from "@/services/api";
import { Logger } from "@/services/logger.service";
import { fetchShifts } from "@/services/shifts-calendar";
import {
  showToastAction,
  toggleInternetConnection,
} from "@/store/actions/appConfigurationActions";
import { setPublishShiftListSelectedShiftId } from "@/store/actions/publishShiftListActions";
import { RootState } from "@/store/types";
import { DayShift } from "@/types/shifts";
import { today } from "@/utils/datetime";

export const PublishedShiftList: React.FC = () => {
  const [loadingShifts, setLoadingShifts] = useState(true);
  const [dayShifts, setDayShifts] = useState<DayShift[]>([]);
  const { appliedFilter, selectedShiftId } = useSelector(
    (state: RootState) => state.publishShiftList
  );
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((state: RootState) => state.account);
  const [sortBy, setSortBy] = useState<SortingOptionsEnum>(
    SortingOptionsEnum.SHIFT_TIME
  );

  const loadData = async () => {
    const filterConfiguration = FILTER_CONFIGURATIONS.find(
      (filter) => filter.id === appliedFilter
    );
    return fetchShifts(
      today().format("YYYY-MM-DD"),
      undefined,
      "ASC",
      filterConfiguration?.configuration,
      sortBy
    )
      .then((response) => {
        setDayShifts(response);
      })
      .catch((error) => {
        dispatch(setPublishShiftListSelectedShiftId(null));
        if (error instanceof ApiApplicationError) {
          if (error.cause === "NO_INTERNET") {
            dispatch(toggleInternetConnection(false));
          } else {
            dispatch(
              showToastAction({
                message: error.message,
                severity: "error",
              })
            );
          }
        }
      });
  };

  const loadShifts = async () => {
    setLoadingShifts(true);
    await loadData()
      .then(() => {
        setLoadingShifts(false);
      })
      .catch((error) => {
        Logger.error("loadData", error);
      });
  };

  useEffect(() => {
    loadShifts();
  }, [appliedFilter, sortBy]);

  return (
    <div className="flex h-full justify-between space-x-medium">
      <ShiftListComponent
        dayShifts={dayShifts}
        loading={loadingShifts}
        reloadData={loadShifts}
        shadowReload={loadData}
        selectedSortingOption={sortBy}
        sortingOptions={
          accountInfo?.facility.portalShiftsOrderingByEnabled
            ? [
                SortingOptionsEnum.SHIFT_TIME,
                SortingOptionsEnum.SHIFT_PUBLICATION_TIME,
              ]
            : []
        }
        setSelectedSortingOption={(option) =>
          setSortBy(option as SortingOptionsEnum)
        }
      />
      <ShiftDetailsSection
        reloadShifts={loadData}
        selectedShiftId={selectedShiftId}
        setSelectedShiftId={(id: number | null) => {
          dispatch(setPublishShiftListSelectedShiftId(id));
        }}
      />
    </div>
  );
};
