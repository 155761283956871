import LivoIcon from "@/components/common/LivoLogo";

interface TagLabelProps {
  text: string;
  icon?: string;
  color?: string;
  iconColor?: string;
  style?: any;
  backgroundColor?: string;
  onRemove?: () => void;
  closeColor?: string;
}

export const TagLabel: React.FC<TagLabelProps> = ({
  text,
  icon,
  color,
  iconColor,
  backgroundColor = "#EEEFF3",
  closeColor = "#707A91",
  onRemove,
  style,
}) => {
  return (
    <div
      className={`flex flex-row items-center space-x-tiny rounded-[4px] px-[6px] py-[2px] ${style}`}
      style={{
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {icon ? (
        <LivoIcon name={icon} size={16} color={iconColor || "#707A91"} />
      ) : null}
      <p
        className={`info-caption text-${color ? color : "Text-Default"} text-nowrap`}
      >
        {text}
      </p>
      {onRemove ? (
        <div
          onClick={onRemove}
          className="flex cursor-pointer items-center justify-center"
        >
          <LivoIcon name="close" size={24} color={closeColor} />
        </div>
      ) : null}
    </div>
  );
};
