import { useTranslation } from "react-i18next";

import { SortingOptionsEnum } from "@/components/common/SortingSelector";
import { ShiftCardHeader } from "@/components/shiftlist/ShiftCardHeader";

import {
  ActionComponentIdEnum,
  Shift,
  ShiftTimeStatusEnum,
} from "@/types/shifts";
import { getDate, getWeekDay } from "@/utils";

import { CapacityComponent } from "./CapacityComponent";
import { ScheduleComponent } from "./ScheduleComponent";
import { ShiftCardContainer } from "./ShiftCardContainer";
import { ShiftCardTag } from "./ShiftCardTag";

interface ShiftCardProps {
  shift: Shift;
  onClick?: () => void;
  isSelected?: boolean;
  actionComponents?: {
    iconName: string;
    onClick: (shift: Shift) => void;
    id: ActionComponentIdEnum;
  }[];
  sortedBy?: SortingOptionsEnum;
}

export const ShiftCard: React.FC<ShiftCardProps> = ({
  shift,
  isSelected,
  onClick,
  actionComponents,
  sortedBy,
}) => {
  const { t } = useTranslation("shift-claim-list");
  const poolAndInternalOnboarded =
    shift.livoPoolOnboarded && shift.livoInternalOnboarded;

  return (
    <ShiftCardContainer
      shift={shift}
      isSelected={isSelected}
      onClick={onClick}
      actionComponents={
        shift.shiftTimeStatus === ShiftTimeStatusEnum.PAST
          ? actionComponents?.filter(
              (actionComponent) =>
                actionComponent.id !== ActionComponentIdEnum.EDIT
            )
          : actionComponents
      }
    >
      <div className=" flex w-full flex-col p-small">
        <div className="flex w-full flex-row items-start justify-between">
          <ShiftCardHeader
            title={shift.title}
            recurrent={shift.recurrentDates?.length > 1}
            style={{
              marginBottom: "4px",
            }}
            skills={shift.skills}
            category={shift.category}
            internalVisible={shift.internalVisible && poolAndInternalOnboarded}
            externalVisible={shift.externalVisible && poolAndInternalOnboarded}
          />

          <ShiftCardTag
            totalPendingClaims={
              shift.totalPendingClaims + shift.totalCancellationRequests
            }
            isFilled={
              shift.totalAcceptedClaims === shift.capacity &&
              shift.totalCancellationRequests === 0
            }
          />
        </div>

        <div className="mt-small flex flex-row items-center justify-between">
          {sortedBy === SortingOptionsEnum.SHIFT_PUBLICATION_TIME && (
            <div className="body-sm flex items-center justify-end">
              {getWeekDay(shift.startTime)} {getDate(shift.startTime)}
            </div>
          )}

          <ScheduleComponent
            startTime={shift.startTime}
            finishTime={shift.finishTime}
            style="body-sm"
          />

          {sortedBy === SortingOptionsEnum.SHIFT_TIME && (
            <div className="body-sm flex items-center justify-end">
              {t("published_label", {
                date: getDate(shift.createdAt),
              })}
            </div>
          )}

          <div className="flex items-center justify-end">
            <CapacityComponent
              acceptedClaims={shift.totalAcceptedClaims}
              totalAcceptedClaimsWithoutHRIntegration={
                shift.totalAcceptedClaimsWithoutHRIntegration
              }
              emptyClaims={shift.capacity - shift.totalAcceptedClaims}
            />
          </div>
        </div>
      </div>
    </ShiftCardContainer>
  );
};
