import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { ShiftClaim } from "@/types";
import { formatDate, formatSchedule } from "@/utils";

import BoldTitleAndValue from "./common/BoldTitleAndValue";

export default function ShiftInformationCard({
  claim,
  rightHeader,
}: {
  claim: ShiftClaim;
  rightHeader?: JSX.Element;
}) {
  const { t } = useTranslation(["shift-claim-details", "shift-claim-list"]);
  const reasonDisplay = claim.slotReason
    ? claim.slotReason.displayText +
      (claim.slotReason.comment ? " - " + claim.slotReason.comment : "")
    : "";
  return (
    <Card sx={{ m: 1 }}>
      <CardContent>
        <div className="flex flex-row justify-between">
          <div>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {t("shift_information_title")}
            </Typography>
            <Typography variant="h5" component="div">
              {formatDate(claim.shift.startTime)}
            </Typography>
            <Typography>
              {formatSchedule(claim.shift.startTime, claim.shift.finishTime)}
            </Typography>
            <BoldTitleAndValue
              title={t("total_pay_label")}
              value={claim.shift.formattedTotalPay}
              classname="my-[24px]"
            />
            <BoldTitleAndValue
              title={t("shift-claim-list:specialization_table_title")}
              value={claim.shift.specialization.displayText}
            />
            {claim.slotReason && (
              <BoldTitleAndValue
                title={t("reason_label")}
                value={reasonDisplay}
              />
            )}
          </div>
          <div>{rightHeader}</div>
        </div>
      </CardContent>
    </Card>
  );
}
