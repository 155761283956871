import { ApiApplicationError } from "@/services/api";
import { contactLivo } from "@/services/facility-offer";
import store from "@/store";
import {
  showToastAction,
  toggleInternetConnection,
} from "@/store/actions/appConfigurationActions";
import { SubscriptionStatus } from "@/types/offers";

export const OFFER_CONFIG_QUERY_KEY = "offer-config";

export const PUBLISH_OFFER_KEY = "publish-offer";

export const postContactLivo = async ({
  reason,
  source,
}: {
  reason?: SubscriptionStatus;
  source: "OFFER_PUBLISHING" | "OFFER_LISTING";
}) => {
  try {
    if (!reason) throw new Error("Reason is required");
    await contactLivo(reason, source);
    return { ok: true };
  } catch (error) {
    if (error instanceof ApiApplicationError) {
      if (error.cause === "NO_INTERNET") {
        store.dispatch(toggleInternetConnection(false));
      } else {
        store.dispatch(
          showToastAction({
            message: error.message,
            severity: "error",
          })
        );
      }
    }
  }
};
