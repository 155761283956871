import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import LivoIcon from "@/components/common/LivoLogo";
import { LivoCVModal } from "@/components/curriculum/LivoCVModal";
import { useLivoCVModal } from "@/components/curriculum/useLivoCVModal";

import { fetchClaimInfoAction } from "@/store/actions/claimActions";
import { RootState } from "@/store/types";
import { ClaimStatusEnunm, SlotReason } from "@/types/claims";

import { ManageClaimContainer } from "./ManageClaimContainer";
import { ProfessionalCardHeader } from "./ProfessionalCardHeader";
import { ProfessionalCVSummary } from "./ProfessionalCVSummary";
import { ProfessionalFacilityExperience } from "./ProfessionalFacilityExperience";
import { ProfessionalLivoReviews } from "./ProfessionalLivoReviews";
import { ProfessionalDataBody } from "./ProfessionalProfileDataBody";
import { SlotReasonDetails } from "./SlotReasonDetails";

interface ProfessionalClaimDetailsProps {
  shiftId: number;
  claimId: number;
  goBack: () => void;
  onAccept: () => void;
  onReject: () => void;
  onUpdateSlotReason: (slotReason: SlotReason) => void;
}
export const ProfessionalClaimDetails: React.FC<
  ProfessionalClaimDetailsProps
> = ({ shiftId, claimId, goBack, onAccept, onReject, onUpdateSlotReason }) => {
  const { t } = useTranslation("professional-claim");
  const { claimRequest, isLoading } = useSelector(
    (state: RootState) => state.claimData
  );
  const dispatch = useDispatch();
  const livoCVModal = useLivoCVModal(claimRequest?.professionalProfile?.id);

  useEffect(() => {
    dispatch(fetchClaimInfoAction(shiftId, claimId) as any);
  }, [dispatch, shiftId, claimId]);

  return (
    <div className="flex w-full flex-col pb-large">
      <button
        onClick={goBack}
        className="mb-small flex w-full items-center justify-start space-x-small px-small"
      >
        <LivoIcon name="arrow-left" size={24} color="#ACBBCB" />
      </button>
      {isLoading ? (
        <div className="flex h-full flex-1 items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div className="flex size-full flex-col justify-between pb-large">
          <div className="no-scrollbar flex w-full flex-col space-y-large divide-y divide-Divider-Default overflow-y-scroll">
            <div className="flex w-full flex-col">
              <ProfessionalCardHeader
                professionalProfile={claimRequest.professionalProfile}
                modality={claimRequest.modality}
              />
              <ProfessionalDataBody
                claim={claimRequest}
                onViewLivoCV={livoCVModal.showModal}
              />
            </div>

            {claimRequest.professionalProfile?.cvSummary && (
              <ProfessionalCVSummary
                cvSummary={claimRequest.professionalProfile.cvSummary}
              />
            )}

            <ProfessionalFacilityExperience
              professionalProfile={claimRequest.professionalProfile}
              shiftId={shiftId}
              claimId={claimId}
            />
            {claimRequest.professionalProfile.professionalReview
              ?.averageRating ? (
              <ProfessionalLivoReviews
                review={claimRequest.professionalProfile.professionalReview}
                noPadding={false}
              />
            ) : null}
            {claimRequest.status !== ClaimStatusEnunm.PENDING_APPROVAL &&
            claimRequest.slotReasonOptions &&
            claimRequest.slotReasonOptions.length > 0 ? (
              <SlotReasonDetails
                onUpdateSlotReason={onUpdateSlotReason}
                slotReason={claimRequest.slotReason}
                slotReasonOptions={claimRequest.slotReasonOptions}
                claimId={claimId}
                shiftId={shiftId}
                slotReasonCommentDisplayed={
                  claimRequest.slotReasonCommentDisplayed
                }
              />
            ) : null}
          </div>

          {claimRequest.status === ClaimStatusEnunm.PENDING_APPROVAL ? (
            <div className="flex w-full flex-col px-medium">
              <ManageClaimContainer
                onAccept={onAccept}
                onReject={onReject}
              ></ManageClaimContainer>
            </div>
          ) : null}

          {livoCVModal.livoCVDetails && (
            <LivoCVModal
              fullScreen
              style={{ width: "100%", maxWidth: "600px" }}
              title={t("professional_livo_cv_title", {
                fullName: `${claimRequest.professionalProfile?.firstName} ${claimRequest.professionalProfile?.lastName}`,
              })}
              isOpen={livoCVModal.isOpen}
              onClose={livoCVModal.hideModal}
              livoCVDetails={livoCVModal.livoCVDetails}
            />
          )}
        </div>
      )}
    </div>
  );
};
