import { useNavigate } from "react-router-dom";

import { Card, CardContent, Typography } from "@mui/material";

import Chip from "@/components/common/Chip";
import { NotificationsBadge } from "@/components/common/NotificationsBadge";
import OfferStatus from "@/components/Offer/OfferStatus";

import { Offer } from "@/types/offers";

import clsx from "clsx";

const OfferItem = ({ offer }: { offer: Offer }) => {
  const navigate = useNavigate();

  const onClick = () => {
    const skillText = offer?.skill ?? "";
    const duration = offer?.duration ?? "";
    const status = offer?.status ?? "";
    navigate(`/ofertas-publicadas?offerId=${offer.id}`, {
      state: {
        skillText,
        duration,
        status,
      },
    });
  };

  if (!offer) return null;

  return (
    <Card
      onClick={onClick}
      className="isolate flex cursor-pointer flex-col items-start gap-2 rounded-xl bg-white p-4 px-5 shadow-custom hover:bg-Background-Secondary md:flex-row md:items-center"
    >
      <CardContent className="flex w-full flex-col gap-1 !p-0 md:flex-row md:items-center md:justify-between">
        <div className="flex flex-[2.5] items-center gap-2 truncate">
          <Chip
            label={offer.category?.code ?? "-"}
            className="border border-Neutral-600 bg-Neutral-000"
          />
          <Typography
            variant="h6"
            className="w-full truncate !text-f01 font-semibold !leading-6"
          >
            {offer.skill ?? offer.skills[0] ?? "-"}
          </Typography>
        </div>

        <div className="flex flex-[1.4] justify-start">
          <Typography
            variant="body1"
            className="text-ellipsis  !text-f01 font-normal !leading-6"
          >
            {offer.duration}
          </Typography>
        </div>

        <div className="flex flex-[1.4] justify-start">
          <Typography
            variant="h6"
            className={clsx(
              "mt-2 !text-f01  md:mt-0",
              offer.compensation?.defined
                ? "text-Green-700"
                : "text-Text-Subtle"
            )}
          >
            {offer.compensation?.displayText ?? ""}
          </Typography>
        </div>

        <div className="flex flex-[1.2] justify-start">
          <OfferStatus {...(offer.statusTag ?? {})} />
        </div>

        <div className="w-[20px]">
          {offer.totalNewClaims > 0 && (
            <NotificationsBadge notifications={offer.totalNewClaims} />
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default OfferItem;
