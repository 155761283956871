/**
 * datetime ultilities functions using dayjs
 */

import { DATETIME_FORMAT } from "@/config/datetime-format.enum";

import dayjs, { Dayjs, UnitType } from "dayjs";

export function formatDateTime(
  utcTime: string,
  format: DATETIME_FORMAT = DATETIME_FORMAT.DATE_TIME
) {
  return dayjs(utcTime).format(format);
}

export function formatTime(utcTime: string) {
  return formatDateTime(utcTime, DATETIME_FORMAT.TIME);
}

export function formatDate(utcTime: string) {
  return formatDateTime(utcTime, DATETIME_FORMAT.DATE);
}

export function isBeforeToday(date: string | Dayjs) {
  return dayjs(date).isBefore(dayjs(), "day");
}

export function isToday(date: string) {
  return dayjs(date).isSame(dayjs(), "day");
}

export function isTomorrow(date: string) {
  let tomorrow = dayjs().add(1, "day");
  return dayjs(date).isSame(tomorrow, "day");
}

export function getTomorrowOf(date: Date) {
  return dayjs(date).add(1, "days").toDate();
}

export function formatDateWithToday(parsedDate: string) {
  if (isToday(parsedDate)) {
    return "Hoy";
  }

  if (isTomorrow(parsedDate)) {
    return "Mañana";
  }

  return formatDate(parsedDate);
}

export function formatSchedule(startTime: string, finishTime: string) {
  const formattedStartTime = formatTime(startTime);
  const formattedEndTime = formatTime(finishTime);

  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function getDayOfWeek(date: string) {
  return dayjs(date).format("dddd");
}

export function getMonth(date: string) {
  return dayjs(date).format("MMMM");
}

export function getYear(date: string) {
  return dayjs(date).format("YYYY");
}

export function getDay(date: string) {
  return dayjs(date).format("DD");
}

export function firstDayOfWeek(date: string) {
  return dayjs(date).startOf("month").day();
}

export function lastDayOfMonth(date: string) {
  return dayjs(date).endOf("month").date();
}

export function getDayInMonth(month: string, dayInMonth: number) {
  return dayjs(month).startOf("month").add(dayInMonth, "day");
}

export function today() {
  return dayjs();
}

export function day(date: string | Dayjs | undefined | Date) {
  return dayjs(date);
}

export function duration(
  endtime: string | Dayjs,
  startTime: string | Dayjs,
  by: "millisecond" | "second" | "minute" | "hour" | "day" | "month" | "year"
) {
  return day(endtime).diff(day(startTime), by);
}

export function setTime(
  day: string | Dayjs | undefined,
  config: Partial<Record<UnitType, number>>
) {
  let _day = dayjs(day);
  for (const key in config) {
    const value = config[key as UnitType];
    if (typeof value !== "number") continue;
    _day = _day.set(key as UnitType, value);
  }

  return _day;
}

export function isSameOrAfter(
  date: string | Dayjs,
  dateToCompare: string | Dayjs
) {
  const _date = dayjs(date);
  const _dateToCompare = dayjs(dateToCompare);
  return (
    _date.isSame(_dateToCompare, "day") || _date.isAfter(_dateToCompare, "day")
  );
}

export function isoWeekday(day: string | Dayjs) {
  const _day = dayjs(day);
  const d = _day.day();
  return d === 0 ? 7 : d;
}
