import { useTranslation } from "react-i18next";

import { ConfirmationModal } from "../common/ConfirmationModal";

type RemoveFavoriteProfessionalModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  professionalId: number;
  unfavoriteProfessional: (professionalId: number) => void;
};

export default function RemoveFavoriteProfessionalModal({
  isOpen,
  handleClose,
  professionalId,
  unfavoriteProfessional,
}: RemoveFavoriteProfessionalModalProps) {
  const { t } = useTranslation("professionals/favorite");
  return (
    <ConfirmationModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={t("remove_favorite_professional_title")}
      dismissTitle={t("remove_favorite_professional_dismiss_title")}
      buttonTitle={t("remove_favorite_professional_button_title")}
      onDismiss={handleClose}
      onPress={() => unfavoriteProfessional(professionalId)}
      buttonColor="#FF5A59"
    >
      <p className="mb-4 text-base">
        {t("remove_favorite_professional_description_1")}
      </p>

      <p className="text-base">
        {t("remove_favorite_professional_description_2")}
      </p>
    </ConfirmationModal>
  );
}
