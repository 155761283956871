import { PublishShiftListAction, PublishShiftListState } from "@/store/types";

const initialState: PublishShiftListState = {
  appliedFilter: "all",
  selectedShiftId: null,
};

const publishShiftListReducer = (
  state = initialState,
  action: PublishShiftListAction
) => {
  switch (action.type) {
    case "SET_FILTER":
      return {
        ...state,
        appliedFilter: action.payload,
      };
    case "SET_PUBLISH_SHIFT_LIST_SELECTED_SHIFT_ID":
      return {
        ...state,
        selectedShiftId: action.payload,
      };
    default:
      return state;
  }
};

export default publishShiftListReducer;
