export const setDateSelected = (date: string) => {
  return {
    type: "SET_SELECTED_DATE",
    payload: date,
  };
};

export const setSelectedShiftId = (shiftId: number | null) => {
  return {
    type: "SET_SELECTED_SHIFT_ID",
    payload: shiftId,
  };
};
