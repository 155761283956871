import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

interface ShiftDetailsHeaderProps {
  title: string;
  onClose: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
  editable?: boolean;
  recurrent?: boolean;
}

export const ShiftDetailsHeader: React.FC<ShiftDetailsHeaderProps> = ({
  title,
  onClose,
  onEdit,
  onDelete,
  editable,
  onCopy,
  recurrent,
}) => {
  const { t } = useTranslation(["shift-claim-details", "publish-shift"]);
  const [editDeleteModalOpen, setEditDeleteModalOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setEditDeleteModalOpen(false);
      }
    };

    if (editDeleteModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editDeleteModalOpen]);

  return (
    <div className="flex w-full items-center justify-between px-large">
      <button onClick={onClose}>
        <LivoIcon name="close" size={24} color="#707A91" />
      </button>
      {recurrent ? (
        <div className="flex items-center space-x-small">
          <LivoIcon name="repeat" size={16} color="#ACBBCB" />
          <p className="body-sm text-Text-Subtle">
            {t("publish-shift:recurrent_label")}
          </p>
        </div>
      ) : null}
      <div className="relative">
        <button
          ref={buttonRef}
          onClick={(e) => {
            e.preventDefault();
            setEditDeleteModalOpen(!editDeleteModalOpen);
          }}
        >
          <LivoIcon name="dots" size={24} color="#707A91" />
        </button>
        {editDeleteModalOpen && (
          <div
            ref={modalRef}
            className="absolute right-full top-0 z-10 mb-[14px] mr-tiny w-[255px] space-y-[14px] rounded-[8px] bg-white p-medium shadow-custom"
          >
            <button
              className="pv-tiny flex w-full items-center justify-between px-small"
              onClick={onCopy}
            >
              <p className="body-regular mr-small text-Text-Subtle">
                {t("duplicate_shift_label")}
              </p>
              <LivoIcon name="copy" size={24} color="#707A91" />
            </button>
            {editable ? (
              <>
                <button
                  className="pv-tiny flex w-full items-center justify-between px-small"
                  onClick={onEdit}
                >
                  <p className="body-regular mr-small text-Text-Subtle">
                    {t("edit_shift_label")}
                  </p>
                  <LivoIcon name="pencil" size={24} color="#707A91" />
                </button>
                <button
                  className="pv-tiny flex w-full items-center justify-between px-small"
                  onClick={(e) => {
                    e.preventDefault();
                    onDelete();
                  }}
                >
                  <p className="body-regular mr-small text-Negative-500">
                    {t("delete_shift_label")}
                  </p>
                  <LivoIcon name="trash" size={24} color="#FA3D3B" />
                </button>
              </>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
