import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionButton } from "@/components/common/ActionButton";
import LivoIcon from "@/components/common/LivoLogo";
import { CapacitySelector } from "@/components/publishShift/CapacitySelector";
import { DateTimeSelector } from "@/components/publishShift/DateTimeSelector";
import { DropDownWithInput } from "@/components/publishShift/DropDownWithInput";
import { PriceSelector } from "@/components/publishShift/PriceSelector";
import { PublishInputRow } from "@/components/publishShift/PublishInputRow";
import { PublishShiftMultipleLineInput } from "@/components/publishShift/PublishShiftMultipleLineInput";
import { VisibilitySelector } from "@/components/publishShift/VisibilitySelector";

import { ApiApplicationError } from "@/services/api";
import { ShiftPublicationRequest } from "@/services/publish-shift";
import { ShiftModalityEnum } from "@/types";
import { PublishShiftConfigurationDTO } from "@/types/publish-shift";
import {
  Category,
  ShiftTimeInDayEnum,
  SpecializationDTO,
} from "@/types/shifts";
import { day, duration, today } from "@/utils/datetime";

import { CompensationOptionSelector } from "./CompensationOptionSelector";
import { HeaderComponent } from "./HeaderComponent";
import { UnitInputComponent } from "./UnitInputComponent";

export type ShiftConfiguration = {
  startTime: string;
  finishTime: string;
  shiftTimeInDay: ShiftTimeInDayEnum;
  totalPay: string;
  capacity: number;
  specialization: SpecializationDTO | null;
  unit: string;
  details: string;
  externalVisible: boolean;
  internalVisible: boolean;
  recurrentDates: string[];
  category?: Category | null;
  unitVisible: boolean;
  compensationOptions: string[];
};

interface BaseShiftFormProps {
  publishShiftConfiguration: PublishShiftConfigurationDTO;
  onSubmit: (shiftPublishRequest: ShiftPublicationRequest) => Promise<void>;
  initialConfig: ShiftConfiguration;
  onClose: () => void;
  isEditing?: boolean;
  title: string;
  goBack?: () => void;
}

export const BaseShiftForm = ({
  publishShiftConfiguration: {
    specializations: specializationOptions,
    shiftTimeConfig: shiftTimeInDayConfig,
    livoPoolOnboarded,
    livoInternalOnboarded,
    units,
    calendarSummary,
    unitVisibleConfigurable,
    compensationOptions,
    unitConfigurable,
  },
  onSubmit,
  initialConfig,
  onClose,
  isEditing,
  title,
  goBack,
}: BaseShiftFormProps) => {
  const { t } = useTranslation(["publish-shift", "edit-shift"]);
  const initialStartTime = initialConfig.startTime || today().toISOString();
  const [selectedStartTime, setSelectedStartTime] =
    useState<string>(initialStartTime);
  const [selectedEndTime, setSelectedEndTime] = useState<string>(
    initialConfig.finishTime || today().toISOString()
  );
  const [selectedSpecialization, setSelectedSpecialization] = useState<string>(
    initialConfig.specialization?.name || ""
  );
  const [unitVisible, setUnitVisible] = useState<boolean>(
    initialConfig.unitVisible
  );
  const [priceModality, setPriceModality] = useState<string>("total");
  const [price, setPrice] = useState<string>(initialConfig.totalPay);
  const [capacity, setCapacity] = useState<number>(initialConfig.capacity);
  const [visibility, setVisibility] = useState<ShiftModalityEnum[]>(() => {
    const initialVisibility: ShiftModalityEnum[] = [];
    if (initialConfig.externalVisible) {
      initialVisibility.push(ShiftModalityEnum.EXTERNAL);
    }
    if (initialConfig.internalVisible) {
      initialVisibility.push(ShiftModalityEnum.INTERNAL);
    }
    return initialVisibility;
  });
  const [details, setDetails] = useState<string>(initialConfig.details || "");
  const [unit, setUnit] = useState<string>(initialConfig.unit || "");
  const [selectedCompensationOptions, setSelectedCompensationOptions] =
    useState<string[]>(initialConfig.compensationOptions || []);

  const [invalidPrice, setInvalidPrice] = useState("");
  const [invalidSpecialization, setInvalidSpecialization] = useState("");
  const [invalidUnit, setInvalidUnit] = useState("");
  const [invalidVisibility, setInvalidVisibility] = useState("");
  const [loading, setLoading] = useState(false);
  const [recurrentDates, setRecurrentDates] = useState<string[]>(
    initialConfig?.recurrentDates?.filter((date) =>
      day(date).isAfter(today())
    ) || [day(initialStartTime).format("YYYY-MM-DD")]
  );

  const [publishErrorMessage, setPublishErrorMessage] = useState<string>("");

  const sortedRecurrentDates = recurrentDates.sort((a, b) =>
    day(a).diff(day(b))
  );

  const summaryHeader =
    sortedRecurrentDates.length === 1
      ? t("publish_single_shift_summary_header", {
          date: day(selectedStartTime).format("DD/MM/YYYY"),
        })
      : t("publish_multiple_shifts_summary_header", {
          numberOfShifts: sortedRecurrentDates.length,
          startDate: day(sortedRecurrentDates[0]).format("DD/MM/YYYY"),
          endDate: day(recurrentDates[sortedRecurrentDates.length - 1]).format(
            "DD/MM/YYYY"
          ),
        });

  const summaryHeaderEdit =
    sortedRecurrentDates.length === 1
      ? t("edit-shift:edit_single_shift_summary_header", {
          date: day(selectedStartTime),
        })
      : t("edit-shift:edit_multiple_shifts_summary_header", {
          numberOfShifts: sortedRecurrentDates.length,
          startDate: day(sortedRecurrentDates[0]).format("DD/MM/YYYY"),
          endDate: day(recurrentDates[sortedRecurrentDates.length - 1]).format(
            "DD/MM/YYYY"
          ),
        });

  const buttonTitlePublish =
    sortedRecurrentDates.length === 1
      ? t("publish_shift_button")
      : t("publish_multiple_shifts_button", {
          numberOfShifts: sortedRecurrentDates.length,
        });
  const buttonTitleEdit =
    sortedRecurrentDates.length === 1
      ? t("edit-shift:edit_shift_button")
      : t("edit-shift:edit_multiple_shifts_button", {
          numberOfShifts: sortedRecurrentDates.length,
        });

  const poolAndInternalOnboarded = livoPoolOnboarded && livoInternalOnboarded;

  const validateFields = () => {
    let isValid = true;
    if (specializationOptions.length > 0 && !selectedSpecialization) {
      setInvalidSpecialization("Selecciona una especialización");
      isValid = false;
    } else {
      setInvalidSpecialization("");
    }
    if (!price && visibility.includes(ShiftModalityEnum.EXTERNAL)) {
      setInvalidPrice("Ingresa un precio válido");
      isValid = false;
    } else {
      setInvalidPrice("");
    }
    if (
      !unit &&
      visibility.includes(ShiftModalityEnum.INTERNAL) &&
      unitConfigurable
    ) {
      setInvalidUnit("Ingresa una unidad válida");
      isValid = false;
    } else {
      setInvalidUnit("");
    }
    if (visibility.length === 0) {
      setInvalidVisibility("Selecciona al menos una audiencia");
      isValid = false;
    } else {
      setInvalidVisibility("");
    }
    return isValid;
  };

  useEffect(() => {
    setSelectedStartTime(initialConfig.startTime || today().toISOString());
    setSelectedEndTime(initialConfig.finishTime || today().toISOString());
    setSelectedSpecialization(initialConfig.specialization?.name || "");
    setPrice(initialConfig.totalPay);
    setCapacity(initialConfig.capacity);
    setVisibility(() => {
      const initialVisibility: ShiftModalityEnum[] = [];
      if (isEditing) {
        if (initialConfig.externalVisible) {
          initialVisibility.push(ShiftModalityEnum.EXTERNAL);
        }
        if (initialConfig.internalVisible) {
          initialVisibility.push(ShiftModalityEnum.INTERNAL);
        }
      } else if (initialConfig.category != null) {
        if (initialConfig.category.visibleForLivoInternal) {
          initialVisibility.push(ShiftModalityEnum.INTERNAL);
        }
        if (initialConfig.category.visibleForLivoPool) {
          initialVisibility.push(ShiftModalityEnum.EXTERNAL);
        }
      } else {
        initialVisibility.push(ShiftModalityEnum.EXTERNAL);
      }

      return initialVisibility;
    });
    setDetails(initialConfig.details || "");
    setUnit(initialConfig.unit || "");
    setRecurrentDates(
      initialConfig?.recurrentDates?.filter(
        (date) => !day(date).isBefore(today(), "day")
      ) || [today().format("YYYY-MM-DD")]
    );
    setPublishErrorMessage("");

    return () => {
      // Reset state on unmount
      setSelectedStartTime(today().toISOString());
      setSelectedEndTime(today().toISOString());
      setSelectedSpecialization(specializationOptions[0]?.name || "");
      setPrice("");
      setCapacity(1);
      setVisibility([ShiftModalityEnum.EXTERNAL]);
      setDetails("");
      setUnit("");
      setInvalidPrice("");
      setInvalidSpecialization("");
      setInvalidUnit("");
      setInvalidVisibility("");
      setDetails("");
      setRecurrentDates([today().format("YYYY-MM-DD")]);
      setPublishErrorMessage("");
    };
  }, [initialConfig]);

  return (
    <div className="flex size-full flex-col">
      <HeaderComponent
        title={
          title +
          (initialConfig.category
            ? ` - ${initialConfig.category.displayText}`
            : "")
        }
        onClose={onClose}
        goBack={goBack}
      />
      <div
        className="no-scrollbar flex flex-row space-x-large overflow-y-auto p-medium"
        style={{ flexGrow: 1, maxHeight: "calc(80vh - 150px)" }}
      >
        <DateTimeSelector
          calendarSummary={calendarSummary || []}
          selectedStartTime={selectedStartTime}
          selectedEndTime={selectedEndTime}
          setStartTime={setSelectedStartTime}
          setEndTime={setSelectedEndTime}
          shiftTimeInDayConfig={shiftTimeInDayConfig}
          recurrentDates={recurrentDates}
          setRecurrentDates={setRecurrentDates}
          isDisabled={isEditing && initialConfig.recurrentDates?.length > 1}
          subtitle={
            !isEditing
              ? t("publish_shift_date_selector_subtitle")
              : initialConfig.recurrentDates?.length > 1
                ? t("edit-shift:edit_shift_date_selector_subtitle")
                : undefined
          }
          bulkSelectionEnabled={
            !isEditing || initialConfig.recurrentDates?.length > 1
              ? true
              : false // when editing a single shift user cannot select multiple dates
          }
        />
        <div className="flex w-full flex-col">
          {poolAndInternalOnboarded && (
            <PublishInputRow iconName="eye">
              <VisibilitySelector
                selectedVisibility={visibility}
                setVisibility={setVisibility}
                errorMessage={invalidVisibility}
              />
            </PublishInputRow>
          )}
          {livoInternalOnboarded && unitConfigurable && (
            <UnitInputComponent
              units={units}
              unit={unit}
              setUnit={(newUnit: string) => {
                setUnit(newUnit);
                setInvalidUnit("");
              }}
              invalidUnit={invalidUnit}
              setInvalidUnit={setInvalidUnit}
              unitVisible={unitVisible}
              setUnitVisible={setUnitVisible}
              unitVisibleConfigurable={unitVisibleConfigurable}
            />
          )}
          {specializationOptions.length > 0 ? (
            <PublishInputRow iconName="stethoscope">
              <DropDownWithInput
                label={t("dropdown_specialization")}
                selectedOptionId={selectedSpecialization}
                setOptionId={(optionId) => {
                  setSelectedSpecialization(optionId);
                  setInvalidSpecialization("");
                }}
                placeHolder={t("dropdown_specialization")}
                options={specializationOptions.map((specialization) => ({
                  id: specialization.name,
                  name: specialization.displayText,
                }))}
                errorMessage={invalidSpecialization}
                disabled={isEditing}
              />
            </PublishInputRow>
          ) : null}

          {visibility.includes(ShiftModalityEnum.EXTERNAL) ? (
            <PublishInputRow iconName="cash-banknote">
              <PriceSelector
                setPrice={(newPrice) => {
                  setPrice(newPrice);
                  setInvalidPrice("");
                }}
                selectedPrice={price}
                selectedModality={priceModality}
                setSelectedModality={setPriceModality}
                errorMessage={invalidPrice}
                isDisabled={!visibility.includes(ShiftModalityEnum.EXTERNAL)}
              />
            </PublishInputRow>
          ) : null}

          {compensationOptions?.configurable &&
          visibility.includes(ShiftModalityEnum.INTERNAL) ? (
            <PublishInputRow>
              <CompensationOptionSelector
                compensationOptions={compensationOptions.options}
                selectedCompensationOptions={selectedCompensationOptions}
                setCompensationOptions={setSelectedCompensationOptions}
              />
            </PublishInputRow>
          ) : null}

          <PublishInputRow iconName="user">
            <CapacitySelector
              selectedCapacity={capacity}
              maxCapacity={5}
              setCapacity={setCapacity}
            />
          </PublishInputRow>
          <div className="flex items-start justify-center space-x-medium py-medium">
            <div className="flex items-center justify-center pt-small">
              <LivoIcon name={"blockquote"} size={24} color="#ACBBCB" />
            </div>
            <PublishShiftMultipleLineInput // Unidad
              selectedValue={details}
              setValue={setDetails}
              placeHolder={t("detail_input_placeholder")}
              label={t("detail_input_label")}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between border-t border-Divider-Default p-large align-top">
        {publishErrorMessage ? (
          <div>
            <p className="body-regular text-Negative-500">
              {isEditing
                ? t("edit-shift:error_editing_header")
                : t("publish_error_header")}
            </p>
            <p className="body-regular text-Negative-500">
              {publishErrorMessage}
            </p>
          </div>
        ) : (
          <div>
            <p className="body-regular text-Text-Subtle">
              {isEditing ? summaryHeaderEdit : summaryHeader}
            </p>
          </div>
        )}
        <div className="bg-Primary-Default flex min-w-[180px]  self-start text-white">
          <ActionButton
            isDisabled={false}
            isLoading={loading}
            style={{}}
            onClick={() => {
              const startTime = day(selectedStartTime);
              let endTime = day(selectedEndTime);

              if (endTime.isBefore(startTime)) {
                endTime = endTime.add(1, "day");
              }
              let totalPay = +price;
              if (priceModality === "hourly") {
                const hours = duration(endTime, startTime, "hour");
                totalPay = +price * hours;
              }

              const shiftConfiguration: ShiftPublicationRequest = {
                startTime: startTime.toDate(),
                endTime: endTime.toDate(),
                specialization: selectedSpecialization,
                totalPay: totalPay,
                capacity: capacity,
                internalVisible: visibility.includes(
                  ShiftModalityEnum.INTERNAL
                ),
                externalVisible: visibility.includes(
                  ShiftModalityEnum.EXTERNAL
                ),
                details: details,
                unit: unit,
                recurrentDates: recurrentDates,
                category: initialConfig.category?.code,
                unitVisible: unitVisible,
                compensationOptions: selectedCompensationOptions,
              };

              if (!validateFields()) return;
              setLoading(true);
              onSubmit(shiftConfiguration)
                .catch((error) => {
                  if (error instanceof ApiApplicationError) {
                    setPublishErrorMessage(error.message);
                  } else {
                    setPublishErrorMessage("Ha ocurrido un error inesperado");
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            <p className="action-regular py-small text-center">
              {isEditing ? buttonTitleEdit : buttonTitlePublish}
            </p>
          </ActionButton>
        </div>
      </div>
    </div>
  );
};
