interface CapacityItemProps {
  isSelected: boolean;
  capacity: string;
  onPress: () => void;
}

export const CapacityItem: React.FC<CapacityItemProps> = ({
  isSelected,
  capacity,
  onPress,
}) => {
  return (
    <div
      onClick={onPress}
      className={`
                flex size-full items-center rounded-[8px] px-medium py-small  ${isSelected ? "bg-Action-Secondary" : "ring-solid bg-white ring-1 ring-Divider-Subtle hover:bg-Background-Secondary"}  cursor-pointer`}
    >
      <p
        className="action-sm w-full text-center"
        style={{
          color: isSelected ? "#FFFFFF" : "#055186",
        }}
      >
        {capacity}
      </p>
    </div>
  );
};
