import { useTranslation } from "react-i18next";

import LivoIcon from "../../common/LivoLogo";

interface InternalProfessionalAttributesProps {
  attributes: {
    label: string;
    value: string;
  }[];
}
export const InternalProfessionalAttributes: React.FC<
  InternalProfessionalAttributesProps
> = ({ attributes }) => {
  const { t } = useTranslation("professional-claim");
  return (
    <div className="flex w-full flex-col p-medium">
      <div className="mb-large flex flex-row space-x-small">
        <LivoIcon name="id-badge-2" size={24} color="#ACBBCB" />
        <p className="body-regular">{t("professional_data_title")}</p>
      </div>

      <div className="flex flex-col space-y-small">
        {attributes.map((attribute, index) => (
          <div
            key={index}
            className="flex flex-row justify-between space-x-small"
          >
            <p className="body-regular text-Text-Subtle">{attribute.label}:</p>
            <p className="subtitle-regular text-Text-Default">
              {attribute.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
