import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import LivoIcon from "@/components/common/LivoLogo";
import { LivoCVModal } from "@/components/curriculum/LivoCVModal";
import { useLivoCVModal } from "@/components/curriculum/useLivoCVModal";
import { ProfessionalLivoReviews } from "@/components/shiftDetails/professionalClaim/ProfessionalLivoReviews";
import { ProfilePicture } from "@/components/shiftDetails/ProfilePicture";

import { RootState } from "@/store/types";
import { ShiftModalityEnum } from "@/types";
import { CVType } from "@/types/claims";
import { OfferClaimStatus, OfferProfessionalProfile } from "@/types/offers";

import { OfferClaimStatusTag } from "./OfferStatusTag";

interface OfferProfessionalProfileProps {
  profile: OfferProfessionalProfile;
  detailsDisclosed: boolean;
  callToActionText: string | null;
  status: OfferClaimStatus;
}

export const OfferProfessionalProfileSection: React.FC<
  OfferProfessionalProfileProps
> = ({ profile, detailsDisclosed, callToActionText, status }) => {
  const { t } = useTranslation([
    "professional-claim",
    "professionals/profile",
    "offers",
  ]);
  const account = useSelector((state: RootState) => state.account);
  const livoCVModal = useLivoCVModal(profile.id);

  const hasLivoCV = profile.availableCVTypes?.includes(CVType.LIVO_CV);
  const hasPdfUpload = profile.availableCVTypes?.includes(CVType.PDF_UPLOAD);

  return (
    <div className="flex w-full flex-col p-4">
      {/* Profile Header */}
      <div className="flex flex-col items-center space-y-2">
        <ProfilePicture
          profilePictureUrl={profile?.profilePictureUrl}
          modality={ShiftModalityEnum.EXTERNAL}
          size={64}
        />
        <p className="heading-md text-Text-Default">{profile.fullName}</p>
      </div>

      <div className="my-1 flex flex-row items-center space-x-2">
        <OfferClaimStatusTag status={status} />
      </div>

      {/* Main Content */}
      <div className="flex flex-col divide-y divide-Divider-Default">
        {/* Professional Info Section */}
        <div className="mb-6 flex flex-col">
          <div className="mb-5 flex items-center space-x-2 pt-3">
            <LivoIcon name="id-badge-2" size={24} color="#ACBBCB" />
            <p className="text-base text-Text-Default">
              {t("professional_data_title")}
            </p>
          </div>

          <div
            className={`flex flex-col ${
              detailsDisclosed ? "rounded-lg bg-Primary-100 p-4" : ""
            }`}
          >
            {detailsDisclosed && (
              <>
                <div className="mb-2 flex space-x-2">
                  <p className="body-regular text-Text-Subtle">
                    {t("telephone_label")}:
                  </p>
                  <p className="subtitle-regular text-Text-Default">
                    {profile.phoneNumber}
                  </p>
                </div>
                <div className="mb-2 flex space-x-2">
                  <p className="body-regular text-Text-Subtle">
                    {t("email_label")}:
                  </p>
                  <p className="subtitle-regular text-Text-Default">
                    {profile.email}
                  </p>
                </div>
              </>
            )}

            <div className="mb-2 flex space-x-2">
              <p className="body-regular text-Text-Subtle">
                {t("license_number_label")}:
              </p>
              <p className="subtitle-regular text-Text-Default">
                {profile.licenseNumber}
              </p>
            </div>

            <div className="space-x-2">
              <span className="subtitle-regular text-Text-Subtle">
                {t("experience_label")}:
              </span>
              {hasLivoCV && (
                <button
                  className="subtitle-regular body-regular cursor-pointer text-[#149EF2] hover:underline"
                  onClick={() => livoCVModal.showModal()}
                >
                  {t("view_livo_cv_label")}
                </button>
              )}
              {hasLivoCV && hasPdfUpload && (
                <span className="mx-8 text-Text-Subtle">|</span>
              )}
              <a
                className="subtitle-regular text-[#149EF2] hover:underline"
                href={profile.professionalCV}
                target="_blank"
                rel="noreferrer"
              >
                {t("download_cv_label")}
              </a>
            </div>

            {callToActionText && (
              <p className="mt-4 text-xs text-Grey-700">
                {t("offers:call_to_disclose_details")}
              </p>
            )}
          </div>

          {/* AI Summary */}
          {profile.cvSummary && (
            <div className="mt-6 space-y-2">
              <div className="flex items-center space-x-2">
                <LivoIcon name="sparkles" size={24} color="#ACBBCB" />
                <p className="text-base text-Text-Default">
                  {t("professionals/profile:professional_ai_cv_title")}
                </p>
              </div>
              <p className="text-base text-Grey-700">{profile.cvSummary}</p>
            </div>
          )}
        </div>

        {/* Facility Experience */}
        <div className="mb-6 flex flex-col pt-5">
          <div className="mb-5 flex items-center space-x-2">
            <LivoIcon name="report-medical" size={24} color="#ACBBCB" />
            <p className="body-regular">{t("facility_experience")}</p>
          </div>
          <div className="flex items-center rounded-lg bg-white p-3 ring-1 ring-Divider-Subtle">
            <div className="flex-1">
              <p className="subtitle-regular">{t("completed_shifts")}</p>
              {account.accountInfo && (
                <p className="body-regular text-Text-Subtle">
                  {t("in")} {account.accountInfo.facility.name}
                </p>
              )}
            </div>
            <p className="heading-sm mr-3">{profile.totalShiftsInFacility}</p>
          </div>
        </div>

        {/* Reviews */}
        {profile.professionalReview?.averageRating && (
          <div className="mb-6 pt-5">
            <ProfessionalLivoReviews
              review={profile.professionalReview}
              noPadding={true}
            />
          </div>
        )}
      </div>

      {livoCVModal.livoCVDetails && (
        <LivoCVModal
          fullScreen
          style={{ width: "100%", maxWidth: "600px" }}
          title={t("professional_livo_cv_title", {
            fullName: profile.fullName,
          })}
          isOpen={livoCVModal.isOpen}
          onClose={livoCVModal.hideModal}
          livoCVDetails={livoCVModal.livoCVDetails}
        />
      )}
    </div>
  );
};
