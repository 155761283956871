import LivoIcon from "@/components/common/LivoLogo";
import { TagLabelRow } from "@/components/common/TagLabelRow";

import { FacilityStaff } from "../../services/facility-staff";

interface FacilityStaffCardProps {
  faciltiyStaff: FacilityStaff;
  onClick: () => void;
  facilityHasUnitsConfigured?: boolean;
}

export const FacilityStaffCard: React.FC<FacilityStaffCardProps> = ({
  faciltiyStaff,
  onClick,
  facilityHasUnitsConfigured,
}) => {
  const gridColumnsStyle = facilityHasUnitsConfigured
    ? "grid-cols-4"
    : "grid-cols-3";
  return (
    <div
      className={`flex flex-row  items-center rounded-[16px] bg-white  p-small`}
      style={{
        minWidth: "200px",
      }}
    >
      <div
        className={`grid flex-1 ${gridColumnsStyle} w-full items-center justify-between space-x-small`}
      >
        <div className="col-span-1 flex text-left">
          <p className="subtitle-regular truncate">
            {faciltiyStaff.firstName} {faciltiyStaff.lastName}
          </p>
        </div>
        <div className="col-span-1 flex text-nowrap text-left">
          <p className="body-regular truncate">{faciltiyStaff.email}</p>
        </div>
        {facilityHasUnitsConfigured ? (
          <div className="col-span-1 flex justify-start">
            <TagLabelRow
              tags={faciltiyStaff.units.map((unit) => unit.displayName)}
            />
          </div>
        ) : null}

        <div className="col-span-1 flex">
          <TagLabelRow
            tags={faciltiyStaff.permissions.map((perm) => perm.tabText)}
          />
        </div>
      </div>
      <div
        onClick={onClick}
        className="ml-small cursor-pointer rounded-full bg-Primary-100 p-tiny"
      >
        <LivoIcon name="pencil" size={16} color={"#149EF2"} />
      </div>
    </div>
  );
};
