import LivoIcon from "@/components/common/LivoLogo";

import { modalityTags } from "@/utils/constants";

import { ShiftModalityEnum } from "../../types";

interface ModalityTagProps {
  modality: ShiftModalityEnum;
  style?: any;
  shortTag?: boolean;
}

export const ModalityTag: React.FC<ModalityTagProps> = ({
  modality,
  shortTag,
  style,
}) => {
  const modalityProps = modalityTags[modality];
  return shortTag ? (
    <div
      className={`flex bg-[${modalityProps.backgroundColor}] items-center rounded-[100px] p-tiny size-xLarge justify-center ${style}`}
    >
      <LivoIcon
        name={modalityProps.icon}
        size={16}
        color={modalityProps.color}
      />
    </div>
  ) : (
    <div
      className={`bg-[${modalityProps.backgroundColor}] flex flex-row items-center justify-center rounded-[4px] border-solid  p-tiny ${style}`}
    >
      <LivoIcon
        name={modalityProps.icon}
        size={16}
        color={modalityProps.color}
      />
      <p className="subtitle-sm ml-tiny text-Text-Default">
        {modalityProps.displayText}
      </p>
    </div>
  );
};
