import { Typography } from "@mui/material";

import { IconChevronRight } from "@tabler/icons-react";

import clsx from "clsx";

interface SectionRowProps {
  icon: React.ReactNode;
  text: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const SectionRow = ({ icon, text, onClick, children }: SectionRowProps) => {
  return (
    <div
      className={clsx(
        "flex items-end gap-2 text-gray-800",
        onClick && "cursor-pointer"
      )}
      onClick={onClick}
    >
      <div className="flex flex-1 shrink-0 items-center gap-2">
        {icon}
        <Typography variant="body1">{text}</Typography>
        {children}
      </div>
      {onClick && <IconChevronRight size={20} color="#BDBDBD" />}
    </div>
  );
};

SectionRow.propTypes = {};

export default SectionRow;
