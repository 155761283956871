import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { ClaimRequest, CVType } from "@/types/claims";

interface ProfessionalDataBodyProps {
  claim: ClaimRequest;
  onViewLivoCV: () => void;
}
export const ProfessionalDataBody: React.FC<ProfessionalDataBodyProps> = ({
  claim,
  onViewLivoCV,
}) => {
  const { t } = useTranslation("professional-claim");
  const hasLivoCV = claim.professionalProfile.availableCVTypes?.includes(
    CVType.LIVO_CV
  );
  const hasPdfUpload = claim.professionalProfile.availableCVTypes?.includes(
    CVType.PDF_UPLOAD
  );

  return (
    <div className="flex w-full flex-col p-medium">
      <div className="mb-large flex flex-row space-x-small">
        <LivoIcon name="id-badge-2" size={24} color="#ACBBCB" />
        <p className="body-regular">{t("professional_data_title")}</p>
      </div>
      <div className="mb-small flex flex-row flex-wrap space-x-small">
        <p className="body-regular text-Text-Subtle">
          {t("license_number_label")}:
        </p>
        <p className="subtitle-regular mr-8 text-Text-Default">
          {claim.professionalProfile.licenseNumber}
        </p>
      </div>
      <div className="space-x-small">
        <span className="subtitle-regular text-Text-Subtle">
          {t("experience_label")}:
        </span>
        {hasLivoCV && (
          <button
            className="subtitle-regular body-regular cursor-pointer text-[#149EF2] hover:underline"
            onClick={() => onViewLivoCV()}
          >
            {t("view_livo_cv_label")}
          </button>
        )}
        {hasLivoCV && hasPdfUpload && (
          <span className="mx-8 text-Text-Subtle">|</span>
        )}
        <a
          className="subtitle-regular text-[#149EF2] hover:underline"
          href={claim.professionalProfile.professionalCV}
          target="_blank"
          rel="noreferrer"
        >
          {t("download_cv_label")}
        </a>
      </div>
    </div>
  );
};
