import { useSelector } from "react-redux";

import LivoIcon from "@/components/common/LivoLogo";
import { Avatar } from "@/components/shiftDetails/Avatar";

import { RootState } from "@/store/types";
import { ShiftModalityEnum } from "@/types";
import { modalityTags } from "@/utils/constants";

interface ProfilePictureProps {
  profilePictureUrl?: string;
  modality: ShiftModalityEnum | null;
  style?: any;
  size: number;
}

export const ProfilePicture: React.FC<ProfilePictureProps> = ({
  profilePictureUrl,
  modality,
  size,
  style,
}) => {
  const { accountInfo } = useSelector((state: RootState) => state.account);
  const poolAndInternalOnboarded =
    accountInfo?.livoPoolOnboarded && accountInfo?.livoInternalOnboarded;
  return (
    <div style={{ width: size, height: size }} className="relative">
      {profilePictureUrl ? (
        <img
          src={profilePictureUrl}
          style={{
            width: size,
            height: size,
            objectFit: "cover",
            objectPosition: "center",
          }}
          className="rounded-[8px]"
        />
      ) : (
        <Avatar size={size} />
      )}

      {modality && poolAndInternalOnboarded && (
        <div
          className={`absolute -bottom-tiny -right-tiny flex items-center justify-center rounded-[100px] border-2 border-solid border-white  p-[2px] ${style} `}
          style={{ backgroundColor: modalityTags[modality].backgroundColor }}
        >
          <LivoIcon
            name={modalityTags[modality].icon}
            size={size / 3}
            color={modalityTags[modality].color}
          />
        </div>
      )}
    </div>
  );
};
