import React from "react";

import LivoIcon from "../common/LivoLogo";

interface CapacityComponentProps {
  acceptedClaims: number;
  totalAcceptedClaimsWithoutHRIntegration?: number;
  emptyClaims: number;
}

export const CapacityComponent: React.FC<CapacityComponentProps> = ({
  acceptedClaims,
  totalAcceptedClaimsWithoutHRIntegration,
  emptyClaims,
}) => {
  return (
    <div className="flex">
      {Array.from(
        {
          length:
            acceptedClaims - (totalAcceptedClaimsWithoutHRIntegration || 0),
        },
        (_, i) => i + 1
      ).map((acceptedClaim, index) => (
        <LivoIcon key={index} name="user-check" color="#33B240" size={20} />
      ))}
      {Array.from(
        { length: totalAcceptedClaimsWithoutHRIntegration || 0 },
        (_, i) => i + 1
      ).map((acceptedClaimWithoutHRIntegration, index) => (
        <LivoIcon key={index} name="user-check" color="#FF911C" size={20} />
      ))}
      {Array.from({ length: emptyClaims }, (_, i) => i + 1).map(
        (emptyClaim, index) => (
          <LivoIcon key={index} name="user" color="#ACBBCB" size={20} />
        )
      )}
    </div>
  );
};
