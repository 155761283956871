import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

export default function FavoriteTag() {
  const { t } = useTranslation("professionals/favorite");
  return (
    <div className="flex flex-row items-center">
      <LivoIcon name="heart-filled" size={12} color="#FF5A59" />
      <span className="subtitle-sm ml-tiny">{t("favorite_label")}</span>
    </div>
  );
}
