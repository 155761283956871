import { ShiftModalityEnum } from "@/types";
import { PublishShiftConfigurationDTO } from "@/types/publish-shift";

import { api, handleApiError } from "./api";

export interface ShiftPublicationRequest {
  startTime: Date;
  endTime: Date;
  specialization: string;
  totalPay?: number;
  capacity: number;
  details: string;
  modality?: ShiftModalityEnum;
  unit?: string;
  externalVisible: boolean;
  internalVisible: boolean;
  recurrentDates: string[];
  category?: string;
  unitVisible: boolean;
  unitVisibleConfigurable?: boolean;
  compensationOptions: string[];
}

export function fetchPublishShiftConfiguration(
  category?: string
): Promise<PublishShiftConfigurationDTO> {
  const uri = "/facility/portal/shifts/publish-shift-config";

  return api
    .get(
      uri,
      category
        ? {
            params: {
              category,
            },
          }
        : undefined
    )
    .then((res) => ({
      ...res.data,
    }))
    .catch(handleApiError);
}

export function publishShift(shiftRequest: ShiftPublicationRequest) {
  const uri = "/facility/portal/shifts/create-shift";

  return api.post(uri, shiftRequest).catch(handleApiError);
}

export function fetchFacilityShiftClaimRequests(shiftId: number) {
  const uri = `/facility/portal/shifts/${shiftId}/claims`;
  return api
    .get(uri)
    .then((res) => {
      return res.data.rows
        .filter(
          (claimRequest: any) =>
            claimRequest.status !== "PROFESSIONAL_SELF_CANCELLED"
        )
        .map((claimRequest: any) => ({
          ...claimRequest,
        }));
    })
    .catch(handleApiError);
}
