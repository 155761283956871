import React, { createContext, useCallback, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useCloseOffer } from "@/pages/OfferDetail/hooks/useMutationOffer";

import { useInvalidateQuery } from "@/hooks/use-invalidate-query";
import { OFFER_DETAIL_QUERY_ID } from "@/queries/offer-detail";
import { translate } from "@/services/i18next/translate";
import { showToastAction } from "@/store/actions/appConfigurationActions";
import { OfferDetail } from "@/types/offers";

interface OfferActionsContextType {
  handleEdit: () => void;
  handleDuplicateOffer: (offer: OfferDetail) => void;
  handleCloseOffer: (
    offerId: number
  ) => Promise<{ ok: boolean; offerId: number } | undefined>;
  handleCancelEdit: () => void;
  handleSuccessEdit: (offerId: number) => void;
  isEditing: boolean;
}

const OfferActionsContext = createContext<OfferActionsContextType | undefined>(
  undefined
);

export const OfferActionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { state } = useLocation();
  const [isEditing, setIsEditing] = useState(state?.action === "edit");
  const invalidateQuery = useInvalidateQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCompletedAction = (params?: { ok: boolean; offerId: number }) => {
    invalidateQuery([OFFER_DETAIL_QUERY_ID, `${params?.offerId}`]);
    dispatch(
      showToastAction({
        message: translate("offers:toast_offer_cancelled", {
          offerId: params?.offerId,
        }),
        severity: "success",
      })
    );
  };

  const { closeOfferAsync: closeOffer } = useCloseOffer({
    onSuccess: onCompletedAction,
  });

  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const handleSuccessEdit = (offerId: number) => {
    invalidateQuery([OFFER_DETAIL_QUERY_ID, `${offerId}`]);
    setIsEditing(false);
  };

  const handleDuplicateOffer = useCallback(
    (offer: OfferDetail) => {
      navigate("/ofertas-publicadas?action=create-offer", {
        state: { initialOffer: offer },
      });
    },
    [navigate]
  );

  const handleCloseOffer = useCallback(
    (offerId: number) => {
      return closeOffer(offerId);
    },
    [closeOffer]
  );

  const value = {
    isEditing,
    handleEdit,
    handleCancelEdit,
    handleSuccessEdit,
    handleDuplicateOffer,
    handleCloseOffer,
  };

  return (
    <OfferActionsContext.Provider value={value}>
      {children}
    </OfferActionsContext.Provider>
  );
};

export const useOfferActions = () => {
  const context = useContext(OfferActionsContext);
  if (context === undefined) {
    throw new Error(
      "useOfferActions must be used within an OfferActionProvider"
    );
  }
  return context;
};
