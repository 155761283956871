import React, { useEffect, useMemo, useRef } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  useFieldArray,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IconButton, Stack } from "@mui/material";

import { IconX } from "@tabler/icons-react";

import FormSelect from "@/components/form/FormSelect";

import colors from "@/config/color-palette";
import { FormConfig } from "@/queries/offer-mutation";

import { throttle } from "lodash-es";

type FormRequirementInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  config?: FormConfig;
};

const FormRequirementInput = <T extends FieldValues>({
  control,
  config,
  name,
}: FormRequirementInputProps<T>) => {
  const { t } = useTranslation("offers");
  const { fields, append, remove } = useFieldArray({
    control,
    name: name as any,
  });

  const mountRef = useRef(false);

  const appendItem = useMemo(() => {
    return throttle(
      () => {
        append({ skill: "", experience: "" } as any, { shouldFocus: false });
      },
      100,
      { leading: true, trailing: false }
    );
  }, [append]);

  const disabledOptions = useMemo(() => {
    return fields.map(
      // @ts-ignore
      (field: { id: string; skill: string; experience?: string }) =>
        field?.skill ?? ""
    );
  }, [fields]);

  const skillOptions = useMemo(() => {
    return config?.skills?.map((skill) => {
      return {
        ...skill,
        disabled: disabledOptions.includes(skill.value),
      };
    });
  }, [config?.skills, disabledOptions]);

  useEffect(() => {
    if (fields.length === 0) {
      if (mountRef.current) return;
      appendItem();
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>
      {fields.map((field, index) => {
        const isLastItem = index === fields?.length - 1;

        return (
          <Stack
            key={field.id}
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            alignItems="center"
          >
            {/* Select Skill */}
            <FormSelect
              name={`${name}.${index}.skill` as Path<T>}
              control={control}
              label={t("skill")}
              options={skillOptions ?? []}
              onChange={(e) => {
                // update(index, { ...field, skill: e.target.value });
                if (isLastItem && e.target.value) {
                  appendItem();
                }
              }}
            />

            {/* Select Experience */}
            <FormSelect
              name={`${name}.${index}.experience` as Path<T>}
              control={control}
              label={t("experience")}
              options={config?.skillExperience ?? []}
            />

            <Controller
              name={`${name}.${index}.skill` as Path<T>}
              control={control}
              render={({ field }) => {
                const isEmptyLast = !field?.value && isLastItem;

                if (isEmptyLast) return <div className="hidden" />;
                return (
                  <>
                    {fields.length > 1 && (
                      <IconButton onClick={() => remove(index)}>
                        <IconX color={colors["Text-Light"]} />
                      </IconButton>
                    )}
                  </>
                );
              }}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default FormRequirementInput;
