import { Control, FieldValues, Path, useWatch } from "react-hook-form";

type OptionalFieldContainerProps<T extends FieldValues, K extends Path<T>> = {
  name: K; // The field name to watch
  control: Control<T>; // React Hook Form control
  condition: (value: T[K]) => boolean; // Condition function to determine visibility
  children: React.ReactNode;
};

const FormOptionalFieldContainer = <T extends FieldValues, K extends Path<T>>({
  control,
  name,
  condition,
  children,
}: OptionalFieldContainerProps<T, K>) => {
  // Use generic type inference to get the correct type of value
  const value = useWatch<T, K>({ control, name });

  return condition(value) ? <>{children}</> : null;
};

export default FormOptionalFieldContainer;
