import LivoIcon from "@/components/common/LivoLogo";

import { ShiftModalityEnum } from "@/types";
import { modalityTags } from "@/utils/constants";

interface VisibilityItemProps {
  isSelected: boolean;
  modality: ShiftModalityEnum;
  onPress: () => void;
}

export const VisibilityItem: React.FC<VisibilityItemProps> = ({
  isSelected,
  modality,
  onPress,
}) => {
  const modalityProps = modalityTags[modality];

  return (
    <button
      onClick={onPress}
      className="flex w-full flex-row items-center justify-between space-x-small rounded-[8px] border border-Divider-Default px-small py-medium"
    >
      <div className="flex w-full flex-row items-center justify-start space-x-tiny">
        <LivoIcon
          name={modalityProps.icon}
          size={16}
          color={modalityProps.color}
        />
        <p className="body-regular">{modalityProps.displayText}</p>
      </div>

      <div className="flex items-center justify-center p-[3px]">
        <LivoIcon
          name={isSelected ? "checkbox-checked" : "checkbox-unchecked"}
          size={24}
          color={isSelected ? modalityProps.color : "#ACBBCB"}
        />
      </div>
    </button>
  );
};
