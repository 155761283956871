import { CategoryAndVisibilityTags } from "@/components/common/CategoryAndVisibilities";
import { ScheduleComponent } from "@/components/shifts/ScheduleComponent";
import { ShiftCardContainer } from "@/components/shifts/ShiftCardContainer";
import { ShiftCardTag } from "@/components/shifts/ShiftCardTag";

import { Shift } from "@/types/shifts";
import { formatDate } from "@/utils/datetime";

interface ActivityShiftCardProps {
  shift: Shift;
  onClick?: () => void;
  isSelected?: boolean;
}

export const ActivityShiftCard: React.FC<ActivityShiftCardProps> = ({
  shift,
  isSelected,
  onClick,
}) => {
  return (
    <ShiftCardContainer shift={shift} isSelected={isSelected} onClick={onClick}>
      <div className=" flex w-full flex-col p-small">
        <CategoryAndVisibilityTags
          category={shift.category}
          recurrenVisible={shift.recurrentDates?.length > 1}
          internalVisible={shift.internalVisible}
          externalVisible={shift.externalVisible}
          noMargin={true}
        />

        <div className="flex w-full flex-row items-center justify-between">
          <h1 className="subtitle-regular">{shift.title}</h1>
          <ShiftCardTag
            totalPendingClaims={
              shift.totalPendingClaims + shift.totalCancellationRequests
            }
            isFilled={
              shift.totalAcceptedClaims === shift.capacity &&
              shift.totalCancellationRequests === 0
            }
            isSelected={isSelected}
          />
        </div>
        <div className="mt-small flex flex-row items-center justify-between">
          <ScheduleComponent
            startTime={shift.startTime}
            finishTime={shift.finishTime}
          />
          <div className="flex flex-row items-center justify-start">
            <p className="body-sm">{formatDate(shift.startTime)}</p>
          </div>
        </div>
      </div>
    </ShiftCardContainer>
  );
};
