import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

import { ProfessionalReviewInfo } from "@/types/claims";

interface ProfessionalRatingStarProps {
  professionalReview: ProfessionalReviewInfo;
  textClassName?: string;
}

export const ProfessionalRatingStar: React.FC<ProfessionalRatingStarProps> = ({
  professionalReview,
  textClassName,
}) => {
  const { t } = useTranslation("shift-claim-details");
  return professionalReview.totalReviews > 0 ? (
    <div className=":hovered:bg-Background-Secondary flex w-full flex-row items-center justify-start space-x-tiny">
      <LivoIcon name="star-filled" size={12} color="#FF911C" />
      <p
        className={`body-sm ${
          textClassName ? textClassName : "text-Text-Default"
        }`}
      >
        {professionalReview.averageRating}
      </p>

      <p
        className={`body-sm ${
          textClassName ? textClassName : "text-Text-Subtle"
        }`}
      >
        {`(${professionalReview.totalReviews} ${
          professionalReview.totalReviews > 1 ? "Reseñas" : "Reseña"
        })`}
      </p>
    </div>
  ) : (
    <p
      className={`info-caption ${
        textClassName ? textClassName : "text-Text-Subtle"
      }`}
    >
      {t("no_reviews_label")}
    </p>
  );
};
