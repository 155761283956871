import React, { useEffect, useRef, useState } from "react";

import LivoIcon from "@/components/common/LivoLogo";

type Option = {
  id: string;
  name: string;
};

interface DropDownWithInputProps {
  setOptionId: (id: string) => void;
  selectedOptionId: string;
  placeHolder?: string;
  errorMessage?: string;
  options?: Option[];
  disabled?: boolean;
  autoFocus?: boolean;
  hasChanged?: boolean;
  callToActionIcon?: string;
  label?: string;
}

export const DropDownWithInput: React.FC<DropDownWithInputProps> = ({
  setOptionId,
  selectedOptionId,
  placeHolder,
  errorMessage,
  options = [],
  disabled,
  autoFocus,
  hasChanged,
  callToActionIcon,
  label,
}) => {
  const selectedOptionName =
    options.find((option) => option.id === selectedOptionId)?.name || "";
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const matchingOptionIndex = options.findIndex((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  useEffect(() => {
    setInputValue(selectedOptionName);
  }, [selectedOptionName]);

  useEffect(() => {
    if (
      isFocused &&
      inputValue &&
      dropdownRef.current &&
      matchingOptionIndex !== -1
    ) {
      const matchingOptionElement = dropdownRef.current.children[
        matchingOptionIndex
      ] as HTMLLIElement;
      matchingOptionElement.scrollIntoView({ block: "nearest" });
    }
  }, [inputValue, isFocused, options, matchingOptionIndex]);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option: Option) => {
    setOptionId(option.id);
    setInputValue("");
    setIsFocused(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      setInputValue("");
    }, 100);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && matchingOptionIndex !== -1) {
      e.preventDefault();
      const matchingOption = options[matchingOptionIndex];
      setOptionId(matchingOption.id);
      setInputValue("");
      setIsFocused(false);
      inputRef.current?.blur();
    }
  };

  return (
    <div className="relative flex w-full flex-col">
      {label && selectedOptionId && selectedOptionId !== "" ? (
        <div className="absolute -top-small left-small bg-white px-tiny">
          <p className="info-overline text-Text-Subtle">{label}</p>
        </div>
      ) : null}
      <div
        className={`ring-solid box-border flex w-full flex-row items-center justify-start space-x-small rounded-[8px] px-small py-medium ring-1 ${!disabled ? "bg-white" : "bg-Background-Secondary"} shrink-1
                    ${errorMessage ? "ring-2 ring-red-500" : "ring-Divider-Subtle"} focus-within:ring-2 focus-within:ring-Action-Secondary`}
      >
        {hasChanged && !isFocused ? (
          <div className="size-small rounded-full bg-Action-Primary" />
        ) : null}
        <input
          ref={inputRef}
          disabled={disabled}
          type="text"
          value={isFocused ? inputValue : selectedOptionName}
          onChange={handleInputValue}
          onBlur={handleInputBlur}
          className="body-regular placeholder:body-regular w-full bg-transparent placeholder:text-Text-Subtle focus:outline-none"
          placeholder={placeHolder}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyPress}
          autoFocus={autoFocus}
        />
        {callToActionIcon ? (
          <div className="items-center justify-center">
            <LivoIcon name={callToActionIcon} size={24} color={"#149EF2"} />
          </div>
        ) : null}
      </div>
      {isFocused && options.length > 0 && (
        <ul
          ref={dropdownRef}
          className="absolute top-full z-10 mt-tiny max-h-60 w-full overflow-auto rounded-[8px] border border-Divider-Default bg-white"
          style={{
            boxShadow:
              "0px 1px 4px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {options.map((option, index) => (
            <li
              key={option.id}
              className={`body-regular cursor-pointer px-large py-medium ${inputValue && index === matchingOptionIndex ? "bg-Background-Secondary" : ""} hover:bg-Background-Secondary`}
              onMouseDown={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
      {errorMessage && (
        <p className="info-caption mt-tiny text-Negative-500">{errorMessage}</p>
      )}
    </div>
  );
};
