import { translate } from "@/services/i18next/translate";
import { ShiftModalityEnum } from "@/types";

export enum FacilityReviewStatusEnum {
  CONFIRMED = "CONFIRMED",
  UNAVAILABLE = "UNAVAILABLE",
  IN_REVIEW = "IN_REVIEW",
  AVAILABLE_SOON = "AVAILABLE_SOON",
}

type ColorThemeEnum = "success" | "default" | "warning";

type ReviewStatusLabelProps = {
  [key in FacilityReviewStatusEnum]: {
    label: string;
    colorTheme: ColorThemeEnum;
  };
};

export const getReviewStatusLabelProps: () => ReviewStatusLabelProps = () => ({
  UNAVAILABLE: {
    label: translate("shift-claim-list:unavailable_tag_label"),
    colorTheme: "default",
  },
  AVAILABLE_SOON: {
    label: translate("shift-claim-list:available_soon_tag_label"),
    colorTheme: "default",
  },
  IN_REVIEW: {
    label: translate("shift-claim-list:in_review_tag_label"),
    colorTheme: "warning",
  },
  CONFIRMED: {
    label: translate("shift-claim-list:confirmed_tag_label"),
    colorTheme: "success",
  },
});

export const modalityTags = {
  [ShiftModalityEnum.EXTERNAL]: {
    tagCode: ShiftModalityEnum.EXTERNAL,
    displayText: "Livo",
    color: "#149ef2",
    icon: "livo",
    backgroundColor: "#E0EFFF",
  },
  [ShiftModalityEnum.INTERNAL]: {
    tagCode: ShiftModalityEnum.INTERNAL,
    displayText: "Interno",
    color: "#5fbdad",
    icon: "internal-hospital",
    backgroundColor: "#eff7e7",
  },
  [ShiftModalityEnum.REPEAT]: {
    tagCode: ShiftModalityEnum.REPEAT,
    displayText: "Recurrente",
    color: "#707A91",
    icon: "repeat",
    backgroundColor: "#EEEFF3",
  },
};
