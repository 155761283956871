import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Avatar, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import { IconUser } from "@tabler/icons-react";

import LivoIcon from "@/components/common/LivoLogo";

import colors from "@/config/color-palette";
import { AppDispatch } from "@/store";
import { showToastAction } from "@/store/actions/appConfigurationActions";
import { ProfessionalInitProfile, ProfessionalLegalProfile } from "@/types";

import BoldTitleAndValue from "./common/BoldTitleAndValue";
import FirstShifterTag from "./common/FirstShifterTag";

export default function ProfessionalProfileCard({
  professionalInitProfile,
  professionalLegalProfile,
}: {
  professionalInitProfile: ProfessionalInitProfile;
  professionalLegalProfile?: ProfessionalLegalProfile;
}) {
  const { t } = useTranslation(["shift-claim-details", "shift-claim-list"]);
  const dispatch = useDispatch<AppDispatch>();

  const BoxRow = ({ children }: { children: React.ReactNode }) => (
    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>{children}</Box>
  );
  const legalDocumentationNotAvailable = (
    <Typography variant="body2" color="text.secondary" gutterBottom>
      {t("unavailable_legal_information")}
    </Typography>
  );

  const deductionPercentage = professionalLegalProfile?.deductionPercentage
    ? professionalLegalProfile.deductionPercentage.toString() + "%"
    : "";
  const fieldsToCopy = t("copy_professional_fields", {
    firstName: professionalInitProfile.firstName || "",
    lastName: professionalInitProfile.lastName || "",
    phoneNumber: professionalInitProfile.phoneNumber || "",
    email: professionalInitProfile.email || "",
    licenseNumber: professionalInitProfile.licenseNumber || "",
    hasLegalProfile: !!professionalLegalProfile,
    nationalId: professionalLegalProfile?.nationalId || "",
    address: professionalLegalProfile?.address || "",
    bankAccountNumber: professionalLegalProfile?.bankAccountNumber || "",
    deductionPercentage: deductionPercentage,
    socialSecurityNumber: professionalLegalProfile?.socialSecurityNumber || "",
  });

  const legalDocumentation = professionalLegalProfile && (
    <div>
      <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
        {t("legal_information_title")}
      </Typography>
      <BoxRow>
        <BoldTitleAndValue
          title={t("national_id")}
          value={professionalLegalProfile.nationalId}
          placeholder={t("shift-claim-list:pending_label")}
          annotation={
            professionalLegalProfile.nationalIdDocExpired
              ? t("expired_document_label")
              : undefined
          }
          copyText={professionalLegalProfile.nationalId}
          copyTextSuccess={t("national_id_copied")}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={t("address_label")}
          value={professionalLegalProfile.address}
          placeholder={t("shift-claim-list:pending_label")}
          copyText={professionalLegalProfile.address}
          copyTextSuccess={t("address_copied")}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={t("bank_account_number_label")}
          value={professionalLegalProfile.bankAccountNumber}
          placeholder={t("shift-claim-list:pending_label")}
          copyText={professionalLegalProfile.bankAccountNumber}
          copyTextSuccess={t("bank_account_number_copied")}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={t("deduction_percentage_label")}
          value={deductionPercentage}
          placeholder={t("shift-claim-list:pending_label")}
          copyText={deductionPercentage}
          copyTextSuccess={t("deduction_percentage_copied")}
        />
      </BoxRow>
      <BoxRow>
        <BoldTitleAndValue
          title={t("social_security_number_label")}
          value={professionalLegalProfile.socialSecurityNumber}
          placeholder={t("shift-claim-list:pending_label")}
          copyText={professionalLegalProfile.socialSecurityNumber}
          copyTextSuccess={t("social_security_number_copied")}
        />
      </BoxRow>
    </div>
  );
  return (
    <Card sx={{ m: 1, flex: 1, minWidth: 400 }}>
      <CardContent>
        <div className="flex flex-row items-center justify-between">
          <Typography
            sx={{ fontSize: 14, mb: 2 }}
            color="text.secondary"
            gutterBottom
          >
            {t("professional_profile_title")}
          </Typography>
          <button
            onClick={() => {
              navigator.clipboard.writeText(fieldsToCopy);
              dispatch(
                showToastAction({
                  message: "Información copiada con éxito",
                  severity: "success",
                })
              );
            }}
            className="ring-solid flex flex-row items-center rounded-[100px] py-small pl-medium pr-large ring-1 ring-[#848DA9]"
          >
            <p className="action-regular mr-tiny text-Primary-500">
              {t("copy_all_fields")}
            </p>
            <LivoIcon name={"copy"} size={16} color={"#149EF2"} />
          </button>
        </div>
        <Box sx={{ mb: 3 }}>
          <Box
            sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}
          >
            <Avatar
              src={professionalInitProfile.profilePictureUrl}
              sx={{ width: 70, height: 70, marginBottom: 2, mr: 2 }}
            >
              {professionalInitProfile.profilePictureUrl ? null : (
                <IconUser size={36} color={colors["Text-Subtle"]} />
              )}
            </Avatar>
            <Box>
              <Typography variant="h5" component="div">
                {professionalInitProfile.firstName}{" "}
                {professionalInitProfile.lastName}{" "}
                {professionalInitProfile.secondLastName}
              </Typography>

              {professionalInitProfile.firstShifter && <FirstShifterTag />}

              <BoldTitleAndValue
                title={t("license_number_label")}
                value={professionalInitProfile.licenseNumber}
                placeholder={t("shift-claim-list:pending_label")}
              />
              {professionalLegalProfile && (
                <BoldTitleAndValue
                  title={t("national_id")}
                  value={professionalLegalProfile.nationalId}
                  placeholder={t("shift-claim-list:pending_label")}
                  annotation={
                    professionalLegalProfile.nationalIdDocExpired
                      ? t("expired_document_label")
                      : undefined
                  }
                  copyText={professionalLegalProfile.nationalId}
                  copyTextSuccess={t("national_id_copied")}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ mb: 6 }}>
          <Typography variant="h5" sx={{ mb: 2, fontSize: 20 }}>
            {t("contact_information_label")}
          </Typography>
          <BoxRow>
            <BoldTitleAndValue
              title={t("email_label")}
              value={professionalInitProfile.email}
              copyText={professionalInitProfile.email}
              copyTextSuccess={t("email_copied")}
            />
          </BoxRow>
          <BoxRow>
            <BoldTitleAndValue
              title={t("phone_number_label")}
              value={professionalInitProfile.phoneNumber}
              copyText={professionalInitProfile.phoneNumber}
              copyTextSuccess={t("phone_number_copied")}
            />
          </BoxRow>
        </Box>
        {professionalLegalProfile
          ? legalDocumentation
          : legalDocumentationNotAvailable}
      </CardContent>
    </Card>
  );
}
