import { useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import WelcomeModal from "@/components/common/modal/WelcomeModal";
import Sidebar from "@/components/layout/Sidebar";

import { FLAGS } from "@/config/flag-enums";
import { useFlag } from "@/hooks/use-flag";
import { useModal } from "@/hooks/use-modal";
import { useProtectedRoutes } from "@/routers/hooks/use-protected-router";
import FlagsService from "@/services/flags.service";

interface ProtectedLayoutProps {
  logOut: () => void;
}

const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ logOut }) => {
  const firstLoginFlag = useFlag(FLAGS.WELCOME_FIRST_LOGIN);
  const { openModal } = useModal();
  const navigate = useNavigate();
  const openRef = useRef(false);

  const sideBarRoutes = useProtectedRoutes().filter(
    (route) => !!route.iconName
  );

  useEffect(() => {
    if (firstLoginFlag && !openRef.current) {
      openRef.current = true;
      // Remove flag
      FlagsService.removeFlag(FLAGS.WELCOME_FIRST_LOGIN);
      // Show welcome modal
      const modalContent = (
        <WelcomeModal
          onConfirm={() => {
            navigate("/ofertas-publicadas?action=create-offer");
          }}
        />
      );
      openModal(modalContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoginFlag]);

  return (
    <div className="no-scrollbar flex overflow-y-hidden">
      <Sidebar logOut={logOut} routes={sideBarRoutes} />
      <div className="content flex-1 overflow-x-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
