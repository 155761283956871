import { ProfessionalRatingStar } from "@/components/common/ProfessionalRatingStar";
import { ProfilePicture } from "@/components/shiftDetails/ProfilePicture";

import { ShiftModalityEnum } from "@/types";
import { OfferClaim } from "@/types/offers";

import { OfferClaimStatusTag } from "./OfferStatusTag";
import { SkillTagsCompact } from "./SkillTagsCompact";

interface OfferClaimCardCompactProps {
  claim: OfferClaim;
  isSelected: boolean;
  selectClaim: (claimId: OfferClaim) => void;
}

export const OfferClaimCardCompact: React.FC<OfferClaimCardCompactProps> = ({
  claim,
  isSelected,
  selectClaim,
}) => {
  return (
    <div className="relative">
      <div
        ref={(node) => {
          if (node && isSelected) {
            node.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }}
        onClick={() => selectClaim(claim)}
        className={`flex flex-row items-center p-small ${isSelected ? "bg-Action-Secondary text-Text-Inverse" : "bg-white"} relative cursor-pointer rounded-[16px]`}
        style={{
          minWidth: "200px",
          maxWidth: "1280px",
        }}
      >
        <div className="inline-flex items-start justify-start gap-2 rounded-lg">
          <div className="flex shrink grow basis-0 items-start justify-start gap-4">
            <div className="relative size-12 rounded-lg">
              <ProfilePicture
                profilePictureUrl={claim.professionalProfile.profilePictureUrl}
                size={48}
                modality={ShiftModalityEnum.EXTERNAL}
              />
            </div>
            <div className="flex min-h-20 shrink grow basis-0 items-start justify-start gap-2">
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-2">
                <div className="w-48 font-['Roboto'] text-base font-normal leading-normal">
                  {claim.professionalProfile.fullName}
                </div>
                <div className="inline-flex w-44 items-start justify-start gap-1">
                  <SkillTagsCompact skills={claim.professionalProfile.skills} />
                </div>
                {claim.professionalProfile.professionalReview && (
                  <div className="inline-flex items-center justify-start gap-1">
                    <ProfessionalRatingStar
                      professionalReview={
                        claim.professionalProfile.professionalReview
                      }
                      textClassName={
                        isSelected ? "text-Text-Inverse" : undefined
                      }
                    />
                  </div>
                )}
              </div>
              <div className="inline-flex w-20 flex-col items-end justify-start gap-2 self-stretch">
                <div className="inline-flex w-20 items-start justify-end gap-2.5">
                  <OfferClaimStatusTag status={claim.status} />
                </div>
              </div>
              <div className="absolute right-0 top-1/2 w-[20px] -translate-y-1/2">
                {claim.newClaim && (
                  <div className="flex items-center justify-center">
                    <div className="size-2 rounded-full bg-blue-500"></div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
