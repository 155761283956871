import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { ActionButton } from "@/components/common/ActionButton";
import LivoIcon from "@/components/common/LivoLogo";
import SortingSelector, {
  SortingOptionsEnum,
} from "@/components/common/SortingSelector";
import { ShiftConfiguration } from "@/components/publishShift/BaseShiftForm";
import { PublishShiftModal } from "@/components/publishShift/PublishShiftModal";
import { EditShiftModal } from "@/components/shiftDetails/EditShiftModal";

import { setAppliedFilter } from "@/store/actions/publishShiftListActions";
import { RootState } from "@/store/types";
import { applyFilter } from "@/types/common/shiftFilters";
import {
  ActionComponentIdEnum,
  DayShift,
  Shift,
  ShiftTimeInDayEnum,
} from "@/types/shifts";
import { day, today } from "@/utils/datetime";

import { DayShiftComponent } from "./DayShiftComponent";
import { EmptyShiftsState } from "./EmptyShiftsState";
import { FilteredShiftsEmptyState } from "./FilteredShiftsEmptyState";
import FilterShiftsModal from "./FilterShiftsModal";
import { FiltersRow } from "./FiltersRow";

interface ShiftListComponentProps {
  dayShifts: DayShift[];
  loading: boolean;
  reloadData: () => void;
  shadowReload: () => void;
  selectedSortingOption: SortingOptionsEnum;
  sortingOptions: SortingOptionsEnum[];
  setSelectedSortingOption: (option: SortingOptionsEnum) => void;
}

export const ShiftListComponent: React.FC<ShiftListComponentProps> = ({
  dayShifts,
  loading,
  reloadData,
  shadowReload,
  selectedSortingOption,
  sortingOptions,
  setSelectedSortingOption,
}) => {
  const { t } = useTranslation(["publish-shift", "calendar"]);
  const { appliedFilter } = useSelector(
    (state: RootState) => state.publishShiftList
  );

  const [publishShiftOpen, setPublishShiftOpen] = useState(false);
  const [actionableShift, setActionableShift] = useState<Shift | null>(null);

  const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] =
    useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const filters = useSelector((state: RootState) => state.filterShifts.filters);
  const filteredDayShifts = [] as DayShift[];
  dayShifts.forEach((dayShift) => {
    let filteredShifts = dayShift.shifts;
    filters.forEach((f) => (filteredShifts = applyFilter(f, filteredShifts)));
    if (filteredShifts.length > 0) {
      filteredDayShifts.push({
        ...dayShift,
        shifts: filteredShifts,
      });
    }
  });

  const dispatch = useDispatch();

  return (
    <div className="no-scrollbar flex w-full flex-1 justify-center overflow-y-auto pt-xLarge mx-10">
      <div className="flex size-full max-w-screen-lg flex-col pb-xLarge">
        <div className="mb-large flex w-full flex-wrap items-center justify-between space-x-small">
          <div className="mr-small flex items-center space-x-small">
            <p className="heading-md text-ellipsis text-nowrap">
              {t("calendar:shift_list_title")}
            </p>
            <p className="body-large text-Text-Subtle ">
              ({filteredDayShifts.length})
            </p>
          </div>
          <div className="flex self-end">
            <ActionButton
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                setPublishShiftOpen(true);
                setEditShiftDetailsModalOpen(false);
              }}
              inverse={true}
              color={"#149EF2"}
            >
              <div className="flex items-center space-x-small">
                <p className="action-regular text-Action-Primary">
                  {t("publish_shift_button")}
                </p>
                <LivoIcon name="plus" size={24} color="#149EF2" />
              </div>
            </ActionButton>
          </div>
        </div>
        <div className="flex w-full flex-wrap items-center space-x-tiny space-y-tiny pb-large">
          <FiltersRow
            appliedFilter={appliedFilter}
            setFilter={(filter: string) => {
              dispatch(setAppliedFilter(filter));
            }}
            onFiltersButtonClick={() => setFilterModalOpen(true)}
          />
        </div>
        <div className="flex w-full justify-center space-y-small">
          {loading ? (
            <div className="flex h-full flex-1 items-center justify-center">
              <CircularProgress />
            </div>
          ) : filteredDayShifts.length === 0 ? (
            appliedFilter === "all" ? (
              <EmptyShiftsState
                onClick={() => {
                  setPublishShiftOpen(true);
                }}
              />
            ) : (
              <FilteredShiftsEmptyState
                onClick={() => {
                  dispatch(setAppliedFilter("all"));
                }}
              />
            )
          ) : (
            <div className="relative flex flex-1 flex-col space-y-small">
              {sortingOptions.length > 0 ? (
                <SortingSelector
                  selectedOption={selectedSortingOption}
                  options={sortingOptions}
                  onChange={(option) =>
                    setSelectedSortingOption(option as SortingOptionsEnum)
                  }
                  className="absolute right-0 top-0"
                />
              ) : null}
              {filteredDayShifts.map((dayShift, index) => {
                return (
                  <DayShiftComponent
                    key={index}
                    dayShift={dayShift}
                    actionComponents={[
                      {
                        id: ActionComponentIdEnum.EDIT,
                        iconName: "pencil",
                        onClick: (shift: Shift) => {
                          setActionableShift(shift);
                          setEditShiftDetailsModalOpen(true);
                          setPublishShiftOpen(false);
                        },
                      },
                      {
                        id: ActionComponentIdEnum.COPY,
                        iconName: "copy",
                        onClick: (shift: Shift) => {
                          setActionableShift(shift);
                          setPublishShiftOpen(true);
                          setEditShiftDetailsModalOpen(false);
                        },
                      },
                    ]}
                    sortedBy={
                      sortingOptions.length > 0
                        ? selectedSortingOption
                        : undefined
                    }
                  />
                );
              })}
              <div
                style={{
                  height: "24px",
                }}
              ></div>
            </div>
          )}
        </div>

        <PublishShiftModal
          isOpen={publishShiftOpen}
          onClose={() => {
            setPublishShiftOpen(false);
            setActionableShift(null);
          }}
          selectedDate={today().format("YYYY-MM-DD")}
          shiftTimeInDay={ShiftTimeInDayEnum.MORNING}
          reloadData={reloadData}
          shiftDetails={
            actionableShift
              ? ({
                  ...actionableShift,
                  recurrentDates: actionableShift
                    ? [day(actionableShift.startTime).format("YYYY-MM-DD")]
                    : [],
                  compensationOptions:
                    actionableShift?.compensationOptions.map(
                      (option) => option.value
                    ) || [],
                } as ShiftConfiguration)
              : undefined
          }
        />
        <EditShiftModal
          isOpen={editShiftDetailsModalOpen && actionableShift !== null}
          onClose={() => {
            setEditShiftDetailsModalOpen(false);
            setActionableShift(null);
          }}
          reloadData={() => {
            shadowReload();
          }}
          shiftDetails={actionableShift}
        />
      </div>
      <FilterShiftsModal
        isOpen={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
      />
    </div>
  );
};
