import { CategoryTag } from "@/components/common/CategoryTag";
import { ModalityTag } from "@/components/internal/ModalityTag";

import { ShiftModalityEnum } from "@/types";
import { Category } from "@/types/shifts";

interface CategoryAndVisibilityTagsProps {
  category?: Category;
  recurrenVisible: boolean;
  internalVisible: boolean;
  externalVisible: boolean;
  noMargin?: boolean;
}

export const CategoryAndVisibilityTags: React.FC<
  CategoryAndVisibilityTagsProps
> = ({
  category,
  recurrenVisible,
  internalVisible,
  externalVisible,
  noMargin,
}) => {
  return category || recurrenVisible || internalVisible || externalVisible ? (
    <div
      className={`flex flex-row items-center space-x-tiny ${!noMargin ? "mb-tiny" : ""}`}
    >
      {category ? <CategoryTag text={category.acronym} /> : null}
      {recurrenVisible ? (
        <ModalityTag modality={ShiftModalityEnum.REPEAT} shortTag={true} />
      ) : null}
      {internalVisible ? (
        <ModalityTag modality={ShiftModalityEnum.INTERNAL} shortTag={true} />
      ) : null}
      {externalVisible ? (
        <ModalityTag modality={ShiftModalityEnum.EXTERNAL} shortTag={true} />
      ) : null}
    </div>
  ) : null;
};
