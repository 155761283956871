import LivoIcon from "@/components/common/LivoLogo";
import { NotificationsBadge } from "@/components/common/NotificationsBadge";

import clsx from "clsx";

interface OfferSideBarMenuProps {
  label: string;
  badge: number;
  onClick: () => void;
  selected: boolean;
}

export const OfferSideBarMenu: React.FC<OfferSideBarMenuProps> = ({
  label,
  badge,
  onClick,
  selected,
}) => {
  return (
    <div
      className={clsx(
        selected ? "bg-Secondary-700 text-white" : "text-Neutral-950",
        "inline-flex items-center justify-start gap-2 self-stretch rounded-lg py-3 pl-2 pr-1"
      )}
      onClick={onClick}
    >
      <div className="shrink grow basis-0 font-['Roboto'] text-base font-normal leading-normal">
        {label}
      </div>
      {badge > 0 && <NotificationsBadge notifications={badge} />}
      <LivoIcon name="chevron-right" size={24} color="#ACBBCB" />
    </div>
  );
};
