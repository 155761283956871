// src/Sidebar.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { SideBarButton } from "@/components/common/SideBarButton";

import { RouteProps } from "@/routers/config";
import { VisibleTabEnum } from "@/services/account";
import { AppDispatch } from "@/store";
import { fetchActivity } from "@/store/actions/activityShiftListActions";
import { fetchPendingRequests } from "@/store/actions/pendingProfessionalsActions";
import { RootState } from "@/store/types";

interface SidebarProps {
  logOut: () => void;
  routes: RouteProps[];
}
const Sidebar: React.FC<SidebarProps> = ({ logOut, routes }) => {
  const location = useLocation();
  const getSelectedRoute = (path: string) => {
    return location.pathname === path;
  };
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const handleNavigation = (path: string) => {
    navigate(path);
  };
  const { activityShiftList, pendingRequests } = useSelector(
    (state: RootState) => state
  );

  useEffect(() => {
    //poll for activity notification badge, in case we need any other we might migrate this to a polling api
    if (
      routes.some(
        (route) =>
          route.id === VisibleTabEnum.ACTIONABLE_SHIFTS ||
          route.id === VisibleTabEnum.INTERNAL_STAFF_MANAGEMENT
      )
    ) {
      const interval = setInterval(() => {
        if (
          routes.some((route) => route.id === VisibleTabEnum.ACTIONABLE_SHIFTS)
        ) {
          dispatch(fetchActivity());
        }

        if (
          routes.some(
            (route) => route.id === VisibleTabEnum.INTERNAL_STAFF_MANAGEMENT
          )
        ) {
          dispatch(fetchPendingRequests());
        }
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [routes]);

  return (
    <div className="content border-Divder-Subtle flex flex-col justify-between border-r bg-white  py-[20px] ">
      <div className="flex flex-1 flex-col">
        <ul>
          {routes.map((route, index) => (
            <li key={index} className="mb-[20px] px-medium">
              <SideBarButton
                onClick={() => {
                  handleNavigation(route.path);
                }}
                name={route?.iconName!}
                isFocused={getSelectedRoute(route.path)}
                notifications={
                  route.id === VisibleTabEnum.ACTIONABLE_SHIFTS
                    ? activityShiftList.shifts.length
                    : route.id === VisibleTabEnum.INTERNAL_STAFF_MANAGEMENT
                      ? pendingRequests.count
                      : undefined
                }
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-col items-center">
        <SideBarButton
          onClick={() => {
            logOut();
            requestAnimationFrame(() => {
              navigate("/");
            });
          }}
          name={"log-out"}
          isFocused={false}
        />
      </div>
    </div>
  );
};

export default Sidebar;
