import { useCallback, useRef } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  Box,
  ClickAwayListener,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";

import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import {
  AnimatedPaper,
  useAnimatedPaper,
} from "@/components/common/animation/AnimatedPaper";
import DialogConfirmButtons from "@/components/common/buttons/DialogConfirmButtons";
import ChipWithTooltip from "@/components/common/ChipWithTooltip";
import LivoIcon from "@/components/common/LivoLogo";

import colors from "@/config/color-palette";

import SelectCheckbox, { Option, OTHER_PREFIX } from "./SelectCheckbox";

import clsx from "clsx";

type FormSelectCheckBoxProps<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  multiple?: boolean;
  options: Option[];
  placeholder?: string;
  label?: string;
  enableOtherSelect?: boolean;
  required?: boolean;
};

// Function to remove the "other:" prefix if present
const removeOtherPrefix = (value: string) => {
  if (value.startsWith(OTHER_PREFIX)) {
    // first is prefix, second is id, thỉd is checked status, then rest is value
    const [, , , ...displayValue] = value.split(":");
    return displayValue.join(":");
  }

  return value;
};

function FormRichContentSelectCheckbox<T extends FieldValues>({
  control,
  name,
  multiple = false,
  options,
  placeholder,
  label,
  enableOtherSelect,
  required,
}: FormSelectCheckBoxProps<T>) {
  const { t } = useTranslation();
  const { anchorRef, open, transitioning, handleClose, toggleOpenClose } =
    useAnimatedPaper<HTMLElement>();
  const chipContainerRef = useRef<HTMLDivElement | null>(null);

  const renderCheckboxLabel = useCallback((option: Option) => {
    return (
      <ChipWithTooltip
        trimLength={100}
        label={option.label}
        className="bg-Neutral-200 px-2 py-0.5 text-Grey-900"
        icon={
          option?.icon ? (
            <LivoIcon
              name={option.icon}
              size={16}
              color={colors["Text-Subtle"]}
            />
          ) : undefined
        }
      />
    );
  }, []);

  const renderChips = (
    selectedValues: string[],
    handleDeleteChip: (val: string) => void
  ) => {
    if (multiple) {
      return selectedValues?.length > 1 ? (
        <InputAdornment
          position="start"
          sx={{
            width: "95%",
            height: "100%",
            minWidth: "95%",
          }}
        >
          <Box
            ref={chipContainerRef}
            className="!h-full !w-full gap-1 overflow-y-auto"
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              maxHeight: "80px",
              overflowY: "auto",
              alignItems: "center",
            }}
          >
            {selectedValues
              .map((val) => {
                const opt = options.find((o) => o.value === val);

                if (val.startsWith(OTHER_PREFIX)) {
                  const [, , checked, ...value] = val.split(":");
                  if (checked !== "true") return null;
                  if (value.join(":") === "") return null;
                }

                return (
                  <ChipWithTooltip
                    key={`form-rich-select-${name}-value-${val}`}
                    label={opt ? opt.label : removeOtherPrefix(val)}
                    className="bg-Neutral-200 text-Grey-900"
                    trimLength={20}
                    style={{
                      maxWidth: "100%",
                      flexShrink: 0,
                      minWidth: "fit-content",
                    }}
                    icon={
                      opt?.icon ? (
                        <div className="flex size-6 items-center justify-center">
                          <LivoIcon
                            name={opt.icon}
                            size={16}
                            color={colors["Text-Subtle"]}
                          />
                        </div>
                      ) : undefined
                    }
                    onDelete={() => handleDeleteChip(val)}
                  />
                );
              })
              .filter((predicate) => !!predicate)}
          </Box>
        </InputAdornment>
      ) : null;
    } else {
      const opt = options.find((opt) => opt.value === selectedValues[0]);
      return (
        selectedValues.length > 0 && (
          <ChipWithTooltip
            className="mx-2 bg-Neutral-200 text-Grey-900"
            label={opt ? opt.label : removeOtherPrefix(selectedValues[0])}
            onDelete={() => handleDeleteChip(selectedValues[0])}
          />
        )
      );
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // Ensure value is an array of strings (if multiple selection is enabled)
        const selectedValues = Array.isArray(value) ? (value as string[]) : [];
        const containerHeight = Math.max(
          chipContainerRef?.current?.scrollHeight || 0,
          48
        );

        const handleCheckBoxChange = (vals: string[]) => {
          onChange(vals);
        };

        // Function to delete a chip
        const handleDeleteChip = (val: string) => {
          onChange(selectedValues.filter((item) => item !== val));
        };

        return (
          <div className="relative w-full mb-4">
            <TextField
              multiline
              minRows={1}
              maxRows={2}
              required={required}
              label={label}
              className="w-full overflow-hidden"
              placeholder={placeholder}
              InputProps={{
                value: containerHeight > 48 ? "\n\n\n" : "",
                readOnly: true,
                endAdornment: (
                  <div className="flex size-6 items-center justify-center">
                    {open ? (
                      <IconChevronUp size={24} color={colors["Text-Subtle"]} />
                    ) : (
                      <IconChevronDown
                        size={24}
                        color={colors["Text-Subtle"]}
                      />
                    )}
                  </div>
                ),
                startAdornment: renderChips(selectedValues, handleDeleteChip),
              }}
              onClick={toggleOpenClose}
              error={!!error}
              helperText={t(error?.message as never)}
            />

            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement="bottom-start"
              sx={{ zIndex: 9999 }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <AnimatedPaper
                  className={clsx(
                    open && !transitioning ? "popper-enter" : "popper-exit",
                    "!min-w-72 p-4"
                  )}
                >
                  <SelectCheckbox
                    enableOtherSelect={enableOtherSelect}
                    multiple={multiple}
                    values={selectedValues}
                    onChange={handleCheckBoxChange}
                    options={options}
                    renderLabel={renderCheckboxLabel}
                  />
                  <DialogConfirmButtons
                    buttons={[
                      {
                        label: "Cerrar",
                        onClick: () => {
                          handleCheckBoxChange([]);
                          handleClose();
                        },
                      },
                      {
                        label: "Guardar",
                        onClick: handleClose,
                      },
                    ]}
                  />
                </AnimatedPaper>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }}
    />
  );
}

export default FormRichContentSelectCheckbox;
