import { SortingOptionsEnum } from "@/components/common/SortingSelector";

import { RecurrencyOptionsEnum } from "@/types/publish-shift";
import { DayShift, FilterConfiguration, Shift } from "@/types/shifts";

import { api, handleApiError } from "./api";

function mapDayShift(dayShiftResponse: DayShift) {
  return {
    ...dayShiftResponse,
    shifts: dayShiftResponse.shifts.map(mapShift),
  };
}

export function mapShift(shiftResponse: any) {
  return {
    ...shiftResponse,
  } as Shift;
}

type FetchShiftParams = {
  fromDate?: string;
  toDate?: string;
  sortOrder?: string;
  withPendingClaims?: boolean;
  isFilled?: boolean;
  sortBy?: SortingOptionsEnum;
};

export function fetchShifts(
  fromDate?: string,
  toDate?: string,
  ordering: "ASC" | "DESC" = "ASC",
  filters: FilterConfiguration = {},
  sortBy?: SortingOptionsEnum
): Promise<DayShift[]> {
  const uri = "/facility/portal/shifts";

  const params: FetchShiftParams = {};
  if (fromDate) {
    params.fromDate = fromDate;
  }
  if (toDate) {
    params.toDate = toDate;
  }

  if (ordering) {
    params.sortOrder = ordering;
  }

  if (sortBy) {
    params.sortBy = sortBy;
  }

  return api
    .get(uri, {
      params: {
        ...params,
        ...filters,
      },
    })
    .then((response) => {
      return response.data ? response.data.map(mapDayShift) : [];
    })
    .catch(handleApiError);
}

export type ShiftSummary = {
  date: string;
  hasAlert: boolean;
  totalShifts: number;
  holiday?: boolean;
};

export function fetchShiftsSummary(
  fromDate?: string,
  toDate?: string,
  categories?: string[],
  units?: string[]
): Promise<ShiftSummary[]> {
  //format is YYYY-MM-DD
  const uri = "/facility/portal/shifts/shifts-summary";
  const params: {
    fromDate?: string;
    toDate?: string;
    categories?: string[];
    units?: string[];
  } = {};

  if (fromDate) {
    params["fromDate"] = fromDate;
  }
  if (toDate) {
    params["toDate"] = toDate;
  }
  if (categories) {
    params["categories"] = categories;
  }
  if (units) {
    params["units"] = units;
  }

  return api
    .get(uri, { params })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return handleApiError(error);
    });
}

export function fetchShiftDetails(shiftId: number): Promise<Shift | void> {
  const uri = `/facility/portal/shifts/${shiftId}`;
  return api
    .get(uri)
    .then((res) => mapShift(res.data))
    .catch(handleApiError);
}

export function cancelShiftRequest(
  shiftId: number,
  cancelReason: string,
  reasonDetails: string,
  bulkOperation?: RecurrencyOptionsEnum
): Promise<boolean | void> {
  const uri = `/facility/portal/shifts/${shiftId}/cancel-shift`;

  const body = {
    reason: cancelReason,
    reasonDetails,
    bulkOperation,
  };

  return api
    .post(uri, body)
    .then((res) => res.status === 200)
    .catch(handleApiError);
}

export function fetchShiftCancelReasons() {
  const uri = "/facility/common/shift-cancel-reasons";
  return api
    .get(uri)
    .then((res) => res.data)
    .catch(handleApiError);
}

export interface ShiftUpdateRequest {
  startTime: Date;
  endTime: Date;
  totalPay: number;
  details: string | null;
  unit?: string;
  capacity?: number;
  internalVisible: boolean;
  externalVisible: boolean;
  decreaseCapacityReason?: string;
  decreaseCapacityReasonDetails?: string;
  bulkOperation?: RecurrencyOptionsEnum;
  unitVisible: boolean;
}

export function updateShiftRequest(
  shiftId: number,
  shiftRequest: ShiftUpdateRequest
): Promise<boolean | void> {
  const uri = `/facility/portal/shifts/${shiftId}/update-shift`;

  return api
    .post(uri, shiftRequest)
    .then((res) => res.status === 200)
    .catch(handleApiError);
}

export function updateCapacityRequest(
  shiftId: number,
  newCapacity: number,
  cancelReason?: string,
  reasonDetails?: string
): Promise<boolean | void> {
  const uri = `/facility/portal/shifts/${shiftId}/change-capacity`;
  const body = {
    capacity: newCapacity,
    reason: cancelReason,
    reasonDetails,
  };
  return api
    .post(uri, body)
    .then((res) => res.status === 200)
    .catch(handleApiError);
}
