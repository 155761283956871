import { CategoryTag } from "../../components/common/CategoryTag";
import { Category } from "../../types/shifts";
import LivoIcon from "../common/LivoLogo";

interface LargeCardHeaderProps {
  title: string;
  recurrent: boolean;
  style?: any;
  category?: Category;
}

export const LargeCardHeader: React.FC<LargeCardHeaderProps> = ({
  title,
  recurrent,
  style,
  category,
}) => {
  return (
    <div className="flex w-full flex-row items-center justify-start space-x-tiny">
      {category ? <CategoryTag text={category.acronym} /> : null}
      <h1
        className="subtitle-regular truncate"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </h1>
    </div>
  );
};
