import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import LivoIcon from "@/components/common/LivoLogo";
import FavoriteProfessionalCard from "@/components/professionals/FavoriteProfessionalCard";

import { RootState } from "@/store/types";
import { ProfessionalProfile } from "@/types/claims";

interface ProfessionalFacilityExperienceProps {
  professionalProfile: ProfessionalProfile;
  shiftId?: number;
  claimId?: number;
}

export const ProfessionalFacilityExperience: React.FC<
  ProfessionalFacilityExperienceProps
> = ({ professionalProfile, shiftId, claimId }) => {
  const { t } = useTranslation("professional-claim");
  const account = useSelector((state: RootState) => state.account);
  return (
    <div className="flex w-full flex-col p-medium">
      <div className="mb-large flex flex-row space-x-small">
        <LivoIcon name="report-medical" size={24} color="#ACBBCB" />
        <p className="body-regular">{t("facility_experience")}</p>
      </div>
      <div className="ring-solid flex w-full flex-row items-center rounded-[8px] bg-white px-small py-medium ring-1 ring-Divider-Subtle">
        <div className="mr-small flex w-full flex-col">
          <p className="subtitle-regular">{t("completed_shifts")}</p>
          {account.accountInfo && (
            <p className="body-regular text-Text-Subtle">
              {t("in")} {account.accountInfo.facility.name}
            </p>
          )}
        </div>
        <div className="flex">
          {professionalProfile.firstShifterForFacility ? (
            <p className="heading-sm text-nowrap text-[#987ED4]">
              {t("first_shifter_label")}
            </p>
          ) : (
            <p className="heading-sm">
              {professionalProfile.totalShiftsInFacility.totalShiftsInFacility}
            </p>
          )}
        </div>
      </div>
      <FavoriteProfessionalCard
        professionalProfile={professionalProfile}
        shiftId={shiftId}
        claimId={claimId}
        className="ring-solid mt-large rounded-[8px] ring-1 ring-Divider-Subtle"
      />
    </div>
  );
};
