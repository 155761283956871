import { Shift } from "@/types/shifts";
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "@/utils";

import { ShiftCardActions } from "./ShiftCardActions";

interface ShiftCardContainerProps {
  shift: Shift;
  onClick?: () => void;
  isSelected?: boolean;
  children: React.ReactNode;
  actionComponents?: {
    iconName: string;
    onClick: (shift: Shift) => void;
  }[];
}

export const ShiftCardContainer: React.FC<ShiftCardContainerProps> = ({
  shift,
  isSelected,
  onClick,
  children,
  actionComponents,
}) => {
  const shiftTimeInDay = SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay];
  const cardColor = `bg-[${shiftTimeInDay.color}]`;
  const actionComponentsWithShifts = actionComponents?.map(
    (actionComponent) => ({
      iconName: actionComponent.iconName,
      onClick: () => {
        actionComponent.onClick(shift);
      },
    })
  );
  return (
    <div className="group relative">
      <div
        onClick={onClick}
        className={`flex flex-row ${
          isSelected ? "bg-Action-Secondary text-Text-Inverse" : "bg-white"
        } relative rounded-[16px] ${
          onClick ? "cursor-pointer" : ""
        } overflow-hidden`}
        style={{
          minWidth: "200px",
          maxWidth: "100%",
        }}
      >
        <div className={`w-small ${cardColor} flex overflow-hidden`}></div>
        {children}
      </div>
      {actionComponentsWithShifts ? (
        <div className="absolute -top-small right-large flex items-center justify-end opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
          <ShiftCardActions actionComponents={actionComponentsWithShifts} />
        </div>
      ) : null}
    </div>
  );
};
