import { ActivityShiftList } from "@/pages/ActivityShiftList";
import { Calendar } from "@/pages/Calendar";
import { FacilityStaffPage } from "@/pages/FacilityStaffPage";
import { InternalProfessionalsPage } from "@/pages/InternalProfessionalsPage";
import OfferList from "@/pages/OfferList";
import { PublishedShiftList } from "@/pages/PublishedShiftList";
import { ShiftClaimDetailsPage } from "@/pages/ShiftClaimDetailsPage";
import { ShiftClaimListScreen } from "@/pages/ShiftClaimListScreen";

import { ProtectedRoutesEnum, VisibleTabEnum } from "@/services/account";

export type RouteProps = {
  name: string;
  path: string;
  iconName?: string;
  component: React.FC;
  id: VisibleTabEnum | ProtectedRoutesEnum;
  notifications?: number;
};

export const ShiftClaimRoute: RouteProps = {
  name: "Shift Claim Details",
  path: "/shift-claim/:id",
  component: ShiftClaimDetailsPage,
  id: ProtectedRoutesEnum.SHIFT_CLAIM_DETAILS,
};

export const protectedRoutes = [
  {
    name: "Actividad",
    path: "/actividad",
    iconName: "bell",
    component: ActivityShiftList,
    id: VisibleTabEnum.ACTIONABLE_SHIFTS,
  },
  {
    name: "calendar",
    path: "/calendar",
    iconName: "calendar",
    component: Calendar,
    id: VisibleTabEnum.CALENDAR,
  },
  {
    name: "Turnos publicados",
    path: "/turnos-publicados",
    iconName: "report-medical",
    component: PublishedShiftList,
    id: VisibleTabEnum.NEXT_SHIFTS_LIST,
  },
  {
    name: "Ofertas publicadas",
    path: "/ofertas-publicadas",
    iconName: "briefcase",
    component: OfferList,
    id: VisibleTabEnum.OFFER_MANAGEMENT,
  },
  {
    name: "Shifts",
    path: "/documentacion",
    iconName: "file-text",
    component: ShiftClaimListScreen,
    id: VisibleTabEnum.LEGAL_DOCUMENTATION,
  },
  {
    name: "Internal Professionals",
    path: "/internal-professionals",
    iconName: "users",
    component: InternalProfessionalsPage,
    id: VisibleTabEnum.INTERNAL_STAFF_MANAGEMENT,
  },
  {
    name: "Facility Staff",
    path: "/facility-staff",
    iconName: "adjustments-alt",
    component: FacilityStaffPage,
    id: VisibleTabEnum.FACILITY_STAFF_MANAGEMENT,
  },
  ShiftClaimRoute,
] as RouteProps[];
