import { TagLabel } from "@/components/common/TagLabel";

interface SkillTagsCompactProps {
  skills: string[];
  flexWrap?: boolean;
}

export const SkillTagsCompact: React.FC<SkillTagsCompactProps> = ({
  skills,
  flexWrap = false,
}) => {
  if (!skills || skills.length === 0) return null;

  return (
    <div className="group relative">
      <div
        className={`flex flex-row ${flexWrap ? "flex-wrap gap-1" : "space-x-small"}`}
      >
        <TagLabel
          key={0}
          text={
            skills[0].length > 15
              ? skills[0].substring(0, 15) + "..."
              : skills[0]
          }
        />
        {skills.length > 1 && (
          <TagLabel
            key={1}
            text={
              skills[1].length > 15
                ? skills[1].substring(0, 15) + "..."
                : skills[1]
            }
          />
        )}
        {skills.length > 2 && <TagLabel text={`+${skills.length - 2}`} />}
      </div>

      {skills.length > 2 && (
        <div className="invisible absolute left-0 top-full z-10 mt-2 rounded bg-[#2C3038] px-2 py-1 text-white group-hover:visible">
          <div className="flex flex-col space-y-2 text-left text-s01">
            {skills.map((skill, index) => (
              <span key={index}>{skill}</span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
