import { useTranslation } from "react-i18next";

import { CategoryTag } from "@/components/common/CategoryTag";
import { CopyButton } from "@/components/common/CopyButton";
import { TagLabel } from "@/components/common/TagLabel";
import { ModalityTag } from "@/components/internal/ModalityTag";
import { ScheduleComponent } from "@/components/shifts/ScheduleComponent";

import { ShiftModalityEnum } from "@/types";
import { Shift } from "@/types/shifts";
import { formatDate, SHIFT_TIME_IN_DAY_DEFINITIONS } from "@/utils";
import { formatTimeSince } from "@/utils/utils";

import { HolidayTag } from "./HolidayTag";
import { ShiftDetailsRow } from "./ShiftDetailsRow";

interface ShiftDetailsBodyProps {
  shift: Shift;
}

export const ShiftDetailsBody: React.FC<ShiftDetailsBodyProps> = ({
  shift,
}) => {
  const { t } = useTranslation([
    "shift-list",
    "internal-professional-page",
    "calendar",
  ]);
  const shiftTimeInDayProps =
    SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay];
  const poolAndInternalOnboarded =
    shift.livoPoolOnboarded && shift.livoInternalOnboarded;
  const priceVisible = shift.externalVisible && shift.formattedTotalPay;
  const compensationOptionsVisible =
    shift.compensationOptions && shift.internalVisible;

  return (
    <div className="flex w-full flex-col space-y-xLarge px-medium py-large">
      <div className="mx-small flex w-full flex-col">
        <div className="flex flex-row items-center space-x-small">
          {shift.category ? (
            <CategoryTag text={shift.category.acronym} />
          ) : null}
          <p className="heading-md text-Text-Default">{shift.title}</p>
        </div>
        {shift.createdAt ? (
          <p className="info-caption text-Text-Subtle">
            {formatTimeSince(shift.createdAt)}
          </p>
        ) : null}
      </div>
      {poolAndInternalOnboarded && (
        <ShiftDetailsRow iconName="eye">
          <div className="flex flex-row items-center justify-start gap-2">
            {shift.internalVisible && (
              <ModalityTag modality={ShiftModalityEnum.INTERNAL} />
            )}
            {shift.externalVisible && (
              <ModalityTag modality={ShiftModalityEnum.EXTERNAL} />
            )}
          </div>
        </ShiftDetailsRow>
      )}
      {shift.skills.length > 0 ? (
        <ShiftDetailsRow iconName="stethoscope">
          <div className="inline-flex flex-row flex-wrap items-center justify-start gap-tiny space-x-small">
            {shift.skills.map((skill, index) => (
              <TagLabel key={index} text={skill.displayText} />
            ))}
          </div>
        </ShiftDetailsRow>
      ) : null}
      <div className="flex w-full flex-row items-center space-x-small">
        <ShiftDetailsRow
          iconName="calendar"
          style={{
            width: "auto",
          }}
        >
          <p className="body-regular">{formatDate(shift.startTime)}</p>
        </ShiftDetailsRow>
        <HolidayTag holiday={shift.holiday} />
      </div>

      <ShiftDetailsRow
        iconName={shiftTimeInDayProps.icon}
        iconColor={shiftTimeInDayProps.color}
      >
        <p className="body-regular">
          {t(("calendar:" + shiftTimeInDayProps.name) as never)}
        </p>
      </ShiftDetailsRow>
      <ShiftDetailsRow iconName={"clock"}>
        <ScheduleComponent
          style="body-regular space-x-small"
          startTime={shift.startTime}
          finishTime={shift.finishTime}
        />
      </ShiftDetailsRow>
      {shift.unit ? (
        <ShiftDetailsRow iconName="nurse">
          <div className="flex w-full flex-row items-center space-x-small">
            <p className="body-regular">{shift.unit}</p>
            {!shift.unitVisible ? (
              <p className="body-regular text-Text-Subtle">
                ({t("internal-professional-page:hidden_label")})
              </p>
            ) : null}
          </div>
        </ShiftDetailsRow>
      ) : null}
      {priceVisible || compensationOptionsVisible ? (
        <ShiftDetailsRow iconName={"cash-banknote"}>
          {priceVisible ? (
            <p className="body-regular whitespace-nowrap">
              {shift.formattedTotalPay}
            </p>
          ) : null}
          {priceVisible && compensationOptionsVisible ? (
            <p className="body-regular">|</p>
          ) : null}
          {shift.compensationOptions && shift.internalVisible ? (
            <p className="body-regular">
              {shift.compensationOptions
                .map((option) => option.displayText)
                .join(", ")}
            </p>
          ) : null}
        </ShiftDetailsRow>
      ) : null}
      {shift.details ? (
        <ShiftDetailsRow
          iconName="blockquote"
          style={{
            alignItems: "flex-start",
          }}
        >
          <p className="info-caption p-tiny">{shift.details}</p>
        </ShiftDetailsRow>
      ) : null}
      {shift.externalId ? (
        <div className="flex flex-row items-center justify-start space-x-small">
          <div>
            <ShiftDetailsRow iconName="hash">
              <p className="body-regular">{shift.externalId}</p>
            </ShiftDetailsRow>
          </div>
          <CopyButton
            successMessage={t("identifier_copied")}
            text={shift.externalId}
          />
        </div>
      ) : null}
    </div>
  );
};
