import { FacilityProfessionalsDTO } from "@/components/widgets/professionals/types";

import { api, handleApiError } from "./api";

export function fetchFacilityProfessionals(): Promise<FacilityProfessionalsDTO> {
  const uri = "/facility/portal/professionals";
  return api
    .get(uri)
    .then((res) => res.data)
    .catch(handleApiError);
}

export function updateFacilityProfessional(
  professionalId: string,
  favorite: boolean
): Promise<boolean | void> {
  const uri = `/facility/portal/professionals/${professionalId}/update-favorite`;
  return api
    .post(uri, null, { params: { favorite } })
    .then((res) => res.status === 200)
    .catch(handleApiError);
}

export function fetchProfessionalCVDetails(professionalId: number) {
  const uri = `/facility/portal/professionals/${professionalId}/cv-details`;
  return api
    .get(uri)
    .then((res) => res.data)
    .catch(handleApiError);
}
