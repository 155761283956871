import React, { useEffect, useMemo } from "react";

import { CalendarSummary, ShiftTimeConfigDTO } from "@/types/publish-shift";
import { day, setTime, today } from "@/utils/datetime";

import { DateSelector } from "./DateSelector";
import { RecurrentToggler } from "./RecurrentToggler";
import { ScheduleSelector } from "./ScheduleSelector";
import { ShiftTimeInDaySelector } from "./ShiftTimeInDaySelector";
import { SingleDateSelector } from "./SingleDateSelector";

interface DateTimeSelectorProps {
  selectedStartTime: string;
  selectedEndTime: string;
  setStartTime: (time: string) => void;
  setEndTime: (time: string) => void;
  shiftTimeInDayConfig: ShiftTimeConfigDTO;
  errorMessage?: string;
  recurrentDates: string[];
  setRecurrentDates: (dates: string[]) => void;
  subtitle?: string;
  isDisabled?: boolean;
  bulkSelectionEnabled?: boolean;
  calendarSummary: CalendarSummary[];
}
export const DateTimeSelector: React.FC<DateTimeSelectorProps> = ({
  selectedStartTime,
  selectedEndTime,
  setStartTime,
  setEndTime,
  shiftTimeInDayConfig,
  recurrentDates,
  setRecurrentDates,
  subtitle,
  isDisabled,
  bulkSelectionEnabled,
  calendarSummary,
}) => {
  const [isRecurrent, setIsRecurrent] = React.useState(false);

  const predictorDeactivated = Object.values(shiftTimeInDayConfig).every(
    (config) => {
      // hack to deactivate the predictor if all the values are 0
      return (
        config.startTime.hour === 0 &&
        config.startTime.minute === 0 &&
        config.endTime.hour === 0 &&
        config.endTime.minute === 0
      );
    }
  );

  const changeDates = (datesSelected: string[]) => {
    // extract the first item from the array

    setRecurrentDates(datesSelected);
    // find the first date in time
    const date = datesSelected.sort((a, b) => day(a).diff(day(b)))[0];

    const newStartTime = setTime(selectedStartTime, {
      year: day(date).year(),
      month: day(date).month(),
      date: day(date).date(),
    });
    const newEndTime = setTime(selectedEndTime, {
      year: day(date).year(),
      month: day(date).month(),
      date: day(date).date(),
    });

    setStartTime(newStartTime.toISOString());
    setEndTime(newEndTime.toISOString());
  };

  const changeShiftTimeInDay = (shiftTimeInDay: string) => {
    const shiftTimeInDayConfigHashKey =
      shiftTimeInDay as keyof typeof shiftTimeInDayConfig;

    const newStartTime = setTime(selectedStartTime, {
      hour: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].startTime.hour,
      minute:
        shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].startTime.minute,
    });
    const newEndTime = setTime(selectedEndTime, {
      hour: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].endTime.hour,
      minute: shiftTimeInDayConfig[shiftTimeInDayConfigHashKey].endTime.minute,
    });
    setStartTime(newStartTime.toISOString());
    setEndTime(newEndTime.toISOString());
  };

  const shiftTimeConfigArray = useMemo(() => {
    return Object.keys(shiftTimeInDayConfig).map((key) => {
      const shiftTimeInDayConfigHashKey =
        key as keyof typeof shiftTimeInDayConfig;
      const config = shiftTimeInDayConfig[shiftTimeInDayConfigHashKey];

      const start = setTime(today(), {
        hour: config.startTime.hour,
        minute: config.startTime.minute,
      });
      return {
        key: shiftTimeInDayConfigHashKey,
        start,
      };
    });
  }, [shiftTimeInDayConfig]);

  const shiftTimeInDay = useMemo(() => {
    const shiftTimeInDay = shiftTimeConfigArray.find(({ start }) => {
      const selected = day(selectedStartTime);
      return (
        start.hour() === selected.hour() && start.minute() === selected.minute()
      );
    });

    return shiftTimeInDay?.key || "dayShift";
  }, [selectedStartTime, shiftTimeConfigArray]);

  const handleSetTime = (
    time: string,
    setTimeDate: (timeDate: string) => void
  ) => {
    const [hour, minute] = time.split(":");
    const newTime = setTime(selectedStartTime, {
      hour: parseInt(hour),
      minute: parseInt(minute),
    });
    setTimeDate(newTime.toISOString());
  };

  useEffect(() => {
    if (!isRecurrent) {
      changeDates([recurrentDates[0]]);
    }
  }, [isRecurrent]);

  return (
    <div className="flex flex-col items-start justify-start space-y-medium self-start bg-white ">
      {bulkSelectionEnabled ? (
        <RecurrentToggler
          isRecurrent={isRecurrent}
          setIsRecurrent={setIsRecurrent}
        />
      ) : null}
      <div>
        <div
          style={{
            opacity: isDisabled ? 0.5 : 1,
            borderRadius: "8px",
            pointerEvents: isDisabled ? "none" : "auto",
          }}
        >
          {" "}
          {/* Adjust the opacity value as needed */}
          {isRecurrent && bulkSelectionEnabled ? (
            <DateSelector
              setDates={changeDates}
              selectedDates={recurrentDates}
              calendarSummary={calendarSummary}
            />
          ) : (
            <SingleDateSelector
              selectedDate={recurrentDates[0]}
              setDate={(date: string) => changeDates([date])}
              calendarSummary={calendarSummary}
            />
          )}
        </div>
        {subtitle ? (
          <p className="body-regular text-Text-Subtle">{subtitle}</p>
        ) : null}
      </div>

      {!predictorDeactivated ? (
        <ShiftTimeInDaySelector
          setShiftTimeInDay={(shiftTimeInDay: string) => {
            changeShiftTimeInDay(shiftTimeInDay);
          }}
          shiftTimeInDay={shiftTimeInDay}
        />
      ) : null}
      <ScheduleSelector
        setStartTime={(time: string) => handleSetTime(time, setStartTime)}
        setEndTime={(time: string) => handleSetTime(time, setEndTime)}
        selectedStartTime={day(selectedStartTime).format("HH:mm")}
        selectedEndTime={day(selectedEndTime).format("HH:mm")}
      />
    </div>
  );
};
