import React, { useEffect, useRef, useState } from "react";

interface DropDownWithInputProps<T> {
  options: T[];
  placeholder: string;
  autoFocus?: boolean;
  disabled?: boolean;
  errorMessage?: string;
  storeParentOption: (option: T) => void;
  optionToString: (option: T) => string;
}

export default function DropDownWithInput<T>({
  options,
  placeholder,
  autoFocus,
  disabled,
  errorMessage,
  storeParentOption,
  optionToString,
}: DropDownWithInputProps<T>) {
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const matchingOptionIndex = options.findIndex((option) =>
    optionToString(option).toLowerCase().includes(inputValue.toLowerCase())
  );

  useEffect(() => {
    if (
      isFocused &&
      inputValue &&
      dropdownRef.current &&
      matchingOptionIndex !== -1
    ) {
      const matchingOptionElement = dropdownRef.current.children[
        matchingOptionIndex
      ] as HTMLLIElement;
      matchingOptionElement.scrollIntoView({ block: "nearest" });
    }
  }, [inputValue, isFocused, options, matchingOptionIndex]);

  const handleInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option: T) => {
    storeParentOption(option);
    setInputValue("");
    setIsFocused(false);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      setInputValue("");
    }, 100);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && matchingOptionIndex !== -1) {
      e.preventDefault();
      const matchingOption = options[matchingOptionIndex];
      handleOptionClick(matchingOption);
      inputRef.current?.blur();
    }
  };

  return (
    <div className="relative flex w-full flex-col">
      <div
        className={`ring-solid box-border flex w-full flex-row items-center justify-start space-x-small rounded-[8px] px-small py-medium ring-1 ${!disabled ? "bg-white" : "bg-Background-Secondary"} shrink-1 
          ${errorMessage ? "ring-2 ring-red-500" : "ring-Divider-Subtle"} focus-within:ring-2 focus-within:ring-Action-Secondary`}
      >
        <input
          ref={inputRef}
          disabled={disabled}
          type="text"
          value={inputValue}
          onChange={handleInputValue}
          onBlur={handleInputBlur}
          className="body-regular placeholder:body-regular w-full bg-transparent placeholder:text-Text-Subtle focus:outline-none"
          placeholder={placeholder}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyPress}
          autoFocus={autoFocus}
        />
      </div>
      {isFocused && options.length > 0 && (
        <ul
          ref={dropdownRef}
          className="absolute top-full z-10 mt-tiny max-h-60 w-full overflow-auto rounded-[8px] border border-Divider-Default bg-white"
          style={{
            boxShadow:
              "0px 1px 4px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.20), 0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
          }}
        >
          {options.map((option, index) => (
            <li
              key={index}
              className={`body-regular cursor-pointer px-large py-medium ${inputValue && index === matchingOptionIndex ? "bg-Background-Secondary" : ""} hover:bg-Background-Secondary`}
              onMouseDown={() => handleOptionClick(option)}
            >
              {optionToString(option)}
            </li>
          ))}
        </ul>
      )}
      {errorMessage && (
        <p className="info-caption mt-tiny text-Negative-500">{errorMessage}</p>
      )}
    </div>
  );
}
