import { useTranslation } from "react-i18next";

import { TagLabel } from "../../../components/common/TagLabel";
import { ShiftModalityEnum } from "../../../types";
import { ClaimRequest } from "../../../types/claims";
import LivoIcon, { DisclaimerIconMap } from "../../common/LivoLogo";
import { ProfilePicture } from "../ProfilePicture";

interface AcceptedClaimRowProps {
  claim: ClaimRequest;
  onClick: () => void;
  style?: any;
}

export const AcceptedClaimRow: React.FC<AcceptedClaimRowProps> = ({
  claim,
  style,
  onClick,
}) => {
  const { t } = useTranslation(["shift-claim-details", "professional-claim"]);
  const subtitleComponent =
    claim.modality === ShiftModalityEnum.EXTERNAL || !claim.modality ? (
      claim.professionalProfile?.firstShifterForFacility ? (
        <p className="info-caption text-[#987ED4]">
          {t("professional-claim:first_shifter_label")}
        </p>
      ) : claim.professionalProfile?.professionalReview.totalReviews > 0 ? (
        <div className=":hovered:bg-Background-Secondary flex w-full flex-row items-center justify-start space-x-tiny">
          <LivoIcon name="star-filled" size={12} color="#FF911C" />
          <p className="body-sm text-Text-Default">
            {claim.professionalProfile?.professionalReview.averageRating}
          </p>

          <p className="body-sm text-Text-Subtle">
            {`(${claim.professionalProfile?.professionalReview.totalReviews} ${
              claim.professionalProfile.professionalReview.totalReviews > 1
                ? "Reseñas"
                : "Reseña"
            })`}
          </p>
        </div>
      ) : (
        <p className="info-caption text-Text-Subtle">{t("no_reviews_label")}</p>
      )
    ) : claim.cancellationRequest ? (
      <p className="info-caption text-Negative-500">
        {t("cancellation_request")}
      </p>
    ) : (
      <div className="flex w-full flex-col justify-center">
        <div className="flex w-full flex-row flex-wrap items-center gap-y-tiny space-x-tiny">
          {claim.professionalProfile.tags?.map((tag, index) => (
            <TagLabel
              key={index}
              text={tag.label}
              backgroundColor={tag.styling?.backgroundColor || "#EEEFF3"}
              color={tag.styling?.textColor || undefined}
            />
          ))}
        </div>
        {claim.compensationOption && (
          <p className="info-caption text-Text-Subtle">
            {claim.compensationOption?.label}:{" "}
            {claim.compensationOption.compensationValue}
          </p>
        )}
      </div>
    );

  return (
    <div
      onClick={onClick}
      className="py-smal flex size-full cursor-pointer flex-row items-center space-x-medium hover:bg-Background-Secondary"
    >
      <ProfilePicture
        profilePictureUrl={claim.professionalProfile?.profilePictureUrl}
        modality={claim.modality}
        size={48}
      />
      <div className="flex flex-1 flex-col justify-center">
        <div className="flex flex-row items-center space-x-small">
          <p className="body-regular truncate text-Text-Default">
            {claim.professionalProfile?.firstName}{" "}
            {claim.professionalProfile.lastName}
          </p>

          {claim.disclaimer ? (
            <div className="group relative">
              <LivoIcon
                name={DisclaimerIconMap[claim.disclaimer.type].name}
                color={DisclaimerIconMap[claim.disclaimer.type].color}
                size={18}
              />
              <div className="invisible absolute left-0 top-full z-10 mt-2 w-[200px] whitespace-normal rounded bg-[#2C3038] px-2 py-1 text-white group-hover:visible">
                <div className="flex flex-col space-y-2 text-left text-s01">
                  <span>{claim.disclaimer.message}</span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {subtitleComponent}
      </div>

      <LivoIcon name="chevron-right" size={24} color={"#149EF2"} />
    </div>
  );
};
