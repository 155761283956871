import LivoIcon from "@/components/common/LivoLogo";

interface ShiftCardTagProps {
  totalPendingClaims: number;
  isFilled: boolean;
  largeTag?: boolean;
  isSelected?: boolean;
}
export const ShiftCardTag: React.FC<ShiftCardTagProps> = ({
  totalPendingClaims,
  isFilled,
  largeTag,
  isSelected,
}) => {
  const emptyTag = (
    <div className="flex-col items-center justify-center rounded-[100px] p-[2px] opacity-0">
      <p className="subtitle-sm w-large text-center text-white"></p>
    </div>
  );

  if (isSelected) {
    return emptyTag;
  }

  if (isFilled) {
    const tagComponent = (
      <LivoIcon name="circle-check-filled" size={24} color="#33B240" />
    );
    return tagComponent;
  } else if (totalPendingClaims > 0) {
    const tagComponent = (
      <div className="flex flex-col items-center justify-center rounded-[100px] bg-Negative-500 p-[2px]">
        <p className="subtitle-sm w-large text-center text-white">
          {totalPendingClaims}
        </p>
      </div>
    );

    return tagComponent;
  }

  return (
    <div className="flex-col items-center justify-center rounded-[100px] p-[2px] opacity-0">
      <p className="subtitle-sm w-large text-center text-white"></p>
    </div>
  );
};
