import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

interface CompensationOptionSelectorProps {
  compensationOptions: {
    value: string;
    displayText: string;
  }[];
  selectedCompensationOptions: string[];
  setCompensationOptions: (compensationOption: string[]) => void;
}

export const CompensationOptionSelector: React.FC<
  CompensationOptionSelectorProps
> = ({
  selectedCompensationOptions,
  setCompensationOptions,
  compensationOptions,
}) => {
  const { t } = useTranslation("publish-shift");
  return (
    <div className="flex-1">
      <p className="info-caption mb-tiny">{t("compensation_option_label")}</p>
      <div className="flex w-full flex-col space-y-medium self-start rounded-[8px] border border-solid border-Divider-Subtle px-small py-medium">
        {compensationOptions.map((option, index) => {
          return (
            <button
              key={index}
              onClick={() => {
                if (selectedCompensationOptions.includes(option.value)) {
                  setCompensationOptions(
                    selectedCompensationOptions.filter(
                      (value) => value !== option.value
                    )
                  );
                } else {
                  setCompensationOptions([
                    ...selectedCompensationOptions,
                    option.value,
                  ]);
                }
              }}
              className="flex flex-row items-center justify-between space-x-tiny"
            >
              <div className="flex flex-row items-center space-x-tiny">
                <p className="body-regular">{option.displayText}</p>
              </div>
              {
                <LivoIcon
                  name={
                    selectedCompensationOptions.includes(option.value)
                      ? "square-check-filled"
                      : "square"
                  }
                  size={18}
                  color={
                    selectedCompensationOptions.includes(option.value)
                      ? "#149EF2"
                      : "#ACBBCB"
                  }
                />
              }
            </button>
          );
        })}
      </div>
    </div>
  );
};
