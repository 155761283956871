import { useMemo } from "react";

import { FLAGS } from "@/config/flag-enums";
import FlagsService from "@/services/flags.service";

export function useFlag(flag: FLAGS) {
  const value = useMemo(() => {
    return FlagsService.getFlag(flag);
  }, [flag]);

  return value;
}
