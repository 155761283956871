import { CategoryAndVisibilityTags } from "@/components/common/CategoryAndVisibilities";
import LivoIcon from "@/components/common/LivoLogo";
import { TagLabel } from "@/components/common/TagLabel";

import { Category } from "@/types/shifts";

interface ShiftCardHeaderProps {
  title: string;
  recurrent: boolean;
  style?: any;
  skills: {
    displayText: string;
    value: string;
  }[];
  category?: Category;
  internalVisible: boolean;
  externalVisible: boolean;
}

export const ShiftCardHeader: React.FC<ShiftCardHeaderProps> = ({
  title,
  recurrent,
  skills,
  style,
  category,
  internalVisible,
  externalVisible,
}) => {
  return (
    <div className="flex w-full flex-col">
      <CategoryAndVisibilityTags
        category={category}
        recurrenVisible={recurrent}
        internalVisible={internalVisible}
        externalVisible={externalVisible}
      />
      <div
        className="mb-tiny flex flex-row items-center justify-start space-x-tiny"
        style={style}
      >
        <h1 className="subtitle-regular flex flex-1 truncate">{title}</h1>
      </div>
      <div className="inline-flex flex-row flex-wrap items-center justify-start gap-tiny space-x-small">
        {skills.map((skill, index) => (
          <TagLabel key={index} text={skill.displayText} />
        ))}
      </div>
    </div>
  );
};
