import LivoIcon from "@/components/common/LivoLogo";

interface ShiftCardActionsProps {
  actionComponents: {
    iconName: string;
    onClick: () => void;
  }[];
}

export const ShiftCardActions: React.FC<ShiftCardActionsProps> = ({
  actionComponents,
}) => {
  return (
    <div className="flex space-x-small rounded-[100px] bg-white px-small py-tiny shadow-custom">
      {actionComponents.map((actionComponent, index) => (
        <button
          key={index}
          onClick={actionComponent.onClick}
          className="flex items-center justify-center"
        >
          <LivoIcon name={actionComponent.iconName} size={16} color="#707A91" />
        </button>
      ))}
    </div>
  );
};
