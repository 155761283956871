import { useTranslation } from "react-i18next";

import LivoIcon from "@/components/common/LivoLogo";

interface EmptyCapacityClaimProps {
  onClick: () => void;
  editable: boolean;
}

export const EmptyCapacityClaim: React.FC<EmptyCapacityClaimProps> = ({
  onClick,
  editable,
}) => {
  const { t } = useTranslation("shift-claim-details");
  return (
    <div className="flex size-full flex-row items-center space-x-small py-small">
      <div
        className={`flex items-center justify-center rounded-[8px] bg-Neutral-100 p-medium`}
      >
        <LivoIcon name="user-search" size={24} color="#707A91" />
      </div>
      <div className="flex w-full flex-col justify-center">
        <p className="body-regular text-Text-Default">
          {t("pending_to_fill_capacity_item")}
        </p>
      </div>
      {editable ? (
        <button className="flex items-center justify-center" onClick={onClick}>
          <LivoIcon name="trash" size={24} color="#ACBBCB" />
        </button>
      ) : null}
    </div>
  );
};
